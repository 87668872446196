import {
  ListItemButton,
  ListItemText,
  IconButton,
  Stack,
  Typography,
  ListItemIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { KlausurAufgabeReference, KlausurAufgabe } from "../Klausur";
import { AddAufgabeIcon, AufgabeIcon, AufgabeLinkIcon } from "../Icons";
import { Aufgabe } from "../Aufgabe";
import { FieldValues, useFieldArray, UseFieldArrayReturn } from "react-hook-form";
import SimpleDialog from "../../lib/SimpleDialog";
import DBList from "../../lib/couchdb-mgr/DBList";
import { Database } from "../../lib/couchdb-mgr/HelperClasses";
import DbDirView, { DbDirViewFileTypes } from "../DbDirView";
import { useDoc } from "use-pouchdb";
import { DocumentTypes } from "../BaseObject";
import { useEditModeContext, useEditableViewContext } from "../../lib/EditableView";

interface SelectAufgabeViewProps {
  onSelectDoc: (doc: { dbname: string; docid: string } | undefined) => void;
}
const SelectAufgabeView: React.FC<SelectAufgabeViewProps> = ({ onSelectDoc }) => {
  const [selectedDb, setSelectedDb] = useState<Database | undefined>();
  const [selectedDoc, setSelectedDoc] = useState<any | undefined>();
  const handleSelectFile = (dbname: string, docid: string, type: DocumentTypes) => {
    if (selectedDoc && selectedDoc._id === docid) {
      setSelectedDoc(undefined);
      onSelectDoc(undefined);
    } else {
      if (type !== "aufgabe") throw new Error("INTERNAL ERROR!");
      setSelectedDoc(docid);
      onSelectDoc({ dbname: dbname, docid: docid });
    }
  };
  useEffect(() => {
    setSelectedDoc(undefined);
    onSelectDoc(undefined);
  }, [selectedDb, onSelectDoc]);

  return (
    <Grid container>
      <Grid item xs={5}>
        <DBList allowUserRightsEdit={false} selectedDb={selectedDb} onSelectDatabase={setSelectedDb} />
      </Grid>
      <Grid item xs={7}>
        {selectedDb && (
          <DbDirView
            selectedDocId={selectedDoc}
            showFileTypes={DbDirViewFileTypes.AUFGABEN}
            dbname={selectedDb.name}
            handleOpen={handleSelectFile}
          />
        )}
      </Grid>
    </Grid>
  );
};

interface AufgabeHinzufügenDialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  position: number;
  addFct: (aufgabe: KlausurAufgabe | KlausurAufgabeReference, position: number) => void;
}
const AufgabeHinzufügenDialog: React.FC<AufgabeHinzufügenDialogProps> = ({
  open,
  handleClose,
  title,
  position,
  addFct,
}) => {
  const [selection, setSelection] = useState<"aufgabe" | "copy" | "link">("aufgabe");

  const [linkSelectedDoc, setLinkSelectedDoc] = useState<{ dbname: string; docid: string } | undefined>();

  const handleOK = async () => {
    switch (selection) {
      case "aufgabe":
        addFct(new KlausurAufgabe(), position);
        break;
      case "link":
        if (linkSelectedDoc && linkSelectedDoc.dbname && linkSelectedDoc.docid)
          addFct(new KlausurAufgabeReference(linkSelectedDoc.dbname, linkSelectedDoc.docid), position);
        break;
      case "copy":
        break;
      default:
        throw new Error("Internal Error!");
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <RadioGroup
          name="type"
          value={selection}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSelection((event.target as HTMLInputElement).value as "aufgabe" | "copy" | "link")
          }
        >
          <FormControlLabel value="aufgabe" control={<Radio />} label="neue leere Aufgabe" />
          <FormControlLabel value="link" control={<Radio />} label="Link zu einer bestehenden Aufgabe" />
          <FormControlLabel value="copy" control={<Radio />} label="bestehende Aufgabe als Kopie" />
        </RadioGroup>
        {selection === "link" && <SelectAufgabeView onSelectDoc={setLinkSelectedDoc} />}
        {selection === "copy" && <p>Kopie einer Aufgabe</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbruch</Button>
        <Button disabled={selection === "link" && linkSelectedDoc === undefined} onClick={handleOK}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ReferenceStructureEntryProps {
  dbname: string;
  aufgabeId: string;
  primaryText: string;
}
const ReferenceStructureEntry: React.FC<ReferenceStructureEntryProps> = ({ dbname, aufgabeId, primaryText }) => {
  const aufgabeRaw = useDoc(aufgabeId, { db: dbname });

  const [aufgabe, setAufgabe] = useState<Aufgabe | undefined>(undefined);

  useEffect(() => {
    if (aufgabeRaw.error === null && aufgabeRaw.state === "done") {
      setAufgabe(aufgabeRaw.doc as unknown as Aufgabe);
    } else if (aufgabe) setAufgabe(undefined);
  }, [aufgabeRaw]);

  return (
    <>
      {aufgabe && (
        <ListItemText
          primary={primaryText}
          secondary={aufgabe.name}
          secondaryTypographyProps={{ sx: { fontSize: "x-small" } }}
        />
      )}
    </>
  );
};

interface AufgabenArrayContextType {
  aufgabenArray: UseFieldArrayReturn<FieldValues, "aufgaben", "id">;
  whichAufgabe: number;
  setWhichAufgabe: React.Dispatch<React.SetStateAction<number>>;
}
const AufgabenArrayContext = createContext<AufgabenArrayContextType>({
  aufgabenArray: {} as UseFieldArrayReturn<FieldValues, "aufgaben", "id">,
  whichAufgabe: -1,
  setWhichAufgabe: (() => {}) as React.Dispatch<React.SetStateAction<number>>,
});
interface AufgabenArrayContextComponentProps {
  children: ReactNode;
}
export const AufgabenArrayContextComponent: React.FC<AufgabenArrayContextComponentProps> = ({ children }) => {
  const aufgabenArray = useFieldArray({ name: "aufgaben" });
  // -1 means Eigenschaften anzeigen!
  const [whichAufgabe, setWhichAufgabe] = useState(-1);

  const memoizedValue = useMemo(() => {
    return { aufgabenArray, whichAufgabe, setWhichAufgabe };
  }, [aufgabenArray, whichAufgabe]);

  return <AufgabenArrayContext.Provider value={memoizedValue}>{children}</AufgabenArrayContext.Provider>;
};
export const useAufgabenArrayContext = () => {
  return useContext(AufgabenArrayContext);
};

interface AufgabeVisibilityWrapperProps {
  currentIndex: number;
  children: ReactNode;
}
export const AufgabeVisibilityWrapper: React.FC<AufgabeVisibilityWrapperProps> = ({ currentIndex, children }) => {
  const { whichAufgabe } = useAufgabenArrayContext();
  return <div style={{ visibility: whichAufgabe === currentIndex ? undefined : "collapse" }}>{children}</div>;
};

const KlausurStructureEditor: React.FC = () => {
  // -1: Eigenschaftseditor

  const { whichAufgabe, setWhichAufgabe, aufgabenArray } = useAufgabenArrayContext();
  const { editModeEnabled } = useEditModeContext();

  const handleCheck = (newValue: number) => {
    setWhichAufgabe(newValue);
  };

  const [hinzufügenDialogTitle, setHinzufügenDialogTitle] = useState("");
  const [hinzufügenPos, setHinzufügenPos] = useState(-1);
  const [hinzufügenDialogOpen, setHinzufügenDialogOpen] = useState(false);
  const addFct = (aufgabe: KlausurAufgabe | KlausurAufgabeReference, position: number) => {
    aufgabenArray.insert(position, aufgabe);
  };

  const [reallyDeleteIndex, setReallyDeleteIndex] = useState(-1);
  const reallyDelete = () => {
    aufgabenArray.remove(reallyDeleteIndex);
    setReallyDeleteIndex(-1);
    setWhichAufgabe(-1);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
      <AufgabeHinzufügenDialog
        open={hinzufügenDialogOpen}
        handleClose={() => setHinzufügenDialogOpen(false)}
        title={hinzufügenDialogTitle}
        position={hinzufügenPos}
        addFct={addFct}
      />
      <SimpleDialog
        open={reallyDeleteIndex !== -1}
        handleCancel={() => setReallyDeleteIndex(-1)}
        handleOK={reallyDelete}
        title={`Wollen Sie Aufgabe ${reallyDeleteIndex + 1} wirklich löschen?`}
        abbruchText="Abbruch"
        okText="JA WIRKLICH LÖSCHEN!!"
      />
      <Typography variant="h6">Struktur:</Typography>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem disablePadding>
          <ListItemButton role={undefined} onClick={() => handleCheck(-1)} dense divider selected={whichAufgabe === -1}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Eigenschaften" />
          </ListItemButton>
        </ListItem>

        {aufgabenArray.fields.map((item, index) => {
          const aufgabe: any = item;
          return (
            <ListItem
              key={aufgabe.id}
              disablePadding
              secondaryAction={
                editModeEnabled && (
                  <Stack direction="row">
                    {index > 0 && (
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => {
                          aufgabenArray.move(index, index - 1);
                        }}
                      >
                        <ArrowCircleUpIcon />
                      </IconButton>
                    )}
                    {index < aufgabenArray.fields.length - 1 && (
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => {
                          aufgabenArray.move(index, index + 1);
                        }}
                      >
                        <ArrowCircleDownIcon />
                      </IconButton>
                    )}
                    <IconButton edge="end" aria-label="comments" onClick={() => setReallyDeleteIndex(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                )
              }
            >
              <ListItemButton
                role={undefined}
                onClick={() => handleCheck(index)}
                dense
                divider
                selected={whichAufgabe === index}
              >
                {aufgabe.type === "klausuraufgabe" && (
                  <>
                    <ListItemIcon>
                      <AufgabeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Aufgabe ${index + 1}`}
                      secondary={aufgabe.name}
                      secondaryTypographyProps={{ sx: { fontSize: "x-small" } }}
                    />
                  </>
                )}
                {aufgabe.type === "reference" && (
                  <>
                    <ListItemIcon>
                      <AufgabeLinkIcon />
                    </ListItemIcon>
                    <ReferenceStructureEntry
                      dbname={aufgabe.dbname}
                      aufgabeId={aufgabe.docid}
                      primaryText={`Aufgabe ${index + 1}`}
                    />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
        {editModeEnabled && (
          <ListItemButton
            onClick={() => {
              setHinzufügenDialogTitle(`Aufgabe ${aufgabenArray.fields.length + 1} hinzufügen als`);
              setHinzufügenPos(aufgabenArray.fields.length + 1);
              setHinzufügenDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <AddAufgabeIcon />
            </ListItemIcon>
            <ListItemText secondary="Aufgabe hinzufügen" />
          </ListItemButton>
        )}
      </List>
    </Paper>
  );
};

export default KlausurStructureEditor;
