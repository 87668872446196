import {
  parse,
  format,
  add,
  eachDayOfInterval,
  endOfWeek,
  startOfWeek,
  differenceInDays,
  getDay,
  eachMonthOfInterval,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";
import de from "date-fns/locale/de";

export const DATE_FMT_STRING = "yyyy-MM-dd";
export const TIME_FMT_STRING = "HH:mm:ss";
export const DATE_TIME_FMT_STRING = "yyyy-MM-dd HH:mm:ss";

export enum Weekdays {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

export function addMinutesToTime(time: string, minutes: number) {
  const timeObject = parse(time, TIME_FMT_STRING, new Date());
  const resultObject = add(timeObject, {
    minutes: minutes,
  });
  return format(resultObject, TIME_FMT_STRING);
}

export function addDaysToDateO(dateObject: Date, days: number) {
  const newDate = new Date(dateObject);
  newDate.setDate(dateObject.getDate() + days);
  return newDate;
}

export function addMonthToDateO(dateObject: Date, months: number) {
  return add(dateObject, { months: months });
}

export function addDaysToDate(date: string, days: number) {
  const dateObject = parse(date, DATE_FMT_STRING, new Date());
  return format(addDaysToDateO(dateObject, days), DATE_FMT_STRING);
}

export function firstDayOfMonthO(date: Date) {
  let d = new Date(date);
  d.setDate(1);
  return d;
}

export const getMonthNames = ({ locale, short }: { locale: Locale; short: boolean }): string[] => {
  const months: string[] = [];
  eachMonthOfInterval({ start: new Date(2000, 0, 1), end: new Date(2000, 11, 1) }).forEach((day) => {
    months.push(format(day, short ? "MMM" : "MMMM", { locale }));
  });
  return months;
};

export const getWeekDays = ({ locale, formatString }: { locale: Locale; formatString: string }): string[] => {
  const now = new Date();
  const weekDays: string[] = [];
  const start = startOfWeek(now, { locale });
  const end = endOfWeek(now, { locale });
  eachDayOfInterval({ start, end }).forEach((day) => {
    weekDays.push(format(day, formatString, { locale }));
  });
  return weekDays;
};

export const weekdaysToName = (day: Weekdays) => {
  return getWeekDays({ locale: de, formatString: "EEEEEE" })[day];
};

export const weekdayOfDateToNameO = (date: Date, short: boolean) => {
  return format(date, short ? "EEEEEE" : "EEEE", {
    locale: de,
  });
};

export const monthNameOfDateO = (date: Date, short: boolean) => {
  return format(date, short ? "LLL" : "LLLL", {
    locale: de,
  });
};

export const monthNameOfMonth = (month: number, short: boolean) => {
  return getMonthNames({ locale: de, short })[month];
};

export const formatDateReadable = (date: string) => {
  return format(parse(date, DATE_FMT_STRING, new Date()), "dd.MM.yyyy");
};

export const formatDateReadableO = (date: Date) => {
  return format(date, "dd.MM.yyyy");
};

export const daysDifference = (a: string, b: string) => {
  const aObj = parse(a, DATE_FMT_STRING, new Date());
  const bObj = parse(b, DATE_FMT_STRING, new Date());
  return differenceInDays(aObj, bObj);
};

export const dateToObject = (date: string) => {
  return parse(date, DATE_FMT_STRING, new Date());
};

export const objectToDate = (date: Date) => {
  return format(date, DATE_FMT_STRING);
};

export const objectToDateTime = (date: Date) => {
  return format(date, DATE_TIME_FMT_STRING);
};

interface UseDateResult {
  date: string;
  dateO: Date;
  setDate?: (date: Date) => void;
  addDay?: (days: number) => void;
  subtractDay?: () => void;
}
export const useDate = (startDate?: Date) => {
  const [date, setDate] = useState(startDate ? startDate : new Date());

  const [result, setResult] = useState<UseDateResult>({ date: format(date, DATE_FMT_STRING), dateO: date });

  const setDateApi = useCallback((date: Date) => {
    setDate(date);
  }, []);

  const addDayApi = useCallback(
    (days: number) => {
      if (days === undefined) days = 1;
      const d = addDaysToDateO(date, days);
      setDate(d);
    },
    [date]
  );

  const subtractDayApi = useCallback(() => {
    const d = addDaysToDateO(date, -1);
    setDate(d);
  }, [date]);

  useEffect(() => {
    setResult({
      date: format(date, DATE_FMT_STRING),
      dateO: date,
      setDate: setDateApi,
      addDay: addDayApi,
      subtractDay: subtractDayApi,
    });
  }, [addDayApi, date, setDateApi, subtractDayApi]);

  return result;
};

const internalToWeekdays = (index: number) => {
  let i = index - 1;
  if (i < 0) i = 6;
  return i as Weekdays;
};

export const dayOfDate = (date: string | Date) => {
  if (typeof date === "string") {
    date = dateToObject(date);
  }

  return internalToWeekdays(getDay(date));
};

export const getWeekNumberOfYearO = (date: Date): number => {
  const s = format(date, "ww");
  return parseInt(s);
};

export const getMondayOfWeekO = (date: Date): Date => {
  const res = new Date(date);
  while (res.getDay() !== 1) addDaysToDateO(res, -1);
  return res;
};
