import { useCallback, useEffect, useState } from "react";
import { useDoc, usePouch } from "use-pouchdb";
import { useUsername } from "./CouchDbContext";

class MyLockEntry {
  db: PouchDB.Database;
  currentLockDocument?: LockDoc;
  ids: string[];

  public constructor(db: PouchDB.Database) {
    this.db = db;
    this.ids = [];
  }

  /**
   *
   * @returns true if any lock was removed
   */
  public removeMyLocks() {
    let retVal = false;

    if (this.currentLockDocument && this.ids.length > 0) {
      retVal = true;
      this.currentLockDocument.lockEntries = this.currentLockDocument.lockEntries.filter(
        (entry) => !this.ids.includes(entry.docid)
      );
      this.db
        .put(this.currentLockDocument)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
      this.ids = [];
    } else if (this.ids.length !== 0) {
      throw new Error(
        `INTERNAL ERROR!!!, ${JSON.stringify(this.db)}, ${JSON.stringify(this.currentLockDocument)}, ${JSON.stringify(
          this.ids
        )}`
      );
    }

    return retVal;
  }
}

class MyLockDb {
  lockEntries: MyLockEntry[];

  public constructor() {
    this.lockEntries = [];
  }

  private findEntry(db: PouchDB.Database) {
    for (let i = 0; i < this.lockEntries.length; ++i) {
      if (this.lockEntries[i].db === db) {
        return this.lockEntries[i];
      }
    }
    return undefined;
  }

  public addLock(db: PouchDB.Database, docid: string) {
    let entry = this.findEntry(db);
    if (!entry) {
      entry = new MyLockEntry(db);
      this.lockEntries.push(entry);
    }

    entry.ids.push(docid);
  }

  public removeLock(db: PouchDB.Database, docid: string) {
    let entry = this.findEntry(db);
    if (entry) {
      entry.ids = entry.ids.filter((id) => id !== docid);
    } else
      throw new Error(
        `INTERNAL ERROR!!!, ${JSON.stringify(this.lockEntries)}, ${JSON.stringify(db)}, ${JSON.stringify(docid)}`
      );
  }

  public updateLockDoc(db: PouchDB.Database, lockDoc: LockDoc | undefined) {
    let entry = this.findEntry(db);
    if (!entry) {
      entry = new MyLockEntry(db);
      this.lockEntries.push(entry);
    }
    entry.currentLockDocument = lockDoc;
  }

  /**
   *
   * @returns true if any lock was removed
   */
  public removeAllMyLocks() {
    let retVal = false;

    this.lockEntries.forEach((entry) => {
      retVal = retVal || entry.removeMyLocks();
    });

    return retVal;
  }
}

const myLockDb = new MyLockDb();
window.onbeforeunload = function () {
  return myLockDb.removeAllMyLocks() ? "Seite verlassen" : undefined;
};

interface LockEntry {
  docid: string;
  user: string;
}

interface LockDoc {
  readonly _id: "lock_doc";
  _rev?: string;
  lockEntries: LockEntry[];
}

interface LockState {
  isLocked: boolean | undefined;
  lockUser: string | undefined;
}

interface UseLockReturnType {
  lockState: LockState;
  loading: boolean;
  lock: () => void;
  unlock: () => void;
}

const useLock = (dbname: string, docid: string): UseLockReturnType => {
  // initializing failsafe
  const user = useUsername();

  const db = usePouch(dbname) as PouchDB.Database;
  const lockDocRaw = useDoc("lock_doc", { db: dbname });

  const [lockState, setLockState] = useState<LockState>({ isLocked: undefined, lockUser: undefined });
  const [loading, setLoading] = useState(false);

  // check-lock-state effect
  /*
   * TODO:
   * this effect my run at many places. This effect updates the myLockDb with
   * the current lock_doc. This may happen more often than needed.
   * So it could be possible, to optimize performance here!
   */
  useEffect(() => {
    if (db && docid) {
      if (lockDocRaw.loading === false) {
        if (lockDocRaw.error === null) {
          // loading success
          const l = lockDocRaw.doc as unknown as LockDoc;
          myLockDb.updateLockDoc(db, l);
          let lockUser = undefined;
          for (let i = 0; i < l.lockEntries.length; ++i) {
            if (l.lockEntries[i].docid === docid) {
              lockUser = l.lockEntries[i].user;
              break;
            }
          }
          if (lockUser) {
            setLockState({ isLocked: true, lockUser: lockUser });
          } else {
            setLockState({ isLocked: false, lockUser: undefined });
          }
        } else {
          // loading error
          console.log("loading error: ", lockDocRaw);
          myLockDb.updateLockDoc(db, undefined);
          if (lockDocRaw.error.status === 404) setLockState({ isLocked: false, lockUser: undefined });
          else setLockState({ isLocked: undefined, lockUser: undefined });
        }
      }
    } else {
      setLockState({ isLocked: undefined, lockUser: undefined });
    }
    setLoading(lockDocRaw.loading);
  }, [db, docid, lockDocRaw]);

  const lock = useCallback(async () => {
    try {
      if (db && user && lockDocRaw.loading === false && lockState.isLocked !== true) {
        let l: LockDoc = JSON.parse(JSON.stringify(lockDocRaw.doc));
        if (!l) l = { _id: "lock_doc", lockEntries: [] };
        l.lockEntries.push({ docid, user });
        setLoading(true);
        await db.put(l);
        myLockDb.addLock(db, docid);
      }
    } catch (e) {
      console.log(e);
    }
  }, [db, user, lockDocRaw.loading, lockDocRaw.doc, lockState.isLocked, docid]);

  const unlock = useCallback(async () => {
    try {
      if (lockDocRaw.loading === false && lockDocRaw.error === null) {
        let l: LockDoc = JSON.parse(JSON.stringify(lockDocRaw.doc));
        l.lockEntries = l.lockEntries.filter((entry: LockEntry) => entry.docid !== docid);
        setLoading(true);
        await db.put(l);
        myLockDb.removeLock(db, docid);
      }
    } catch (e) {
      console.log(e);
    }
  }, [db, docid, lockDocRaw.doc, lockDocRaw.error, lockDocRaw.loading]);

  // building return value
  const [retVal, setRetVal] = useState({ lockState, loading, lock, unlock });
  useEffect(() => {
    setRetVal({ lockState, loading, lock, unlock });
  }, [lockDocRaw, lockState, lock, unlock, loading]);

  return retVal;
};

export default useLock;
