import { useCallback } from "react";
import { DocumentAttachment, useGetAttachmentFromObject } from "../lib/AttachmentManager";
import { DocumentAddress, useDbList, useResolveDocumentAddress } from "../lib/couchdb-mgr/CouchDbContext";
import { Klausur } from "./Klausur";
import { klausur2Xml } from "./useKlausur2Xml";
//import { compileLatex2Pdf, createPdfTexEngine, UsePdfCompilerProps } from "./usePdfCompiler";
import { doXslt } from "./useXsltResult";
import { XSLFileData } from "./XSLTemplate";
import useServerXeTeXCompiler, { UsePdfCompilerProps } from "./UseServerXeTeXCompiler";

export const useLatexPreparationCompiler = () => {
  const dbs = useDbList();
  const resolveAddress = useResolveDocumentAddress();
  const getAttachment = useGetAttachmentFromObject();
  //const pdfTexEngine = useRef<undefined | PdfTeXEngine>();

  const compiler = useCallback(
    async (klausur: Klausur, dbname: string, template: DocumentAddress): Promise<UsePdfCompilerProps> => {
      //let pdfTexEnginePromise = undefined;
      //if (!pdfTexEngine.current) pdfTexEnginePromise = createPdfTexEngine();

      const xmlPromise = klausur2Xml(dbs, klausur, dbname, getAttachment);

      const xslFiles = ((await resolveAddress(template)) as any).xslFiles as XSLFileData[];
      const xml = await xmlPromise;

      const latex = await doXslt(xslFiles, xml.xml);

      /*if (latex) {
        if (!pdfTexEngine.current) pdfTexEngine.current = await pdfTexEnginePromise;
        const res = await compileLatex2Pdf(pdfTexEngine.current as PdfTeXEngine, {
          latexDocument: latex,
          attachments: xml.attachments,
        });
        if (res.success) {
          return res.pdf;
        } else throw new Error(res.log);
      } else throw new Error("XSLT did not work!");*/

      return { latexDocument: latex as string, attachments: xml.attachments };
    },
    [dbs, getAttachment, resolveAddress]
  );

  return compiler;
};

export const useCompleteCompiler = () => {
  const dbs = useDbList();
  const resolveAddress = useResolveDocumentAddress();
  const getAttachment = useGetAttachmentFromObject();
  //const pdfTexEngine = useRef<undefined | PdfTeXEngine>();
  const xetex = useServerXeTeXCompiler();

  const compiler = useCallback(
    async (klausur: Klausur, dbname: string, template: DocumentAddress): Promise<Blob> => {
      //let pdfTexEnginePromise = undefined;
      //if (!pdfTexEngine.current) pdfTexEnginePromise = createPdfTexEngine();

      const xmlPromise = klausur2Xml(dbs, klausur, dbname, getAttachment);

      const xslFiles = ((await resolveAddress(template)) as any).xslFiles as XSLFileData[];
      const xml = await xmlPromise;

      const latex = await doXslt(xslFiles, xml.xml);

      if (latex) {
        //if (!pdfTexEngine.current) pdfTexEngine.current = await pdfTexEnginePromise;
        const res = await xetex([
          { filename: "main.tex", data: latex },
          ...xml.attachments.map((a: DocumentAttachment) => {
            return { filename: a.name, data: a.data as Blob };
          }),
        ]);
        if (res.success && res.pdf) {
          return res.pdf;
        } else throw new Error(res.log);
      } else throw new Error("XSLT did not work!");
    },
    [dbs, getAttachment, resolveAddress, xetex]
  );

  return compiler;
};
