import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { usePouch } from "use-pouchdb";
import { DocumentAddress, useDbList } from "./couchdb-mgr/CouchDbContext";

type AttachmentContextType = {
  dbname: string;
  docid: string;
};
const AttachmentContext = createContext<AttachmentContextType>({ dbname: "", docid: "" });

interface AttachmentManagerContextProps {
  dbname: string;
  docid: string;
  children: ReactNode;
}
export const AttachmentManagerContext: React.FC<AttachmentManagerContextProps> = ({ dbname, docid, children }) => {
  const [state, setState] = useState<AttachmentContextType>({ dbname, docid });

  useEffect(() => {
    setState({ dbname, docid });
  }, [dbname, docid]);

  return <AttachmentContext.Provider value={state}>{children}</AttachmentContext.Provider>;
};

export function useSetAttachment() {
  const form = useFormContext();

  return useCallback(
    (name: string, data: Blob, type: string) => {
      form.setValue(`_attachments.${name}`, { content_type: type, data: data }, { shouldDirty: true });
    },
    [form]
  );
}

export function useRemoveAttachment() {
  const form = useFormContext();

  return useCallback(
    (name: string) => {
      form.setValue(`_attachments.${name}`, undefined, { shouldDirty: true });
    },
    [form]
  );
}

interface UseAttachmentResult {
  loading: boolean;
  error: null | any;
  attachment: undefined | Blob;
}
export function useAttachment(attachmentName: string): UseAttachmentResult {
  const [res, setRes] = useState<UseAttachmentResult>({ loading: false, error: null, attachment: undefined });
  const { dbname, docid } = useContext(AttachmentContext);

  const form = useFormContext();
  const formData = form.watch(`_attachments.${attachmentName}.data`);

  const db = usePouch(dbname);

  useEffect(() => {
    // check source of data.
    // Priority is: 1. formData, 2. loadedData

    if (formData) {
      //console.log("attachment taken from form data", docid, attachmentName, formData);
      setRes({ loading: false, error: null, attachment: formData });
    } else if (attachmentName) {
      //console.log("attachment loaded from database", docid, attachmentName);
      setRes({ loading: true, error: null, attachment: undefined });
      db.getAttachment(docid, attachmentName)
        .then((res) => {
          const b = res as Blob;
          setRes({ loading: false, error: null, attachment: b });
        })
        .catch((e) => {
          console.log(e);
          setRes({ loading: false, error: e, attachment: undefined });
        });
    } else {
      //console.log("attachment undefined", docid, attachmentName);
      setRes({ loading: false, error: null, attachment: undefined });
    }
  }, [attachmentName, db, docid, formData]);

  return res;
}

export function useGetAttachmentFromForm({ dbname, docid }: DocumentAddress, attachmentName: string) {
  const form = useFormContext();
  const formData = form.watch(`_attachments.${attachmentName}.data`);

  const db = usePouch(dbname);

  const getAttachment = useCallback(async () => {
    if (formData) return formData;

    try {
      return await db.getAttachment(docid, attachmentName);
    } catch (e) {
      console.log(e);
    }
  }, [attachmentName, db, docid, formData]);

  return getAttachment;
}

export function useGetAttachmentFromObject() {
  const getAttachment = useCallback(async (obj: any, db: PouchDB.Database, attachmentName: string): Promise<Blob> => {
    const objAttachment = (obj as any)._attachments[attachmentName].data;
    if (objAttachment) return objAttachment;

    try {
      if (obj && obj._id) return (await db.getAttachment(obj._id, attachmentName)) as Blob;
    } catch (e) {
      console.log(e);
      throw new Error(e as string);
    }
    throw new Error("ERROR");
  }, []);

  return getAttachment;
}

export function useDataUrlFromBlob(data?: Blob) {
  const [url, setUrl] = useState<string | undefined>();

  useEffect(() => {
    if (data) setUrl(URL.createObjectURL(data));
    else setUrl(undefined);
    return () => {
      setUrl((url) => {
        if (url) URL.revokeObjectURL(url);
        return undefined;
      });
    };
  }, [data]);

  return url;
}

export interface DocumentAttachment {
  name: string;
  data?: Blob;
}
export const useAttachments = ({ dbname, docid }: DocumentAddress) => {
  const dbs = useDbList();

  const [res, setRes] = useState<DocumentAttachment[]>([]);
  useEffect(() => {
    const fx = async () => {
      const db = dbs?.byName(dbname);
      if (db && docid) {
        const doc = await db.db.get(docid, { attachments: true, binary: true });
        if (!doc._attachments) setRes([]);
        else {
          const att = Object.keys(doc._attachments).map((name: string) => {
            if (!doc._attachments) return { name: "INTERNAL ERROR!!!" };
            const att = doc._attachments[name] as any;
            return { name: name, data: att.data };
          });
          setRes(att);
        }
      } else setRes([]);
    };
    fx();
  }, [dbs, docid]);

  return res;
};
