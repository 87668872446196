import { useCallback } from "react";
import { usePouch } from "use-pouchdb";
import { toIndexableString } from "pouchdb-collate";
import { plainToClass } from "class-transformer";

import { v4 as uuidv4 } from "uuid";

import { objectToDateTime } from "../lib/DateTimeHelper";
import { BaseObject, useAllFromViewGeneric } from "./BaseObject";

export interface ContentObjectBase {
  type: string;
  alsAnhang?: boolean;
}

export interface ContentObjectText extends ContentObjectBase {
  readonly type: "text";
  text: string;
  alsAnhang?: boolean;
}

export interface ContentObjectCode extends ContentObjectBase {
  readonly type: "code";
  language: string;
  code: string;
  alsAnhang?: boolean;
}

export interface FileData {
  /** uuid will be used for the attachment id and the exported filename for LaTeX */
  uuid: string | undefined;
  /** z.B. image/png */
  type: string | undefined;
  filename: string | undefined;
}

export interface ContentObjectImage extends ContentObjectBase {
  readonly type: "image";

  uuid: string;
  bildunterschrift: string;
  quellenangabe: string;

  data: FileData;

  rotation: number;
  width: number;
  widthUnit: string;

  alsAnhang: boolean;

  workFile: FileData;
}

export type ContentTypes = ContentObjectText | ContentObjectImage | ContentObjectCode;

export interface Punkt {
  text: string;
  afb1: number;
  afb2: number;
  afb3: number;
  zeit: number;
}

export const createPunkt = (): Punkt => {
  return { text: "", afb1: 0, afb2: 0, afb3: 0, zeit: 0 };
};

export interface Lösung {
  contentArray: ContentTypes[];
  punkte: Punkt[];
}

export const createLösung = (): Lösung => {
  return {
    contentArray: [],
    punkte: [],
  };
};

export interface AufgabeDefinition {
  title: string;
  contentArray: ContentTypes[];
  lösung: Lösung;
  lösungsplatzInCm: number;
  teilaufgaben: AufgabeDefinition[];
  neueSeite: boolean;
}

export const createAufgabeDefinition = (): AufgabeDefinition => {
  return {
    title: "",
    contentArray: [],
    lösung: createLösung(),
    lösungsplatzInCm: 0,
    teilaufgaben: [],
    neueSeite: false,
  };
};

export interface AufgabeMetaInfo {
  //title: string;
  description: ContentTypes[];
  tags: string[];
}

export const createAufgabeMetaInfo = (): AufgabeMetaInfo => {
  const now = new Date();

  return {
    //title: "",
    description: [],
    tags: [],
  };
};

export class Aufgabe extends BaseObject {
  readonly type = "aufgabe";

  metainfo: AufgabeMetaInfo;
  definition: AufgabeDefinition;

  public constructor() {
    super();

    const now = new Date();
    this.creationDate = objectToDateTime(now);
    this.lastEditDate = objectToDateTime(now);

    this.metainfo = createAufgabeMetaInfo();
    this.definition = createAufgabeDefinition();
  }

  static plainToClass(data: unknown) {
    if ((data as any).type === "aufgabe") return plainToClass(Aufgabe, data);
    return undefined;
  }

  public readableName() {
    if (this.name) return this.name;
    return this.creationDate;
  }
}

export function useSaveAufgabe(dbname: string) {
  const db = usePouch(dbname);
  return useCallback(
    async (aufgabe: Aufgabe) => {
      try {
        const now = new Date();
        aufgabe.lastEditDate = objectToDateTime(now);
        if (!aufgabe._id) {
          aufgabe._id = toIndexableString([
            "aufgabe",
            aufgabe.creationDate,
            uuidv4().toString(),
            // eslint-disable-next-line no-control-regex
          ]).replace(/\u0000/g, "\u0001");
        }
        const res = await db.put<any>(aufgabe);
        aufgabe._id = res.id;
        aufgabe._rev = res.rev;
        return aufgabe;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    [db]
  );
}

export function useAllAufgaben(dbname: string, includeDocs: boolean) {
  const allData = useAllFromViewGeneric<Aufgabe>({
    dbname,
    viewName: "klausurtool/allAufgaben",
    includeDocs,
    plainToClassFct: Aufgabe.plainToClass,
  });
  return allData;
}
