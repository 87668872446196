import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { UsePdfCompilerProps, usePdfFromLatex } from "../core/UseServerXeTeXCompiler";
import CodeViewer from "./CodeViewer";

interface LatexViewProps {
  props: UsePdfCompilerProps;
  inProgressChanged?: (inProgress: boolean) => void;
}

const LatexView: React.FC<LatexViewProps> = ({ props, inProgressChanged }) => {
  const pdf = usePdfFromLatex(props);

  useEffect(() => {
    if (inProgressChanged) inProgressChanged(pdf.inProgress);
  }, [inProgressChanged, pdf.inProgress]);

  return (
    <>
      {pdf.inProgress && <CircularProgress />}
      {pdf.errormessage && (
        <div style={{ zIndex: 255, width: "100%", height: "80vh", maxHeight: "80vh" }}>
          <CodeViewer language={"bash"} data={pdf.errormessage} />
        </div>
      )}
      {pdf.success && pdf.dataUrl && (
        <object
          data={pdf.dataUrl}
          type="application/pdf"
          style={{ zIndex: 255, width: "100%", height: "85vh", maxHeight: "85vh" }}
        >
          Fehler beim PDF-Anzeigen!
        </object>
      )}
    </>
  );
};

export default LatexView;
