import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { dbBaseUrl } from "./HelperClasses";

interface ChangePasswordDlgProps {
  user: any;
  open: boolean;
  handleClose: () => void;
}
const ChangePasswordDlg: React.FC<ChangePasswordDlgProps> = ({ user, open, handleClose }) => {
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");

  const [saveAllowed, setSaveAllowed] = useState(false);
  useEffect(() => {
    setSaveAllowed(pw1 === pw2 && pw1.length > 3);
  }, [pw1, pw2]);

  const handleSave = async () => {
    user.password = pw1;
    const newUserUrl = new URL(`./_users/${user._id}`, dbBaseUrl);
    const request = await fetch(newUserUrl.toString(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    if (request.status !== 201) {
      const result = await request.json();
      console.log(request, result);
    }
    setPw1("");
    setPw2("");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Passwort ändern für Benutzer "{user && user.name}"</DialogTitle>
      <DialogContent>
        <TextField label="Passwort" type="password" value={pw1} onChange={(e) => setPw1(e.target.value)} />
        <TextField label="Passwort wiederholen" type="password" value={pw2} onChange={(e) => setPw2(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Abbruch
        </Button>
        <Button disabled={!saveAllowed} onClick={handleSave}>
          Neues Passwort speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDlg;
