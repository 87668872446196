import { Box, Button, SxProps, Typography } from "@mui/material";
import React from "react";
import { downloadFromDataUrl } from "./download";
import MyKlausurtoolDropZone, { DataRendererComponentProps } from "./MyKlausurtoolDropZone";
import { useEditModeContext, useEditableViewContext } from "./EditableView";

const RendererComponent: React.FC<DataRendererComponentProps> = ({ sx, data, dataUrl }) => {
  return (
    <>
      {!dataUrl && (
        <Box sx={{ border: "1pt solid black", minHeight: "50px" }}>
          <Typography>Datei hier ablegen!</Typography>
        </Box>
      )}

      {dataUrl && (
        <Box sx={{ border: "1pt solid black", minHeight: "50px" }}>
          <Button
            variant="contained"
            onClick={async () => {
              if (data.filename && dataUrl) downloadFromDataUrl(data.filename, dataUrl);
            }}
          >
            {data.filename}
          </Button>
        </Box>
      )}
    </>
  );
};

interface II {
  title?: string;
  fieldName: string;
  sx?: SxProps;
}

const MyAllFileDropZone: React.FC<II> = ({ title, fieldName, sx }: II) => {
  const { editModeEnabled } = useEditModeContext();
  return (
    <>
      <MyKlausurtoolDropZone
        sx={[
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        title={title}
        fieldName={fieldName}
        editable={editModeEnabled}
        DataRendererComponent={RendererComponent}
      />
    </>
  );
};

export default MyAllFileDropZone;
