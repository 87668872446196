import { useCallback } from "react";
import { usePouch } from "use-pouchdb";
import { objectToDateTime } from "../lib/DateTimeHelper";
import { BaseObject, DocumentTypes, useAllFromViewGeneric } from "./BaseObject";
import { toIndexableString } from "pouchdb-collate";
import { v4 as uuidv4 } from "uuid";
import { plainToClass } from "class-transformer";

export interface XSLFileData {
  readonly type: "filedata";
  data: string;
  filename: string;
}
/*export interface XSLFileLink {
  readonly type: "filelink";
  dbname: string;
  docid: string;
}*/

export class XSLTemplate extends BaseObject {
  readonly type = "xsltemplate";

  name: string;

  targetType: DocumentTypes;
  xslFiles: XSLFileData[];

  public constructor() {
    super();
    this.name = "";

    this.xslFiles = [];
    this.xslFiles.push({ type: "filedata", data: "", filename: "main.xsl" });
    this.targetType = "klausur";

    const now = new Date();
    this.lastEditDate = objectToDateTime(now);
    this.creationDate = objectToDateTime(now);
  }

  static plainToClass(data: unknown) {
    if ((data as any).type === "xsltemplate") return plainToClass(XSLTemplate, data);
    return undefined;
  }
}

export function useSaveXSLTemplate(dbname: string) {
  const pouchDb = usePouch(dbname);

  return useCallback(
    async (template: XSLTemplate) => {
      try {
        const now = new Date();
        template.lastEditDate = objectToDateTime(now);
        if (!template._id) {
          template._id = toIndexableString([
            "xsltemplate",
            template.creationDate,
            uuidv4().toString(),
            // eslint-disable-next-line no-control-regex
          ]).replace(/\u0000/g, "\u0001");
        }
        const res = await pouchDb.put<any>(template);
        template._id = res.id;
        template._rev = res.rev;
        return template;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    [pouchDb]
  );
}

export function useAllTemplates(dbname: string, includeDocs: boolean) {
  const allData = useAllFromViewGeneric<XSLTemplate>({
    dbname,
    viewName: "klausurtool/allTemplates",
    includeDocs,
    plainToClassFct: XSLTemplate.plainToClass,
  });
  return allData;
}
