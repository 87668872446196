import React from "react";
import { ComponentType } from "react";

export const withContextSelector = <TProps extends unknown, TValue extends unknown>(
  Component: ComponentType<TProps & Record<string, TValue>>,
  selectors: Record<string, (data: any) => TValue>,
  useContextFunction: () => any
) => {
  // memoising component generally for every prop
  const MemoisedComponent = React.memo(Component) as unknown as ComponentType<Record<string, TValue>>;

  return (props: Partial<TProps> & Record<string, TValue>) => {
    // extracting everything from context
    const ctx = useContextFunction();

    // mapping keys that are coming from "selectors" argument
    // to data from context
    const contextProps = Object.keys(selectors).reduce((acc: any, key) => {
      acc[key] = selectors[key](ctx);

      return acc;
    }, {});

    // spreading all props to the memoised component
    return <MemoisedComponent {...props} {...contextProps} />;
  };
};
