import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { IOpenDocument } from "./views/MainEditorView";
import { useEditableViewContext } from "../lib/EditableView";

interface IsDirtyContextType {
  setIsDirty: (isDirty: boolean) => void;
}
const MainViewIsDirtyContext = createContext<IsDirtyContextType>({} as IsDirtyContextType);

interface MainViewIsDirtyContextComponentProps {
  children: ReactNode;
  doc: IOpenDocument;
  setOpenDocuments: (value: React.SetStateAction<IOpenDocument[]>) => void;
}
export const MainViewIsDirtyContextComponent: React.FC<MainViewIsDirtyContextComponentProps> = ({
  children,
  doc,
  setOpenDocuments,
}) => {
  const setIsDirty = useCallback(
    (isDirty: boolean) => {
      setOpenDocuments((oldState) => {
        const newState: IOpenDocument[] = [...oldState];

        let changed = false;
        for (let i = 0; i < newState.length; i += 1) {
          const cur: IOpenDocument = newState[i];
          if (cur === doc && cur.isDirty !== isDirty) {
            changed = true;
            cur.isDirty = isDirty;
          }
        }
        if (changed) return newState;
        else return oldState;
      });
    },
    [doc, setOpenDocuments]
  );

  const value = useMemo(() => {
    return { setIsDirty };
  }, [setIsDirty]);

  return <MainViewIsDirtyContext.Provider value={value}>{children}</MainViewIsDirtyContext.Provider>;
};

export const useMainViewIsDirtyContext = () => {
  return useContext(MainViewIsDirtyContext);
};

export const MainViewHandleDirtyChecker: React.FC = () => {
  const ctx = useEditableViewContext();

  const { setIsDirty } = useMainViewIsDirtyContext();

  useEffect(() => {
    setIsDirty(ctx.isDirty);
  }, [ctx.isDirty, setIsDirty]);

  return <></>;
};
