export function download(filename: string, blob: Blob) {
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
}

export function downloadFromDataUrl(filename: string, dataUrl: string) {
  let a = document.createElement("a");
  a.href = dataUrl;
  a.download = filename;
  a.click();
}
