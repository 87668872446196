// \textbackslash ref\{2987e71b-29c2-455b-9c7c-7edadec40fcc\}
function restoreCmdWithParam(text: string, command: string): string {
  const cmd = `\\textbackslash ${command}\\{`;
  let res = `${text}`;
  let pos = res.indexOf(cmd);

  while (pos !== -1) {
    const param = res.substring(res.indexOf("{", pos) + 1, res.indexOf("}", pos) - 1);
    res = `${res.substring(0, pos)}\\${command}{${param}}${res.substring(res.indexOf("}", pos) + 1)}`;
    pos = res.indexOf(cmd, pos + cmd.length);
  }

  return res;
}

export const pandoc = async (data: string): Promise<string> => {
  let res = "";

  const params = ["--mathjax", "-f", "html", "-t", "latex"];

  const response = await fetch("/server/api/v1/pandoc", {
    method: "POST",
    headers: {
      Accept: "text/plain",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: data, params: params }),
  });

  res = await response.text();

  // restore latex commands
  res = restoreCmdWithParam(res, "ref");
  res = restoreCmdWithParam(res, "pageref");

  return res;
};
