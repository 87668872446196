import { Button, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import EditableTextField from "../../../lib/EditableTextField";
import {
  EditModeEnableRenderProxy,
  useEditModeContext,
} from "../../../lib/EditableView";

interface Props2 {
  index: number;
  punktePath: string;
  textPath: string;
  removeFct: (index: number) => void;
  moveFct: (from: number, to: number) => void;
  listLength: number;
}

export const GutachtenBlueprintPunktView: React.FC<Props2> = ({
  index,
  punktePath,
  textPath,
  removeFct,
  moveFct,
  listLength,
}) => {
  return (
    <>
      <Grid item xs={9}>
        <Stack direction="row" sx={{ width: "100%" }}>
          <EditModeEnableRenderProxy>
            <Stack direction="row">
              <IconButton
                onClick={() => {
                  if (index > 0) moveFct(index, index - 1);
                }}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  if (index < listLength - 1) moveFct(index, index + 1);
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>
              <IconButton onClick={() => removeFct(index)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          </EditModeEnableRenderProxy>
          <EditableTextField
            placeholder="Kurzbeschreibung"
            fieldName={textPath}
            required
            multiline
          />
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <EditableTextField
          fieldName={`${punktePath}.afb1`}
          textAlign="center"
          type="number"
          required
        />
      </Grid>
      <Grid item xs={1}>
        <EditableTextField
          fieldName={`${punktePath}.afb2`}
          textAlign="center"
          type="number"
          required
        />
      </Grid>
      <Grid item xs={1}>
        <EditableTextField
          fieldName={`${punktePath}.afb3`}
          textAlign="center"
          type="number"
          required
        />
      </Grid>
    </>
  );
};

interface GutachtenPunkteViewProps {
  path: string;
  punktePathOffset: string;
}
export const GutachtenBlueprintPunkteView: React.FC<
  GutachtenPunkteViewProps
> = ({ path, punktePathOffset }) => {
  const { control } = useFormContext();
  const gutachtenPunkteArray = useFieldArray({
    name: path,
    control,
  });
  return (
    <Grid container spacing={1}>
      {gutachtenPunkteArray.fields.map((field, index) => {
        return (
          <GutachtenBlueprintPunktView
            key={field.id}
            index={index}
            punktePath={`${path}.${index}.${punktePathOffset}`}
            textPath={`${path}.${index}.erwarteteLeistung`}
            removeFct={gutachtenPunkteArray.remove}
            moveFct={gutachtenPunkteArray.move}
            listLength={gutachtenPunkteArray.fields.length}
          />
        );
      })}
      <EditModeEnableRenderProxy>
        <Grid item xs={12}>
          <Button
            sx={{ width: "100%" }}
            variant="outlined"
            onClick={() =>
              gutachtenPunkteArray.append({
                erwarteteLeistung: "",
                maxPunkte: { afb1: 0, afb2: 0, afb3: 0 },
                erreicht: { afb1: 0, afb2: 0, afb3: 0 },
              })
            }
          >
            Punkt hinzufügen
          </Button>
        </Grid>
      </EditModeEnableRenderProxy>
    </Grid>
  );
};
