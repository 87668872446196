import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Aufgabe, useSaveAufgabe } from "../core/Aufgabe";
import { DocumentTypes } from "../core/BaseObject";
import { Klausur, useSaveKlausur } from "../core/Klausur";
import { useSaveXSLTemplate, XSLTemplate } from "../core/XSLTemplate";
import DatabaseSelect from "../lib/couchdb-mgr/DatabaseSelect";
import { Database } from "../lib/couchdb-mgr/HelperClasses";
import { Gutachten, useSaveGutachten } from "../core/Gutachten";

interface Props {
  open: boolean;
  closeDialog: () => void;
  db: Database;
}

const NewItemDialog: React.FC<Props> = ({ open, closeDialog, db }: Props) => {
  const defaultType: DocumentTypes = "aufgabe";
  const [currentType, setCurrentType] = React.useState<DocumentTypes>(defaultType);
  const [selectedDb, setSelectedDb] = useState<Database | undefined>(db);

  const saveAufgabe = useSaveAufgabe(db.name);
  const saveKlausur = useSaveKlausur(db.name);
  const saveGutachten = useSaveGutachten(db.name);
  const saveTemplate = useSaveXSLTemplate(db.name);

  useEffect(() => {
    setSelectedDb(db);
  }, [db]);

  const handleAccept = () => {
    switch (currentType) {
      case "aufgabe":
        const a = new Aufgabe();
        saveAufgabe(a);
        break;
      case "klausur":
        const k = new Klausur();
        saveKlausur(k);
        break;
      case "gutachten":
        const g = new Gutachten();
        saveGutachten(g);
        break;
      case "xsltemplate":
        const t = new XSLTemplate();
        saveTemplate(t);
        break;
      default:
        throw new Error("not implemented: " + currentType);
    }
    closeDialog();
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentType((event.target as HTMLInputElement).value as DocumentTypes);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Neues Objekt anlegen</DialogTitle>
      <Divider />
      <DialogContent>
        <DatabaseSelect defaultDb={selectedDb} handleChange={setSelectedDb} />
        <FormControl>
          <FormLabel>Item Type:</FormLabel>
          <RadioGroup defaultValue={defaultType} name="fileTypeGroup" onChange={handleRadioChange}>
            <FormControlLabel value="aufgabe" control={<Radio />} label="Aufgabe" />
            <FormControlLabel value="klausur" control={<Radio />} label="Klausur" />
            <FormControlLabel value="gutachten" control={<Radio />} label="Gutachten" />
            <FormControlLabel value="prüfungsdurchführung" control={<Radio disabled />} label="Prüfungsdurchführung" />
            <FormControlLabel value="xsltemplate" control={<Radio />} label="XSL Template" />
          </RadioGroup>
        </FormControl>
        <DialogActions>
          <Button variant="contained" color="error" onClick={closeDialog}>
            Abbruch
          </Button>
          <Button autoFocus variant="contained" color="success" onClick={handleAccept}>
            Erzeugen!
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default NewItemDialog;
