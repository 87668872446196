export async function dataToURL(data: string) {
  try {
    const res = await fetch(data);
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

export async function arrayBufferToBase64(data: ArrayBuffer) {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e: ProgressEvent<FileReader>) => resolve(e.target?.result);
    fileReader.readAsDataURL(new Blob([data]));
  });

  return result_base64;
}

export async function dataUrlToBlob(url: string): Promise<ArrayBuffer> {
  try {
    const res = await fetch(url);
    return await res.arrayBuffer();
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function blob2Uint8Array(blob: Blob) {
  const uint8View = new Uint8Array(await blob.arrayBuffer());
  return uint8View;
}

export const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      //resolve((reader.result as string).substring((reader.result as string).lastIndexOf("base64") + 7));
      resolve(reader.result as string);
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(blob);
  });
};
