import { useCallback } from "react";
import { AufgabeDefinition, Punkt } from "../../Aufgabe";
import { GutachtenAufgabe } from "../../Gutachten";
import { useDbList } from "../../../lib/couchdb-mgr/CouchDbContext";
import { KlausurAufgabeReference, Klausur, KlausurAufgabe } from "../../Klausur";
import { makeChildAufgabeNummer } from "../helper";

//const helper = (aufgabe: AufgabeDefinition): GutachtenAufgabe => {};

function filterForPunkte(g: GutachtenAufgabe) {
  for (let i = 0; i < g.punkte.length; ++i) {
    if (g.punkte[i].maxPunkte.afb1 > 0 || g.punkte[i].maxPunkte.afb2 > 0 || g.punkte[i].maxPunkte.afb3 > 0) return true;
  }
  return false;
}

const makeGutachtenPunktFromAufgabeDefinition = (
  definition: AufgabeDefinition,
  aufgaben: GutachtenAufgabe[],
  aufgabeNummer: number[]
): void => {
  aufgaben.push({
    aufgabenummer: aufgabeNummer,
    bewertung: "",
    punkte: definition.lösung.punkte.map((punkt: Punkt) => {
      return {
        erwarteteLeistung: punkt.text,
        maxPunkte: { afb1: punkt.afb1, afb2: punkt.afb2, afb3: punkt.afb3 },
        erreicht: { afb1: 0, afb2: 0, afb3: 0 },
      };
    }),
  });

  definition.teilaufgaben.forEach((definition, index) =>
    makeGutachtenPunktFromAufgabeDefinition(definition, aufgaben, makeChildAufgabeNummer(aufgabeNummer, index + 1))
  );
};

const useKlausur2Blueprint = () => {
  const dbs = useDbList();

  const klausur2Blueprint = useCallback(
    async (dbname: string, docid: string): Promise<GutachtenAufgabe[]> => {
      const db = dbs.byName(dbname);
      if (!db) throw new Error(`Database not found: ${dbname}`);

      const klausur = Klausur.plainToClass(await db.db.get(docid));
      if (!klausur || klausur.type !== "klausur") throw new Error("Das geht nur mit Klausuren!!");

      const aufgaben: GutachtenAufgabe[] = [];
      await Promise.all(
        klausur.aufgaben.map(async (data, index): Promise<void> => {
          let aufgabe: KlausurAufgabe = data as KlausurAufgabe;
          if (data.type === "reference") {
            const ref = data as KlausurAufgabeReference;
            console.log(`requesting ${ref.docid} from database ${ref.dbname}`);
            const db = dbs.byName(ref.dbname);
            if (!db) throw new Error(`DB not existing: ${ref.dbname}`);
            aufgabe = await db.db.get(ref.docid);
            console.log("done.");
          }

          makeGutachtenPunktFromAufgabeDefinition(aufgabe.definition, aufgaben, [index + 1]);
        })
      );
      return aufgaben.filter((e) => filterForPunkte(e));
    },
    [dbs]
  );

  return klausur2Blueprint;
};

export default useKlausur2Blueprint;
