/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import LoesungEditor from "./LoesungEditor";
import { formatAufgabeNummer, makeChildAufgabeNummer } from "./helper";
import ContentArrayEditor from "./ContentArrayEditor";
import { createAufgabeDefinition } from "../Aufgabe";
import EditableTextField from "../../lib/EditableTextField";
import ColoredLayerView from "../../lib/ColoredLayerView";
import AddUpDownDeleteButton from "./AddUpDownDeleteButton";
import { KlausurAufgabe } from "../Klausur";
import { EditModeEnableRenderProxy, useEditModeContext, useEditableViewContext } from "../../lib/EditableView";
import { EditableCheckbox } from "../../lib/EditableCheckbox";

function DeleteQuestionDialog(
  showDeleteDialog: boolean,
  setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>,
  aufgabeNummer: number[],
  handleDeleteAufgabe: () => void
) {
  return (
    <Dialog
      open={showDeleteDialog}
      onClose={() => setShowDeleteDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Aufgabe ${formatAufgabeNummer(aufgabeNummer)} wirklich löschen?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Ganz sicher??</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteAufgabe}>Ja!</Button>
        <Button onClick={() => setShowDeleteDialog(false)} autoFocus>
          Lieber nicht!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  path: string;
  aufgabeNummer?: Array<number>;
  removeFct?: (index: number) => void;
  index?: number;
  insertNewAtIndex?: (index: number) => void;
  sx?: object;
}

export const AufgabeDefinitionEditor: React.FC<Props> = React.memo(
  ({ path, aufgabeNummer = [1], removeFct = undefined, insertNewAtIndex = undefined, index, sx = [] }) => {
    // const clipboard = useClipboard();
    const { control } = useFormContext();

    const teilaufgabenArray = useFieldArray({
      name: `${path}.teilaufgaben`,
      control,
    });

    const canCreateTeilaufgaben = aufgabeNummer.length < 3 && teilaufgabenArray.fields.length === 0;
    const canCreateAufgaben = insertNewAtIndex && index !== undefined && aufgabeNummer.length > 1;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const handleDeleteAufgabe = () => {
      if (removeFct !== undefined && index !== undefined) removeFct(index);
    };

    return (
      <ColoredLayerView depth={aufgabeNummer.length - 1} sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}>
        {DeleteQuestionDialog(showDeleteDialog, setShowDeleteDialog, aufgabeNummer, handleDeleteAufgabe)}
        <Grid container sx={{ pb: 2, pt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Aufgabe {formatAufgabeNummer(aufgabeNummer)}:</Typography>
          </Grid>
          <Grid item xs={8}>
            <EditableTextField label="Aufgabentitel" fieldName={`${path}.title`} multiline />
          </Grid>
          <Grid item xs={2}>
            <EditableTextField
              label="Platz für Lösungen in cm"
              fieldName={`${path}.lösungsplatzInCm`}
              multiline
              type="number"
            />
          </Grid>
          <Grid item xs={1}>
            <EditableCheckbox label="Neue Seite?" path={`${path}.neueSeite`} />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <ContentCopyIcon />
                </ListItemIcon>
                <ListItemText>Copy</ListItemText>
              </MenuItem>
              <EditModeEnableRenderProxy>
                <MenuItem onClick={handleClose} disabled={removeFct === undefined || index === undefined}>
                  <ListItemIcon>
                    <ContentCutIcon />
                  </ListItemIcon>
                  <ListItemText>Cut</ListItemText>
                </MenuItem>
              </EditModeEnableRenderProxy>
              <Divider />
              <EditModeEnableRenderProxy>
                <MenuItem
                  disabled={removeFct === undefined || index === undefined}
                  onClick={() => {
                    setShowDeleteDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </EditModeEnableRenderProxy>
            </Menu>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ mb: 1 }}>
              <CardContent>
                <Typography variant="subtitle2">Aufgabebeschreibung:</Typography>
                <ContentArrayEditor path={`${path}.contentArray`} />
                <div style={{ margin: 20 }} />
                <LoesungEditor path={`${path}.lösung`} />
              </CardContent>
            </Card>
          </Grid>
          <EditModeEnableRenderProxy>
            {canCreateTeilaufgaben && (
              <Grid item xs={12}>
                <Card sx={{ mb: 1 }}>
                  <CardContent>
                    <Button
                      sx={{ width: "100%" }}
                      startIcon={<SubdirectoryArrowRightIcon />}
                      variant="outlined"
                      onClick={() => {
                        teilaufgabenArray.append(createAufgabeDefinition());
                      }}
                    >
                      Neue Teilaufgabe
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </EditModeEnableRenderProxy>
          <Grid container item xs={12}>
            <Grid item xs={1} />
            <Grid item xs={11}>
              {teilaufgabenArray.fields.map((field, index) => {
                return (
                  <AddUpDownDeleteButton
                    key={field.id}
                    removeFct={teilaufgabenArray.remove}
                    moveFct={teilaufgabenArray.move}
                    index={index}
                    listLength={teilaufgabenArray.fields.length}
                  >
                    <AufgabeDefinitionEditor
                      path={`${path}.teilaufgaben.${index}`}
                      aufgabeNummer={makeChildAufgabeNummer(aufgabeNummer, index + 1)}
                      index={index}
                      removeFct={teilaufgabenArray.remove}
                      insertNewAtIndex={(index) => {
                        const n = createAufgabeDefinition(); //JSON.parse(JSON.stringify(new KlausurAufgabe()));
                        if (index >= teilaufgabenArray.fields.length) teilaufgabenArray.append(n);
                        else teilaufgabenArray.insert(index, n);
                      }}
                      sx={{ width: "100%" }}
                    />
                  </AddUpDownDeleteButton>
                );
              })}
            </Grid>

            <EditModeEnableRenderProxy>
              {canCreateAufgaben && (
                <Grid item xs={12}>
                  <Card sx={{ mb: 1 }}>
                    <CardContent>
                      <Button
                        sx={{ width: "100%" }}
                        startIcon={<ArrowDownwardIcon />}
                        variant="outlined"
                        onClick={() => {
                          insertNewAtIndex(index + 1);
                        }}
                      >
                        Neue Aufgabe
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </EditModeEnableRenderProxy>
          </Grid>
        </Grid>
      </ColoredLayerView>
    );
  }
);
