import Editor from "@monaco-editor/react";
import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import CodeViewer from "../../../lib/CodeViewer";
import { useEditModeContext } from "../../../lib/EditableView";
import { useXslFileContext } from "./XslTemplateView";

interface XslFileEditorProps {
  selectedFileIdx: number;
}
const XslFileEditor: React.FC<XslFileEditorProps> = ({ selectedFileIdx }) => {
  const { files } = useXslFileContext();
  const { editModeEnabled } = useEditModeContext();
  const form = useFormContext();
  const value = form.watch(`xslFiles.${selectedFileIdx}.data`);
  //const value = useWatch({ name: `xslFiles.${selectedFileIdx}.data` });

  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleXslChange = (value: string | undefined, ev: any /*monaco.editor.IModelContentChangedEvent*/) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      form.setValue(`xslFiles.${selectedFileIdx}.data`, value, { shouldDirty: true });
      //files.update(selectedFileIdx, { ...files.fields[selectedFileIdx], data: value });
    }, 1000);

    return () => {
      clearInterval(timeoutRef.current);
      form.setValue(`xslFiles.${selectedFileIdx}.data`, value, { shouldDirty: true });
      //files.update(selectedFileIdx, { ...files.fields[selectedFileIdx], data: value });
    };
  };

  return (
    <div style={{ height: "100%", minWidth: "200px", maxWidth: "100%" }}>
      {files.fields.map((file, index) => {
        return (
          <React.Fragment key={file.id}>
            {index === selectedFileIdx && (
              <>
                {editModeEnabled && (
                  <Editor
                    theme="vs-dark"
                    height="100%"
                    defaultLanguage="xml"
                    value={value}
                    onChange={handleXslChange}
                  />
                )}
                {!editModeEnabled && <CodeViewer language="markup" data={value} />}
              </>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default XslFileEditor;
