import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Provider } from "use-pouchdb";

import PouchDB from "pouchdb-browser";
import PouchDBFind from "pouchdb-find";
import { DatabaseList, dbBaseUrl, useCheckSessionState, useLogout } from "./HelperClasses";
import LoginDialog from "./LoginDialog";

PouchDB.plugin(PouchDBFind);

interface IState {
  loggedIn: boolean;
  inProgress: boolean;
  dbs?: DatabaseList;
  username?: string;
  password?: string;
  userRoles?: string[];
}

interface LoginData {
  loggedIn: boolean;
  username?: string;
  password?: string;
  userRoles?: string[];
}

export interface IAction {
  type:
    | "setDatabaseList"
    | "setUsername"
    | "setPassword"
    | "setUserRoles"
    | "setLoginData"
    | "setLoggedIn"
    | "setInProgress";
  payload: DatabaseList | string | boolean | string[] | LoginData;
}

const initialState: IState = {
  loggedIn: false,
  inProgress: true,
};

const reducer = (state: IState, action: IAction): IState => {
  const { type, payload } = action;
  switch (type) {
    case "setLoggedIn":
      return { ...state, loggedIn: payload as boolean };
    case "setDatabaseList":
      return { ...state, dbs: payload as DatabaseList };
    case "setUsername":
      return { ...state, username: payload as string };
    case "setPassword":
      return { ...state, password: payload as string };
    case "setUserRoles":
      return { ...state, userRoles: payload as string[] };
    case "setLoginData":
      return { ...state, ...(payload as LoginData) };
    case "setInProgress":
      return { ...state, inProgress: payload as boolean };
    default:
      throw new Error();
  }
};

interface ContextType {
  state: IState;
  dispatch: React.Dispatch<IAction>;
  logout: () => void;
}

var COUCH_DB_CTX = createContext<ContextType>({
  state: {} as IState,
  dispatch: {} as React.Dispatch<IAction>,
  logout: {} as () => void,
});

interface CouchDbContextProps {
  children: ReactNode;
}

const CouchDbContext: React.FC<CouchDbContextProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const checkSessionState = useCheckSessionState(dispatch);
  const logout = useLogout(dispatch);
  useEffect(() => {
    checkSessionState();
  }, []);

  const ctxValue = useMemo(() => {
    return { logout, state, dispatch };
  }, [logout, state]);

  const [multiPouchValue, setMultiPouchValue] = useState<any>(undefined);
  useEffect(() => {
    setMultiPouchValue({
      ...state.dbs?.dbsListObjectForProvider(),
      _users: new PouchDB(new URL("./_users", dbBaseUrl).toString()),
    });
  }, [state.dbs]);

  const keepAliveRef = useRef<NodeJS.Timer | undefined>();
  useEffect(() => {
    if (state.loggedIn) {
      console.log("starting keep alive request...");
      if (keepAliveRef.current) throw new Error("Some Error with CouchDB-Keep-Alive");

      keepAliveRef.current = setInterval(async () => {
        const upUrl = new URL("_up", dbBaseUrl);
        const request = await fetch(upUrl.toString(), {
          method: "GET",
        });
        if (request.status === 200) {
        } else {
          // some problem with login...
          console.log("Keep alive was not successful... logging out...");
          logout();
        }
      }, 30000);
    }
    return () => {
      if (keepAliveRef.current) {
        clearInterval(keepAliveRef.current);
        keepAliveRef.current = undefined;
      }
    };
  }, [state.loggedIn]);

  return (
    <>
      <COUCH_DB_CTX.Provider value={ctxValue}>
        {(!state.loggedIn || multiPouchValue === undefined) && <LoginDialog />}
        {state.loggedIn && multiPouchValue !== undefined && (
          <Provider default={Object.keys(multiPouchValue)[0]} databases={multiPouchValue}>
            {children}
          </Provider>
        )}
      </COUCH_DB_CTX.Provider>
    </>
  );
};

export const useCouchDbContext = () => {
  const ctx = useContext(COUCH_DB_CTX);
  if (typeof ctx.dispatch !== "function") throw Error("Context missing???");
  return ctx;
};

export const useDbList = () => {
  const ctx = useCouchDbContext();
  if (!ctx.state.dbs) throw new Error("Context Error??");
  return ctx.state.dbs;
};

export const useUsername = () => {
  const { state } = useCouchDbContext();
  return state.username;
};

export interface DocumentAddress {
  dbname: string;
  docid: string;
}

export const useResolveDocumentAddress = () => {
  const dbs = useDbList();
  const resolve = useCallback(
    async (address: DocumentAddress) => {
      const db = dbs.byName(address.dbname);
      if (db) {
        return db.db.get(address.docid);
      } else throw new Error(`Database not found: ${address.dbname}`);
    },
    [dbs]
  );
  return resolve;
};

export default CouchDbContext;
