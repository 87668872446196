import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DocumentAttachment } from "../lib/AttachmentManager";
import { CircularProgress, Typography } from "@mui/material";
import { useKlausur2Xml } from "./useKlausur2Xml";
import { useGutachten2Xml } from "./useGutachten2Xml";

interface ResultType {
  xml?: string;
  attachments: DocumentAttachment[];
  loading: boolean;
  error?: string;
}
interface Document2XmlContextReturnType {
  result: ResultType;
  setResult: React.Dispatch<React.SetStateAction<ResultType>>;
}
const Document2XmlContextReturn = createContext<Document2XmlContextReturnType>({} as Document2XmlContextReturnType);

interface ParamsType {
  dbname: string;
  docid: string;
  type: string;
}
interface Document2XmlContextParamsType {
  document?: ParamsType;
  setDocument: React.Dispatch<React.SetStateAction<ParamsType | undefined>>;
}
const Document2XmlContextParams = createContext<Document2XmlContextParamsType>({} as Document2XmlContextParamsType);

export const useDocument2XmlApi = () => {
  return useContext(Document2XmlContextParams);
};

export const useDocument2XmlResult = () => {
  return useContext(Document2XmlContextReturn);
};

const KlausurTransformer: React.FC = () => {
  const { document } = useDocument2XmlApi();
  const { setResult } = useDocument2XmlResult();

  const klausurProps = useMemo(() => {
    return document && document.type === "klausur" ? { dbname: document.dbname, docid: document.docid } : undefined;
  }, [document]);
  const klausurXML = useKlausur2Xml(klausurProps);
  const gutachtenProps = useMemo(() => {
    return document && document.type === "gutachten" ? { dbname: document.dbname, docid: document.docid } : undefined;
  }, [document]);
  const gutachtenXML = useGutachten2Xml(gutachtenProps);

  useEffect(() => {
    if (document && document.type === "klausur") {
      setResult(klausurXML);
    } else if (document && document.type === "gutachten") {
      setResult(gutachtenXML);
    } else {
      setResult({ loading: false, attachments: [] });
    }
  }, [document, gutachtenXML, klausurXML, setResult]);

  return <></>;
};

interface Document2XmlContextComponentProps {
  children: ReactNode;
}
export const Document2XmlContextComponent: React.FC<Document2XmlContextComponentProps> = ({ children }) => {
  const [result, setResult] = useState<ResultType>({
    attachments: [],
    loading: false,
  });
  const resultValue = useMemo(() => {
    return { result, setResult };
  }, [result]);

  const [document, setDocument] = useState<ParamsType | undefined>();
  const paramsValue = useMemo(() => {
    return { document, setDocument };
  }, [document]);

  return (
    <Document2XmlContextParams.Provider value={paramsValue}>
      <Document2XmlContextReturn.Provider value={resultValue}>
        <KlausurTransformer />
        {children}
      </Document2XmlContextReturn.Provider>
    </Document2XmlContextParams.Provider>
  );
};

interface DocumentLoadingProxyProps {
  children?: ReactNode;
}
export const DocumentLoadingProxy: React.FC<DocumentLoadingProxyProps> = ({ children }) => {
  // loading: show progress
  // no klausur (!loading && !xml): show "please select klausur"
  // !loading && error: show error
  // !loading && !error && xml: show children

  const { result } = useDocument2XmlResult();

  return (
    <>
      {result.loading && <CircularProgress />}
      {!result.loading && !result && <Typography>Bitte Klausur auswählen!</Typography>}
      {!result.loading && result.error && <Typography>{result.error}</Typography>}
      {!result.loading && !result.error && result.xml && children}
    </>
  );
};
