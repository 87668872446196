import { plainToClass } from "class-transformer";
import { useCallback } from "react";
import { toIndexableString } from "pouchdb-collate";
import { usePouch } from "use-pouchdb";
import { objectToDateTime } from "../lib/DateTimeHelper";
import { AufgabeDefinition, ContentTypes, createAufgabeDefinition } from "./Aufgabe";
import { BaseObject, useAllFromViewGeneric } from "./BaseObject";
import { v4 as uuidv4 } from "uuid";

interface KlausurMetaInfo {
  art: string;
  fach: string;
  hilfsmittel: {
    contentArray: ContentTypes[];
  };
  schule: string;
  schullogo?: ImageData;
  schulform: string;
  schwerpunkt: string;
  einleitungPrüferExemplar: {
    contentArray: ContentTypes[];
  };
  einleitungSchülerExemplar: {
    contentArray: ContentTypes[];
  };

  vorschlag: string;
  anforderungsbereich: string;
}

const createKlausurMetaInfo = (): KlausurMetaInfo => {
  return {
    art: "",
    fach: "",
    hilfsmittel: {
      contentArray: [
        {
          type: "text",
          text: "<ul><li>Fremdwörterlexikon</li><li>Wörterbuch der deutschen Rechtschreibung</li><li>Taschenrechner</li><li>Formelsammlung (TITEL?)</li></ul><p>Hinweis:<br/>Beim Einsatz von programmierbaren Taschenrechnern ist der vorhandene Speicher zu löschen!</p>",
          alsAnhang: false,
        },
      ],
    },
    schule: "",
    schulform: "",
    schwerpunkt: "",
    einleitungPrüferExemplar: { contentArray: [] },
    einleitungSchülerExemplar: { contentArray: [] },
    vorschlag: "",
    anforderungsbereich: "",
  };
};

export class KlausurAufgabeReference {
  readonly type: "reference";
  dbname: string;
  docid: string;

  public constructor(dbname: string, docid: string) {
    this.type = "reference";
    this.dbname = dbname;
    this.docid = docid;
  }
}

export class KlausurAufgabe {
  readonly type = "klausuraufgabe";
  name: string;
  definition: AufgabeDefinition;

  public constructor() {
    this.name = "";
    this.definition = createAufgabeDefinition();
  }
}

export class Klausur extends BaseObject {
  readonly type = "klausur";

  metainfo: KlausurMetaInfo;
  aufgaben: (KlausurAufgabe | KlausurAufgabeReference)[];

  public constructor() {
    super();

    const now = new Date();
    this.creationDate = objectToDateTime(now);
    this.lastEditDate = objectToDateTime(now);

    this.metainfo = createKlausurMetaInfo();
    this.aufgaben = [];
  }

  static plainToClass(data: unknown) {
    if ((data as any).type === "klausur") return plainToClass(Klausur, data);
    return undefined;
  }

  public readableName() {
    if (this.metainfo.art.length > 0 && this.metainfo.fach.length > 0)
      return `${this.metainfo.fach}: ${this.metainfo.art}`;
    return this.creationDate;
  }
}

export function useSaveKlausur(dbname: string) {
  const pouchDb = usePouch(dbname);

  return useCallback(
    async (klausur: Klausur) => {
      try {
        const now = new Date();
        klausur.lastEditDate = objectToDateTime(now);
        if (!klausur._id) {
          klausur._id = toIndexableString([
            "klausur",
            klausur.creationDate,
            uuidv4().toString(),
            // eslint-disable-next-line no-control-regex
          ]).replace(/\u0000/g, "\u0001");
        }
        const res = await pouchDb.put<any>(klausur);
        klausur._id = res.id;
        klausur._rev = res.rev;
        return klausur;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    [pouchDb]
  );
}

export function useAllKlausuren(dbname: string, includeDocs: boolean) {
  const allData = useAllFromViewGeneric<Klausur>({
    dbname,
    viewName: "klausurtool/allKlausuren",
    includeDocs,
    plainToClassFct: Klausur.plainToClass,
  });
  return allData;
}
