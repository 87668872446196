import { Stack, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SimpleDialog from "../../lib/SimpleDialog";
import { useState } from "react";
import React from "react";
import { useEditModeContext } from "../../lib/EditableView";

interface AddUpDownDeleteButtonProps {
  children: React.ReactNode;
  removeFct: (index: number) => void;
  moveFct: (from: number, to: number) => void;
  index: number;
  listLength: number;
}
const AddUpDownDeleteButton: React.FC<AddUpDownDeleteButtonProps> = React.memo(
  ({ children, removeFct, moveFct, index, listLength }) => {
    const [showReallyDeleteDlg, setShowReallyDeleteDlg] = useState(false);
    const { editModeEnabled: visible } = useEditModeContext();

    return (
      <>
        <SimpleDialog
          open={showReallyDeleteDlg}
          handleOK={() => {
            removeFct(index);
            setShowReallyDeleteDlg(false);
          }}
          handleCancel={() => setShowReallyDeleteDlg(false)}
          title={"Wirklich endgültig löschen?"}
        />
        <Stack direction="row" sx={{ width: "100%" }}>
          {children}
          <Stack sx={visible === false ? { display: "none" } : {}}>
            <IconButton
              onClick={() => {
                if (index > 0) moveFct(index, index - 1);
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                if (index < listLength - 1) moveFct(index, index + 1);
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton onClick={() => setShowReallyDeleteDlg(true)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
      </>
    );
  }
);

export default AddUpDownDeleteButton;
