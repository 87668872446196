import { Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Paper, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import EditableTextField from "../../lib/EditableTextField";
import MyAllFileDropZone from "../../lib/MyAllFileDropZone";
import MyImageDropZone from "../../lib/MyImageDropZone";
import ReactHookFormSelect from "../../lib/ReactHookFormSelect";
import useClipboard from "../../lib/useClipboard";

interface ImageEditorComponentProps {
  path: string;
  sx?: object;
}
const ImageEditorComponent: React.FC<ImageEditorComponentProps> = React.memo(({ path, sx }) => {
  const form = useFormContext();

  const { writeText } = useClipboard();

  return (
    <Paper elevation={3} sx={[{ padding: 2, mb: 3 }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">Abbildung</Typography>
        </Grid>
        <Grid item xs={12}>
          <EditableTextField
            fieldName={`${path}bildunterschrift`}
            label="Bildunterschrift"
            placeholder="Bildunterschrift"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <EditableTextField fieldName={`${path}quellenangabe`} label="Quellenangabe" placeholder="Quellenangabe" />
        </Grid>
        <Grid item xs={6}>
          <MyImageDropZone fieldName={`${path}data`} sx={{ height: "100px" }} />
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={2}>
            <EditableTextField
              fieldName={`${path}rotation`}
              label="Rotation (in Grad)"
              placeholder="Rotation (in Grad)"
              type="number"
            />
            <Controller
              control={form.control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label={"als Anhang"} />
              )}
              name={`${path}alsAnhang`}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <EditableTextField
              fieldName={`${path}width`}
              label="Breite"
              placeholder="Breite"
              type="number"
              step={0.05}
            />
            <ReactHookFormSelect
              name={`${path}widthUnit`}
              label={"Einheit"}
              error={undefined}
              required={true}
              control={form.control}
            >
              <MenuItem value={"cm"}>cm</MenuItem>
              <MenuItem value={"\\textwidth"}>\textwidth (max. 1.0!)</MenuItem>
            </ReactHookFormSelect>
          </Stack>
          <Box sx={{ pt: 4 }}>
            <MyAllFileDropZone title="Arbeitsdatei" fieldName={`${path}workFile`} />
          </Box>
          <Box sx={{ pt: 4 }}>
            <Button
              variant="contained"
              disabled={form.watch(`${path}bildunterschrift`).length === 0}
              onClick={() => {
                writeText(
                  `Abbildung \\ref{fig:${form.getValues(`${path}uuid`)}} auf Seite \\pageref{fig:${form.getValues(
                    `${path}uuid`
                  )}}`
                );
              }}
            >
              Kopiere Referenz in die Zwischenablage
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default ImageEditorComponent;
