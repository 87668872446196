import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { createGutachtenAufgabe } from "../../Gutachten";
import { GutachtenBlueprintPunkteView } from "./GutachtenPunkteView";
import AddUpDownDeleteButton from "../AddUpDownDeleteButton";
import { useState } from "react";
import DocumentSelectorDialog from "../DocumentSelectorDialog";
import { DocumentTypes } from "../../BaseObject";
import { DbDirViewFileTypes } from "../../DbDirView";
import useKlausur2Blueprint from "./UseKlausur2Blueprint";
import { EditModeEnableRenderProxy } from "../../../lib/EditableView";
import { incAufgabeNummer } from "../helper";
import { yellow } from "@mui/material/colors";
import { SimpleEditableTextField } from "../../../lib/EditableTextField";

interface GutachtenAufgabeArrayViewProps {
  path: string;
  aufgabeArray: any;
  index: number;
}
export const GutachtenAufgabeArrayView: React.FC<
  GutachtenAufgabeArrayViewProps
> = ({ path, aufgabeArray, index }) => {
  const form = useFormContext();

  const [nums, setNums] = useState(
    form.getValues(`${path}.aufgabenummer`).join(".")
  );
  const handleNumChange = (e: any) => {
    const numsString = e.target.value;
    setNums(numsString);
    let nums = numsString.split(/[.]/);
    nums = nums
      .filter((num: string) => num.length > 0)
      .map((num: string) => parseInt(num.trim()));
    form.setValue(`${path}.aufgabenummer`, nums, { shouldDirty: true });
  };

  return (
    <div
      style={{
        backgroundColor: index % 2 === 0 ? yellow[300] : "white",
        borderBottom: "1pt solid grey",
        paddingBottom: "10pt",
        paddingTop: "10pt",
      }}
    >
      <AddUpDownDeleteButton
        removeFct={aufgabeArray.remove}
        moveFct={aufgabeArray.move}
        index={index}
        listLength={aufgabeArray.fields.length}
      >
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <SimpleEditableTextField value={nums} onChange={handleNumChange} />
          </Grid>
          <Grid item xs={11}>
            <GutachtenBlueprintPunkteView
              path={`${path}.punkte`}
              punktePathOffset="maxPunkte"
            />
          </Grid>

          <EditModeEnableRenderProxy>
            <Grid item xs={12}>
              <Card sx={{ mb: 1 }}>
                <CardContent>
                  <Button
                    sx={{ width: "100%" }}
                    startIcon={<ArrowDownwardIcon />}
                    variant="outlined"
                    onClick={() => {
                      aufgabeArray.insert(
                        index + 1,
                        createGutachtenAufgabe(
                          incAufgabeNummer(
                            aufgabeArray.fields.length > 0
                              ? aufgabeArray.fields[
                                  aufgabeArray.fields.length - 1
                                ].aufgabenummer
                              : undefined
                          )
                        )
                      );
                    }}
                  >
                    Neue Aufgabe
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </EditModeEnableRenderProxy>
        </Grid>
      </AddUpDownDeleteButton>
    </div>
  );
};

interface GutachtenBlueprintEditorProps {
  path: string;
}

const GutachtenBlueprintEditor: React.FC<GutachtenBlueprintEditorProps> = ({
  path,
}) => {
  const aufgaben = useFieldArray({ name: path });

  const [showImportDlg, setShowImportDlg] = useState(false);

  const klausur2Blueprint = useKlausur2Blueprint();

  return (
    <>
      <DocumentSelectorDialog
        open={showImportDlg}
        title="Klausur zum Importieren wählen:"
        showFileTypes={DbDirViewFileTypes.KLAUSUREN}
        handleOpen={async function (
          dbname: string,
          docid: string,
          type: DocumentTypes
        ): Promise<void> {
          const blueprint = await klausur2Blueprint(dbname, docid);
          aufgaben.replace(blueprint);
          setShowImportDlg(false);
        }}
        handleCancel={() => setShowImportDlg(false)}
      />
      <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
        <Stack spacing={0}>
          <Stack direction="row" spacing={5}>
            <Typography variant="h6">Vorlage für die Gutachten:</Typography>
            <EditModeEnableRenderProxy>
              <Button
                variant="contained"
                onClick={() => setShowImportDlg(true)}
              >
                Vorlage von Klausur importieren
              </Button>
            </EditModeEnableRenderProxy>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <Typography variant="subtitle2">Nummer:</Typography>
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Typography variant="subtitle2">Beschreibung:</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">AFB1</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">AFB2</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="subtitle2">AFB3</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {aufgaben.fields.length > 0 && (
            <>
              {aufgaben.fields.map((gutachtenAufgabe, index) => {
                return (
                  <GutachtenAufgabeArrayView
                    key={gutachtenAufgabe.id}
                    aufgabeArray={aufgaben}
                    index={index}
                    path={`${path}.${index}`}
                  />
                );
              })}
            </>
          )}
          <EditModeEnableRenderProxy>
            {aufgaben.fields.length === 0 && (
              <Card sx={{ mb: 1 }}>
                <CardContent>
                  <Button
                    sx={{ width: "100%" }}
                    startIcon={<ArrowDownwardIcon />}
                    variant="outlined"
                    onClick={() => {
                      let num: number[] = [1];
                      if (aufgaben.fields.length > 0) {
                        const l = aufgaben.fields[aufgaben.fields.length - 1];
                        num = incAufgabeNummer((l as any).aufgabenummer);
                      }
                      aufgaben.append(createGutachtenAufgabe(num));
                    }}
                  >
                    Neue Aufgabe
                  </Button>
                </CardContent>
              </Card>
            )}
          </EditModeEnableRenderProxy>
        </Stack>
      </Paper>
    </>
  );
};

export default GutachtenBlueprintEditor;
