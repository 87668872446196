import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { GutachtenEintrag, useSaveGutachtenEintrag } from "../../Gutachten";
import React, { useCallback, useEffect, useState } from "react";
import EditableTextField from "../../../lib/EditableTextField";
import { EditModeEnableRenderProxy, EditableView, useEditableViewContext } from "../../../lib//EditableView";
import { GutachtenEintragPunkteView } from "./GutachtenEintragPunkteView";
import { DirtyEinträgeChecker } from "./GutachtenStructureView";

const Abzüge = [
  {
    idx: 0,
    abzüge: true,
    short: "sprachliche Verstöße, geringer Wortschatz, einfacher Satzbau",
    begründung:
      "Es liegt eine hohe Zahl sprachlicher Verstöße vor. Der Wortschatz ist sehr gering und es werden äußerst einfache Satzstrukturen verwendet.",
  },
  {
    idx: 1,
    abzüge: true,
    short: "geringer Umfang, fundamentale Fehler",
    begründung:
      "Angesichts des geringen Umfangs der Prüfungsarbeit sind die zahlreichen Verstöße gegen die sprachliche Richtigkeit als gravierend anzusehen, da sie im Bereich der Orthographie fundamentale Regeln betreffen.",
  },
  {
    idx: 2,
    abzüge: false,
    short: "keine Verstöße",
    begründung: "Es liegen keine gehäuften Verstöße gegen die sprachliche Richtigkeit oder Form vor.",
  },
  {
    idx: 3,
    abzüge: false,
    short: "viele Verstöße, aber komplexe Satzstrukturen und guter Wortschatz",
    begründung:
      "Die vorliegenden sprachlichen Verstöße verdeutlichen zwar insbesondere eine Schwäche in der Beherrschung der Zeichensetzung, die Verfasserin/der Verfasser verwendet allerdings komplexe Satzstrukturen und einen sehr differenzierten, variationsreichen Wortschatz.",
  },
  {
    idx: 4,
    abzüge: false,
    short: "Fehler aus Zeitmangel am Ende der Arbeit",
    begründung:
      "Die beträchtliche Zahl sprachlicher Verstöße tritt vorrangig im Schlussteil der Prüfungsarbeit auf und deshalb sind die Fehler angesichts der Länge, der Aspektvielfalt und des Gedankenreichtums der Prüfungsarbeit primär auf Zeitmangel, nicht aber auf generelle Defizite in der Beherrschung der Rechtschreibungs-, Zeichensetzungs- und Grammatikregeln zurückzuführen.",
  },
];

interface TemplateDialogProps {
  open: boolean;
  onClose: () => void;
}

const TemplateDialog: React.FC<TemplateDialogProps> = ({ open, onClose }) => {
  const [index, setIndex] = React.useState(2);

  useEffect(() => {
    // Index 2 -> keine Verstöße!
    setIndex(2);
  }, [open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIndex(parseInt((event.target as HTMLInputElement).value));
  };

  const { setValue } = useEditableViewContext();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Template auswählen:</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Als Richtwerte sollen gelten: Abzug eines Punktes bei durchschnittlich 5 Fehlern auf einer in normaler
          Schriftgröße beschriebenen Seite; Abzug von zwei Punkten bei durchschnittlich 7 und mehr Fehlern auf einer in
          normaler Schriftgröße beschriebenen Seite. Bei der Entscheidung über einen Punktabzug ist ein nur
          quantifizierendes Verfahren nicht sachgerecht. Vielmehr sind Zahl und Art der Verstöße zu gewichten und in
          Relation zu Wortzahl, Wortschatz und Satzbau zu setzen. Wiederholungsfehler werden in der Regel nur einmal
          gewertet. Ein Punktabzug muss ebenso wie in Grenzfällen ein Verzicht auf Punktabzug begründet werden.
        </DialogContentText>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={index}
            onChange={handleChange}
          >
            <FormLabel id="demo-controlled-radio-buttons-group">Kein Punktabzug:</FormLabel>
            {Abzüge.filter((e) => !e.abzüge).map((e) => {
              return <FormControlLabel key={e.idx} value={e.idx} control={<Radio />} label={e.short} />;
            })}
            <FormLabel id="demo-controlled-radio-buttons-group">Punktabzug:</FormLabel>
            {Abzüge.filter((e) => e.abzüge).map((e) => (
              <FormControlLabel key={e.idx} value={e.idx} control={<Radio />} label={e.short} />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbruch</Button>
        <Button
          onClick={() => {
            setValue("abzügeFormRechtschreibungBemerkung", Abzüge[index].begründung);
            onClose();
          }}
        >
          Verwenden!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface GutachtenEintragViewProps {
  sx?: object;
  dbname: string;
  eintrag: GutachtenEintrag;
}
const GutachtenEintragView: React.FC<GutachtenEintragViewProps> = ({ sx, dbname, eintrag }) => {
  const saveGutachtenEintrag = useSaveGutachtenEintrag(dbname);
  const saveFunction = useCallback(
    async (gutachtenEintragNeuRaw: any) => {
      //const klausurNeu = Klausur.plainToClass(klausurNeuRaw);
      if (gutachtenEintragNeuRaw) await saveGutachtenEintrag(gutachtenEintragNeuRaw);
      else throw new Error("INTERNAL ERROR!");
    },
    [saveGutachtenEintrag]
  );

  const [showTemplateDialog, setShowTemplateDialog] = useState(false);

  return (
    <Grid item xs={9} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <EditableView value={eintrag} dbname={dbname} saveFunction={saveFunction}>
        <TemplateDialog open={showTemplateDialog} onClose={() => setShowTemplateDialog(false)} />
        <DirtyEinträgeChecker eintragId={eintrag._id} />
        <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Gutachteneintrag</Typography>
            </Grid>
            <Grid item xs={4}>
              <EditableTextField fieldName="nachname" label="Nachname" />
            </Grid>
            <Grid item xs={4}>
              <EditableTextField fieldName="vorname" label="Vorname" />
            </Grid>
            <Grid item xs={4}>
              <EditableTextField fieldName="prüfungsgruppe" label="Prüfungsgruppe" />
            </Grid>
            <Grid item xs={3}>
              <EditableTextField
                fieldName="abzügeFormRechtschreibung"
                label="Abzüge Rechtschreibung/Form"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <EditableTextField fieldName="abzügeFormRechtschreibungBemerkung" label="Begründung" multiline />
            </Grid>
            <Grid item xs={3}>
              <EditModeEnableRenderProxy>
                <Button variant="outlined" onClick={() => setShowTemplateDialog(true)}>
                  Templates
                </Button>
              </EditModeEnableRenderProxy>
            </Grid>
          </Grid>
        </Paper>
        <GutachtenEintragPunkteView eintrag={eintrag} />
      </EditableView>
    </Grid>
  );
};

export default GutachtenEintragView;
