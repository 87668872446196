import React, { ReactNode } from "react";
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export interface MyContextMenuItem {
  title: string;
  icon?: ReactNode;
  handleClick: React.MouseEventHandler<HTMLLIElement>;
  dividerBefore?: boolean;
}

interface ContextMenuProps {
  items: MyContextMenuItem[];
}
const ContextMenu: React.FC<ContextMenuProps> = ({ items }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          if (items.length > 0) setAnchorEl(e.currentTarget);
        }}
        edge="end"
        aria-label="settings"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, index) => {
          return [
            item.dividerBefore && <Divider />,
            <MenuItem
              onClick={(e) => {
                handleClose();
                item.handleClick(e);
              }}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>,
          ];
        })}
      </Menu>
    </>
  );
};

export default ContextMenu;
