const xsltProcessor = async (xslFiles: { filename: string; data: string }[], xmlData: string): Promise<string> => {
  const request = await fetch("/server/api/v1/xslt", {
    method: "POST",
    headers: {
      Accept: "text/plain",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ xml: xmlData, xslFiles: xslFiles }),
  });

  return await request.text();
};

export default xsltProcessor;
