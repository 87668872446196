import { useCallback, useEffect, useState } from "react";
import { useView } from "use-pouchdb";
import { DocumentAttachment } from "../lib/AttachmentManager";
import { GutachtenAufgabe } from "./Gutachten";
import { pandoc } from "./pandoc";
import json2xml from "./json2xml";

const pandocAufgabe = async (aufgabe: GutachtenAufgabe) => {
  //(aufgabe.aufgabenummer as any) = aufgabe.aufgabenummer.join(".");
  (aufgabe.aufgabenummer as any) = aufgabe.aufgabenummer.map((num) => {
    return { num };
  });
  aufgabe.bewertung = await pandoc(aufgabe.bewertung);
  aufgabe.punkte = await Promise.all(
    aufgabe.punkte.map(async (punkt) => {
      punkt.erwarteteLeistung = await pandoc(punkt.erwarteteLeistung);
      return punkt;
    })
  );
  return { aufgabe };
};

const pandocAufgabeArray = async (aufgaben: GutachtenAufgabe[]) => {
  return await Promise.all(
    aufgaben.map(async (aufgabe) => {
      return await pandocAufgabe(aufgabe);
    })
  );
};

const gutachten2Xml = async (
  rows: {
    id: any;
    key: any;
    value: any;
    doc?: PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta> | undefined;
  }[]
) => {
  const gutachten = JSON.parse(JSON.stringify(rows[0].doc));
  delete gutachten._attachments;
  delete gutachten._id;
  delete gutachten._rev;
  const einträge = await Promise.all(
    rows
      .filter((row, index) => index !== 0)
      .map(async (row) => {
        const eintrag = JSON.parse(JSON.stringify(row.doc));
        delete eintrag._attachments;
        delete eintrag._id;
        delete eintrag._rev;
        delete eintrag.gutachtenId;

        eintrag.aufgaben = await pandocAufgabeArray(eintrag.aufgaben);

        return eintrag;
      })
  );
  gutachten.einträge = einträge.map((eintrag) => {
    return { eintrag };
  });
  gutachten.blueprint = await pandocAufgabeArray(gutachten.blueprint);

  const xml = json2xml({ gutachten });

  return { xml };
};

export interface UseGutachten2XmlReturnType {
  xml?: string;
  attachments: DocumentAttachment[];
  loading: boolean;
  error?: string;
}
interface useGutachten2XmlProps {
  dbname: string;
  docid: string;
}

export const useGutachten2Xml = (props: useGutachten2XmlProps | undefined) => {
  const gutachtenView = useView("klausurtool/gutachtenAndEintraege", {
    db: props ? props.dbname : "_default",
    include_docs: true,
    startkey: [props ? props.docid : ""],
    endkey: [props ? props.docid : "", {}],
  });

  const [ret, setRet] = useState<UseGutachten2XmlReturnType>({ loading: true, attachments: [] });

  const forceUpdate = useCallback(() => {
    if (props) {
      setRet({ loading: true, attachments: [] });
      if (gutachtenView.loading === false && gutachtenView.state === "done") {
        gutachten2Xml(gutachtenView.rows)
          .then((res) => {
            setRet({ xml: res.xml, loading: false, attachments: [] });
          })
          .catch((err) => {
            setRet({ loading: false, error: err, attachments: [] });
            console.log(err);
          });
      }
    } else {
      setRet({ loading: false, attachments: [] });
    }
  }, [gutachtenView.loading, gutachtenView.rows, gutachtenView.state, props]);

  useEffect(() => {
    if (gutachtenView.loading) {
      setRet({ loading: true, attachments: [] });
    } else if (gutachtenView.error || !gutachtenView.rows) {
      setRet({ loading: false, error: gutachtenView.error as string, attachments: [] });
    } else {
      forceUpdate();
    }
  }, [gutachtenView.loading, gutachtenView.error, gutachtenView.rows, forceUpdate]);

  return ret;
};
