import { plainToClass } from "class-transformer";
import { usePouch } from "use-pouchdb";
import { objectToDateTime } from "../lib/DateTimeHelper";
import { BaseObject, useAllFromViewGeneric } from "./BaseObject";
import { useCallback } from "react";
import { toIndexableString } from "pouchdb-collate";
import { v4 as uuidv4 } from "uuid";

export interface GutachtenPunktEintrag {
  afb1: number;
  afb2: number;
  afb3: number;
}

export interface GutachtenPunkt {
  erwarteteLeistung: string;
  maxPunkte: GutachtenPunktEintrag;
  erreicht: GutachtenPunktEintrag;
}

export interface GutachtenAufgabe {
  aufgabenummer: number[];
  bewertung: string;
  punkte: GutachtenPunkt[];
}

export function createGutachtenAufgabe(aufgabeNummer?: number[]) {
  return {
    aufgabenummer: aufgabeNummer ? aufgabeNummer : [],
    bewertung: "",
    punkte: [],
  };
}

export class Gutachten extends BaseObject {
  readonly type = "gutachten";

  creationDate: string;
  lastEditDate: string;

  schule: string;
  schulform: string;

  /** z.B. Technik */
  fach: string;
  /** z.B. Informationstechnik */
  schwerpunkt: string;

  /** z.B. Abitur 2023 */
  art: string;
  /** z.B. Vorschlag A */
  vorschlag: string;

  blueprint: GutachtenAufgabe[];

  public constructor() {
    super();

    const now = new Date();
    this.creationDate = objectToDateTime(now);
    this.lastEditDate = objectToDateTime(now);

    this.name = "";

    this.schule = "";
    this.schulform = "";

    this.fach = "";
    this.schwerpunkt = "";

    this.art = "";
    this.vorschlag = "";

    this.blueprint = [];
  }

  static plainToClass(data: unknown) {
    if ((data as any).type === "gutachten") return plainToClass(Gutachten, data);
    return undefined;
  }

  public readableName() {
    if (this.art.length > 0 && this.fach.length > 0) return `${this.fach}: ${this.art}`;
    return this.creationDate;
  }
}

export class GutachtenEintrag {
  readonly type = "gutachteneintrag";

  _id?: string;
  _rev?: string;

  gutachtenId: string;

  creationDate: string;
  lastEditDate: string;

  nachname: string;
  vorname: string;
  prüfungsgruppe: string;

  aufgaben: GutachtenAufgabe[];

  abzügeFormRechtschreibung: number;
  abzügeFormRechtschreibungBemerkung: string;

  public constructor(gutachtenId: string = "", blueprint: GutachtenAufgabe[] = []) {
    const now = new Date();
    this.creationDate = objectToDateTime(now);
    this.lastEditDate = objectToDateTime(now);

    this.nachname = "";
    this.vorname = "";
    this.prüfungsgruppe = "";

    this.aufgaben = JSON.parse(JSON.stringify(blueprint));

    this.abzügeFormRechtschreibung = 0;
    this.abzügeFormRechtschreibungBemerkung = "";

    this.gutachtenId = gutachtenId;
  }

  static plainToClass(data: unknown) {
    if ((data as any).type === "gutachteneintrag") return plainToClass(GutachtenEintrag, data);
    return undefined;
  }
}

export function useSaveGutachten(dbname: string) {
  const pouchDb = usePouch(dbname);

  return useCallback(
    async (gutachten: Gutachten) => {
      try {
        const now = new Date();
        gutachten.lastEditDate = objectToDateTime(now);
        if (!gutachten._id) {
          gutachten._id = toIndexableString([
            "gutachten",
            gutachten.creationDate,
            uuidv4().toString(),
            // eslint-disable-next-line no-control-regex
          ]).replace(/\u0000/g, "\u0001");
        }
        const res = await pouchDb.put<any>(gutachten);
        gutachten._id = res.id;
        gutachten._rev = res.rev;
        return gutachten;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    [pouchDb]
  );
}

/** Muss in der selben Datenbank gespeichert werden, wie das dazugehörige Gutachtendokument! */
export function useSaveGutachtenEintrag(dbname: string) {
  const pouchDb = usePouch(dbname);

  return useCallback(
    async (gutachtenEintrag: GutachtenEintrag) => {
      try {
        if (!gutachtenEintrag.gutachtenId)
          throw new Error("Bitte dafür sorgen, dass der Eintrag einem Gutachten zugeordnet wurde!");
        const now = new Date();
        gutachtenEintrag.lastEditDate = objectToDateTime(now);
        if (!gutachtenEintrag._id) {
          gutachtenEintrag._id = toIndexableString([
            "gutachteneintrag",
            gutachtenEintrag.creationDate,
            uuidv4().toString(),
            // eslint-disable-next-line no-control-regex
          ]).replace(/\u0000/g, "\u0001");
        }
        const res = await pouchDb.put<any>(gutachtenEintrag);
        gutachtenEintrag._id = res.id;
        gutachtenEintrag._rev = res.rev;
        return gutachtenEintrag;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    [pouchDb]
  );
}

export function useAllGutachten(dbname: string, includeDocs: boolean) {
  const allData = useAllFromViewGeneric<Gutachten>({
    dbname,
    viewName: "klausurtool/allGutachten",
    includeDocs,
    plainToClassFct: Gutachten.plainToClass,
  });
  return allData;
}
