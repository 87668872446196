import { Box, DialogContent, SxProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useEditModeContext, useEditableViewContext } from "./EditableView";
import MyKlausurtoolDropZone, { DataRendererComponentProps } from "./MyKlausurtoolDropZone";

const RendererComponent: React.FC<DataRendererComponentProps> = ({ dataUrl }) => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  return (
    <>
      <Dialog maxWidth={"xl"} open={showFullScreen} onClose={() => setShowFullScreen(false)}>
        <DialogContent>
          <img
            src={dataUrl}
            alt="Bild"
            style={{ width: "100%", maxHeight: "100%" }}
            onClick={() => {
              setShowFullScreen(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          border: "1pt solid black",
          minHeight: "200px",
        }}
      >
        {dataUrl && (
          <img
            src={dataUrl}
            alt="Bild"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            onClick={() => {
              setShowFullScreen(true);
            }}
          />
        )}
      </Box>
    </>
  );
};

interface II {
  title?: string;
  fieldName: string;
  sx?: SxProps;
}

const MyImageDropZone: React.FC<II> = ({ title, fieldName, sx }: II) => {
  const { editModeEnabled } = useEditModeContext();
  return (
    <>
      <MyKlausurtoolDropZone
        sx={[
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        title={title}
        fieldName={fieldName}
        editable={editModeEnabled}
        acceptFiles={{ "image/png": [".png"], "image/jpg": [".jpg", ".jpeg"] }}
        DataRendererComponent={RendererComponent}
      />
    </>
  );
};

export default MyImageDropZone;
