import { Stack, FormGroup, FormControlLabel, Checkbox, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useAllFromViewGeneric } from "../../BaseObject";
import { DbDirViewFileTypes } from "../../DbDirView";
import DocumentSelectorDialog from "../DocumentSelectorDialog";
import XslFileSelector from "./XslFileSelector";
import { useDocument2XmlApi } from "../../UseDocument2Xml";
import { useDispatch, useSelector } from "react-redux";
import { selectXslEditorSettings, xslEditorActions } from "../../../redux/slices/xslEditorSlice";

export interface ViewState {
  xsl: boolean;
  xml: boolean;
  latex: boolean;
  pdf: boolean;
}

interface DocumentButtonHelperProps {
  dbname: string;
  docid: string;
  clicked: () => void;
}
const DocumentButtonHelper: React.FC<DocumentButtonHelperProps> = ({ dbname, docid, clicked }) => {
  const documentInfo = useAllFromViewGeneric({
    dbname: dbname,
    viewName: "klausurtool/allDocsName",
    includeDocs: false,
    key: docid,
  });

  return (
    <Button variant="outlined" onClick={clicked}>
      Document
      {documentInfo.loading === false &&
        documentInfo.state === "done" &&
        documentInfo.rows &&
        documentInfo.rows.length === 1 && (
          <>
            :<br />
            {`${documentInfo.rows[0].value.name}`}
          </>
        )}
    </Button>
  );
};

interface DocumentButtonProps {
  dbname?: string;
  docid?: string;
  clicked: () => void;
}
const DocumentButton: React.FC<DocumentButtonProps> = ({ dbname, docid, clicked }) => {
  if (dbname && docid) return <DocumentButtonHelper dbname={dbname} docid={docid} clicked={clicked} />;
  else return <Button onClick={clicked}>Document wählen</Button>;
};

interface ViewSelectionCheckListProps {
  defaultState: ViewState;
  stateChanged: (state: ViewState) => void;
  onSelectXslFile: (idx: number) => void;
}
const ViewSelectionCheckList: React.FC<ViewSelectionCheckListProps> = ({
  defaultState,
  stateChanged,
  onSelectXslFile,
}) => {
  const xslEditorSettings = useSelector(selectXslEditorSettings);
  const reduxDispatch = useDispatch();

  const { document, setDocument } = useDocument2XmlApi();

  const [state, setState] = useState<ViewState>(defaultState);

  useEffect(() => {
    setDocument(xslEditorSettings.lastKlausur);
  }, []);

  useEffect(() => {
    stateChanged(state);
  }, [state, stateChanged]);

  const [showSelectDocDlg, setShowSelectDocDlg] = useState(false);

  return (
    <Stack>
      <FormGroup row={true}>
        <FormControlLabel
          control={<Checkbox checked={state.xsl} onChange={(e) => setState({ ...state, xsl: e.target.checked })} />}
          label="XSL-Editor"
        />
        <XslFileSelector enabled={state.xsl} onSelect={onSelectXslFile} />
        <FormControlLabel
          control={<Checkbox checked={state.xml} onChange={(e) => setState({ ...state, xml: e.target.checked })} />}
          label="XML"
        />
        <DocumentButton {...document} clicked={() => setShowSelectDocDlg(true)} />
        <DocumentSelectorDialog
          open={showSelectDocDlg}
          handleOpen={(dbname, docid, type) => {
            setDocument({ dbname, docid, type });
            reduxDispatch(xslEditorActions.setLastKlausur({ dbname, docid, type }));
            setShowSelectDocDlg(false);
          }}
          handleCancel={() => setShowSelectDocDlg(false)}
          showFileTypes={DbDirViewFileTypes.KLAUSUREN | DbDirViewFileTypes.GUTACHTEN}
        />
        <FormControlLabel
          control={<Checkbox checked={state.latex} onChange={(e) => setState({ ...state, latex: e.target.checked })} />}
          label="XSL-transformiertes LaTeX"
        />
        <FormControlLabel
          control={<Checkbox checked={state.pdf} onChange={(e) => setState({ ...state, pdf: e.target.checked })} />}
          label="PDF-View"
        />
      </FormGroup>
    </Stack>
  );
};

export default ViewSelectionCheckList;
