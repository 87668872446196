import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../hooks";

interface TemplateEntryList {
  /** key: ID des Documents dem die Templates zugeordnet werden, values: Liste mit Templates */
  [docid: string]: { templateDbName: string; templateDocId: string }[];
}

interface DbEntryList {
  [dbname: string]: TemplateEntryList;
}

interface IState {
  /** key: Name der Datenbank des Klausur-Dokuments, */
  templateListByDbNames: DbEntryList;
}

const initialState: IState = { templateListByDbNames: {} };

export const klausurEditorSlice = createSlice({
  name: "settings",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTemplates: (
      state,
      action: PayloadAction<{
        dbname: string;
        docid: string;
        templates: { templateDbName: string; templateDocId: string }[];
      }>
    ) => {
      if (state.templateListByDbNames[action.payload.dbname] === undefined) {
        state.templateListByDbNames[action.payload.dbname] = {};
      }
      const tentry = state.templateListByDbNames[action.payload.dbname];
      tentry[action.payload.docid] = action.payload.templates;
      return state;
    },
    addTemplate: (
      state,
      action: PayloadAction<{ dbname: string; docid: string; templateDbName: string; templateDocId: string }>
    ) => {
      if (state.templateListByDbNames[action.payload.dbname] === undefined) {
        state.templateListByDbNames[action.payload.dbname] = {};
      }
      const tentry = state.templateListByDbNames[action.payload.dbname];
      if (tentry[action.payload.docid] === undefined) {
        tentry[action.payload.docid] = [];
      }
      const a = tentry[action.payload.docid];
      if (a) {
        const newEntry = { templateDbName: action.payload.templateDbName, templateDocId: action.payload.templateDocId };
        let exists = false;
        for (let i = 0; i < a.length; ++i) {
          if (a[i] === newEntry) {
            exists = true;
            break;
          }
        }
        if (!exists) a.push(newEntry);
      }
      return state;
    },
    removeTemplate: (
      state,
      action: PayloadAction<{ dbname: string; docid: string; templateDbName: string; templateDocId: string }>
    ) => {
      const dentry = state.templateListByDbNames[action.payload.dbname];
      if (dentry !== undefined && dentry[action.payload.docid] !== undefined) {
        const a = dentry[action.payload.docid];
        if (a) {
          const deleteEntry = {
            templateDbName: action.payload.templateDbName,
            templateDocId: action.payload.templateDocId,
          };
          for (let i = 0; i < a.length; ++i) {
            if (a[i] === deleteEntry) {
              a.slice(i, 1);
              break;
            }
          }
        }
      }
      return state;
    },
  },
});

// actions, use: appDispatch(setCredentials(...));
export const klausurEditorActions = klausurEditorSlice.actions;
export const klausurEditorReducer = klausurEditorSlice.reducer;

// Selectors (use with useSelector())
export const klausurEditorSettings = (state: RootState) => state.klausurEditorSettings;
