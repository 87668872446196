import { ReactNode, useCallback, useEffect, useState } from "react";

import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";

import { FieldValues, UseFieldArrayReturn } from "react-hook-form";

import { KlausurAufgabeReference, Klausur, useSaveKlausur } from "../Klausur";
import KlausurMetaInfoEditor from "./KlausurMetaInfoEditor";
import KlausurStructureView, {
  AufgabeVisibilityWrapper,
  AufgabenArrayContextComponent,
  useAufgabenArrayContext,
} from "./KlausurStructureView";
import AufgabeEditor from "./AufgabeEditor";
import { EditableView, useEditModeContext, useEditableViewContext } from "../../lib/EditableView";
import { useGenericDocument } from "../BaseObject";
import EditableTextField from "../../lib/EditableTextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useDbList } from "../../lib/couchdb-mgr/CouchDbContext";
import { useSetAttachment } from "../../lib/AttachmentManager";
import {
  LatexViewWrapper,
  useKlausurVorschauContext,
  VorschauContextComponent,
  VorschauView,
} from "./KlausurEditorVorschauContext";
import React from "react";
import { MainViewHandleDirtyChecker } from "../MainViewIsDirtyContext";

interface AufgabeReferenceEditorProps {
  dbname: string;
  docid: string;
  index: number;
  aufgabenArray: UseFieldArrayReturn<FieldValues, "aufgaben", "id">;
  sx?: object;
}
const AufgabeReferenceEditor: React.FC<AufgabeReferenceEditorProps> = React.memo(({ index, aufgabenArray, sx }) => {
  const dbs = useDbList();
  const { editModeEnabled } = useEditModeContext();
  const setAttachment = useSetAttachment();
  const referenceToCopy = useCallback(
    async (index: number) => {
      const dbname = (aufgabenArray.fields[index] as any).dbname;
      const docid = (aufgabenArray.fields[index] as any).docid;

      const db = dbs?.byName(dbname);
      if (db) {
        const a = await db.db.get(docid, { attachments: true, binary: true });
        aufgabenArray.update(index, { type: "aufgabe", name: (a as any).name, definition: (a as any).definition });

        // Attachments nicht vergessen...
        const keys = Object.keys((a as any)._attachments);
        for (let i = 0; i < keys.length; ++i) {
          const att = (a as any)._attachments[keys[i]] as any;
          setAttachment(keys[i], att.data as Blob, att.content_type);
        }
      }
    },
    [aufgabenArray, dbs, setAttachment]
  );

  return (
    <Stack spacing={2} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Button variant="outlined">In Editor öffnen</Button>
      <Button disabled={!editModeEnabled} variant="outlined" onClick={() => referenceToCopy(index)}>
        In Kopie umwandeln
      </Button>
    </Stack>
  );
});

interface SmallGridIfVorschauVisibleProps {
  children: ReactNode;
}
const SmallGridIfVorschauVisible: React.FC<SmallGridIfVorschauVisibleProps> = ({ children }) => {
  const { latexViewerProps } = useKlausurVorschauContext();
  return (
    <Grid item xs={latexViewerProps ? 5 : 9}>
      {children}
    </Grid>
  );
};

interface VorschauWrapperProps {
  children: ReactNode;
}
const VorschauWrapper: React.FC<VorschauWrapperProps> = ({ children }) => {
  const { latexViewerProps } = useKlausurVorschauContext();
  return <>{latexViewerProps && children}</>;
};

const AufgabeArrayView: React.FC = () => {
  const { aufgabenArray, whichAufgabe } = useAufgabenArrayContext();
  return (
    <>
      {aufgabenArray.fields.map((aufgabe, index) => {
        if ((aufgabe as any).type === "klausuraufgabe") {
          return (
            <AufgabeEditor
              key={aufgabe.id}
              path={`aufgaben.${index}`}
              showMetaInfoEditor={false}
              aufgabeNummer={[whichAufgabe + 1]}
              sx={{ visibility: whichAufgabe === index ? undefined : "collapse" }}
            />
          );
        } else if ((aufgabe as any).type === "reference") {
          return (
            <AufgabeReferenceEditor
              key={aufgabe.id}
              aufgabenArray={aufgabenArray}
              index={index}
              dbname={(aufgabenArray.fields[index] as unknown as KlausurAufgabeReference).dbname}
              docid={(aufgabenArray.fields[index] as unknown as KlausurAufgabeReference).docid}
              sx={{ visibility: whichAufgabe === index ? undefined : "collapse" }}
            />
          );
        } else throw new Error(`Unbekannter Typ ${(aufgabe as any).type}`);
      })}
    </>
  );
};

const CombinationOfKlausurEditorAndStructure: React.FC<{
  dbname: string;
  klausur: Klausur;
}> = ({ dbname, klausur }) => {
  const saveKlausur = useSaveKlausur(dbname);

  const saveFunction = useCallback(
    async (klausurNeuRaw: any) => {
      //console.log(klausurNeuRaw);
      if (klausurNeuRaw) await saveKlausur(klausurNeuRaw);
      else throw new Error("INTERNAL ERROR!");
    },
    [saveKlausur]
  );

  return (
    <EditableView value={klausur} dbname={dbname} saveFunction={saveFunction}>
      <MainViewHandleDirtyChecker />
      <AufgabenArrayContextComponent>
        <VorschauContextComponent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box sx={{ position: "fixed", width: "24%" }}>
                <KlausurStructureView />
                <VorschauView dbname={dbname} docid={klausur._id} />
              </Box>
            </Grid>

            <SmallGridIfVorschauVisible>
              <Stack spacing={2}>
                <AufgabeVisibilityWrapper currentIndex={-1}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Name des Documents:</Typography>
                      <EditableTextField label="Name des Documents" fieldName={`name`} />
                    </CardContent>
                  </Card>

                  <KlausurMetaInfoEditor />
                </AufgabeVisibilityWrapper>
                <AufgabeArrayView />
              </Stack>
            </SmallGridIfVorschauVisible>
            <VorschauWrapper>
              <Grid item xs={4}>
                <Box sx={{ position: "fixed", width: "30%" }}>
                  <LatexViewWrapper />
                </Box>
              </Grid>
            </VorschauWrapper>
          </Grid>
        </VorschauContextComponent>
      </AufgabenArrayContextComponent>
    </EditableView>
  );
};

interface KlausurEditorProps {
  dbname: string;
  docid: string;
  openEditorFct: (dbname: string, docid: string) => void;
  handleIsDirty?: (doc: any, isDirty: boolean) => void;
  handleIsDirtyDoc?: any;
}

const KlausurEditor: React.FC<KlausurEditorProps> = React.memo(({ dbname, docid, openEditorFct }) => {
  const klausur = useGenericDocument(dbname, docid, Klausur.plainToClass);
  return (
    <>
      {!klausur.doc && <CircularProgress />}
      {klausur.doc && <CombinationOfKlausurEditorAndStructure klausur={klausur.doc} dbname={dbname} />}
    </>
  );
});

export default KlausurEditor;
