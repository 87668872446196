import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ContentArrayEditor from "./ContentArrayEditor";
import { createPunkt } from "../Aufgabe";
import EditableTextField from "../../lib/EditableTextField";
import { useEditModeContext } from "../../lib/EditableView";

interface Props2 {
  index: number;
  path: string;
  removeFct: (index: number) => void;
  moveFct: (from: number, to: number) => void;
  listLength: number;
  showZeit?: boolean;
  textFieldName?: string;
}

export const PunktView: React.FC<Props2> = React.memo(
  ({ index, path, removeFct, moveFct, listLength, showZeit = true }) => {
    const { editModeEnabled } = useEditModeContext();
    return (
      <>
        <Grid item xs={showZeit ? 8 : 9}>
          <Stack direction="row" sx={{ width: "100%" }}>
            {editModeEnabled && (
              <Stack direction="row">
                <IconButton
                  onClick={() => {
                    if (index > 0) moveFct(index, index - 1);
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    if (index < listLength - 1) moveFct(index, index + 1);
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton onClick={() => removeFct(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            )}
            <EditableTextField placeholder="Kurzbeschreibung" fieldName={`${path}.text`} required multiline />
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <EditableTextField fieldName={`${path}.afb1`} textAlign="center" type="number" required />
        </Grid>
        <Grid item xs={1}>
          <EditableTextField fieldName={`${path}.afb2`} textAlign="center" type="number" required />
        </Grid>
        <Grid item xs={1}>
          <EditableTextField fieldName={`${path}.afb3`} textAlign="center" type="number" required />
        </Grid>
        {showZeit && (
          <Grid item xs={1}>
            <EditableTextField fieldName={`${path}.zeit`} textAlign="center" type="number" required />
          </Grid>
        )}
      </>
    );
  }
);

interface PunkteViewProps {
  path: string;
  showZeit?: boolean;
  specialCreatePunktFunction?: () => any;
}
export const PunkteView: React.FC<PunkteViewProps> = React.memo(
  ({ path, showZeit = true, specialCreatePunktFunction }) => {
    const { editModeEnabled } = useEditModeContext();
    const { control } = useFormContext();
    const punkteArray = useFieldArray({
      name: path,
      control,
    });
    return (
      <Grid container spacing={1}>
        <Grid item xs={showZeit ? 8 : 9}>
          <Typography variant="subtitle2">Punkteverteilung:</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">AFB1</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">AFB2</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">AFB3</Typography>
        </Grid>
        {showZeit && (
          <Grid item xs={1}>
            <Typography variant="subtitle2">Zeit</Typography>
          </Grid>
        )}
        {punkteArray.fields.map((field, index) => {
          return (
            <PunktView
              key={field.id}
              index={index}
              path={`${path}.${index}`}
              removeFct={punkteArray.remove}
              moveFct={punkteArray.move}
              listLength={punkteArray.fields.length}
              showZeit={showZeit}
            />
          );
        })}
        {editModeEnabled && (
          <Grid item xs={12}>
            <Button
              sx={{ width: "100%" }}
              variant="outlined"
              onClick={() =>
                punkteArray.append(specialCreatePunktFunction ? specialCreatePunktFunction() : createPunkt())
              }
            >
              Punkt hinzufügen
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
);

const LoesungEditor: React.FC<{ path: string }> = React.memo(({ path }) => {
  return (
    <>
      <Typography variant="subtitle2">Erwartete Lösung:</Typography>
      <Stack gap={1}>
        <ContentArrayEditor path={`${path}.contentArray`} />
        <PunkteView path={`${path}.punkte`} />
      </Stack>
    </>
  );
});

export default LoesungEditor;
