import { Box, Tabs, Tab } from "@mui/material";
import React, { ReactNode, createContext, useContext, useMemo, useState } from "react";

interface TabContextType {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}
const TabContext = createContext<TabContextType>({
  value: -1,
  setValue: (() => {}) as React.Dispatch<React.SetStateAction<number>>,
  handleChange: (() => {}) as (event: React.SyntheticEvent, newValue: number) => void,
});

interface TabContextComponentType {
  children: ReactNode;
}
export const TabContextComponent: React.FC<TabContextComponentType> = ({ children }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const memoizedValue = useMemo(() => {
    return { value, setValue, handleChange };
  }, [value]);

  return <TabContext.Provider value={memoizedValue}>{children}</TabContext.Provider>;
};
export const useTabContext = () => {
  return useContext(TabContext);
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

interface TabContentProps {
  label: string;
  wrapLabel?: boolean;
  icon?: ReactNode;
  iconPosition?: "bottom" | "end" | "start" | "top";
  children: ReactNode;
}

export const TabContent: React.FC<TabContentProps> = ({ label, wrapLabel, icon, iconPosition, children }) => {
  return <>{children}</>;
};

interface TabViewProps {
  vertical?: boolean;
  children: ReactNode[];
}
const TabView: React.FC<TabViewProps> = React.memo(({ vertical = false, children }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            orientation={vertical ? "vertical" : "horizontal"}
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
          >
            {children &&
              React.Children.map(children, (tab: any) => {
                //console.log(tab, tab.type, TabContent);
                if (!tab.props.label) throw Error("Please use TabContent as Wrapper!");
                return (
                  <Tab
                    label={tab.props.label}
                    wrapped={tab.props.wrapLabel ? tab.props.wrapLabel : false}
                    icon={tab.props.icon ?? undefined}
                    iconPosition={tab.props.iconPosition ?? undefined}
                  />
                );
              })}
          </Tabs>
        </Box>
        {children &&
          React.Children.map(children, (child: any, index) => {
            //console.log(child);
            return (
              <TabPanel key={index} value={value} index={index}>
                {child}
              </TabPanel>
            );
          })}
      </Box>
    </>
  );
});

export default TabView;
