import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useDbList } from "./CouchDbContext";
import { Database } from "./HelperClasses";
import { useEffect } from "react";

interface DatabaseSelectProps {
  defaultDb?: Database;
  handleChange: (db: Database | undefined) => void;
}
const DatabaseSelect: React.FC<DatabaseSelectProps> = ({ defaultDb, handleChange }) => {
  const dbs = useDbList();

  useEffect(() => {
    if (defaultDb) handleChange(defaultDb);
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Datenbank auswählen</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={defaultDb ? defaultDb.name : ""}
        label="Datenbank auswählen"
        onChange={(event: SelectChangeEvent) => {
          handleChange(dbs ? dbs.byName(event.target.value as string) : undefined);
        }}
      >
        {dbs &&
          dbs?.dbs.map((db) => {
            return (
              <MenuItem key={db.name} value={db.name}>
                {db.getReadableName()}
              </MenuItem>
            );
          })}
        <MenuItem value={""}></MenuItem>
      </Select>
    </FormControl>
  );
};

export default DatabaseSelect;
