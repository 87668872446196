import { Paper, Grid, Typography } from "@mui/material";
import { GutachtenEintrag, GutachtenPunkt } from "../../Gutachten";
import { formatAufgabeNummer } from "../helper";
import { useFieldArray } from "react-hook-form";
import EditableTextField from "../../../lib/EditableTextField";
import React from "react";

interface GutachtenEintragPunktViewProps {
  path: string;
}
const GutachtenEintragPunktView: React.FC<GutachtenEintragPunktViewProps> = ({ path }) => {
  const punkte = useFieldArray({ name: path });
  return (
    <>
      {punkte.fields.map((punkt, index) => {
        const p = punkt as unknown as GutachtenPunkt;
        return (
          <React.Fragment key={punkt.id}>
            <Grid item xs={8}>
              <Typography>{p.erwarteteLeistung}</Typography>
            </Grid>
            <Grid item xs={1}>
              {p.maxPunkte.afb1 > 0 && (
                <EditableTextField
                  fullWidth={false}
                  fieldName={`${path}.${index}.erreicht.afb1`}
                  textAlign="center"
                  type="number"
                  required
                  suffix={`/${p.maxPunkte.afb1.toString()}`}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              {p.maxPunkte.afb2 > 0 && (
                <EditableTextField
                  fullWidth={false}
                  fieldName={`${path}.${index}.erreicht.afb2`}
                  textAlign="center"
                  type="number"
                  required
                  suffix={`/${p.maxPunkte.afb2.toString()}`}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              {p.maxPunkte.afb3 > 0 && (
                <EditableTextField
                  fullWidth={false}
                  fieldName={`${path}.${index}.erreicht.afb3`}
                  textAlign="center"
                  type="number"
                  required
                  suffix={`/${p.maxPunkte.afb3.toString()}`}
                />
              )}
            </Grid>
            <Grid item xs={1} />
          </React.Fragment>
        );
      })}
    </>
  );
};

interface GutachtenEintragAufgabeViewProps {
  path: string;
  aufgabenummer: number[];
}
const GutachtenEintragAufgabeView: React.FC<GutachtenEintragAufgabeViewProps> = ({ path, aufgabenummer }) => {
  const punkte = useFieldArray({ name: `${path}.punkte` });
  const hasPunkte = punkte.fields.length > 0;
  return (
    <>
      {hasPunkte && (
        <>
          <Grid item xs={1}>
            <Typography variant="h6">{formatAufgabeNummer(aufgabenummer)}</Typography>
          </Grid>
          {<GutachtenEintragPunktView path={`${path}.punkte`} />}
          <Grid item xs={11}>
            <EditableTextField fieldName={`${path}.bewertung`} multiline={true} variant="outlined" label="Bewertung" />
          </Grid>
        </>
      )}
    </>
  );
};

interface GutachtenEintragPunkteViewProps {
  eintrag: GutachtenEintrag;
}
export const GutachtenEintragPunkteView: React.FC<GutachtenEintragPunkteViewProps> = ({ eintrag }) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography variant="h6">Bewertung:</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">AFB1</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">AFB2</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">AFB3</Typography>
        </Grid>
        {eintrag.aufgaben.map((aufgabe, index) => {
          return (
            <GutachtenEintragAufgabeView
              key={formatAufgabeNummer(aufgabe.aufgabenummer)}
              path={`aufgaben.${index}`}
              aufgabenummer={aufgabe.aufgabenummer}
            />
          );
        })}
      </Grid>
    </Paper>
  );
};
