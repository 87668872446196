import { useState } from "react";

import { Card, CardContent, TextField, Typography } from "@mui/material";

import { useFormContext } from "react-hook-form";

import ContentArrayEditor from "./ContentArrayEditor";
import { useEditModeContext, useEditableViewContext } from "../../lib/EditableView";

interface AufgabeEditorProps {
  prefix: string;
  sx?: object;
}

/**
 * If db is undefined -> this editor is not standalone
 *
 * @param param0
 * @returns
 */
const AufgabeMetaInfoEditor: React.FC<AufgabeEditorProps> = ({ prefix, sx }) => {
  const form = useFormContext();

  const { editModeEnabled } = useEditModeContext();

  const [tags, setTags] = useState(form.getValues(`${prefix}metainfo.tags`).join(", "));
  const handleTagsChange = (e: any) => {
    const tagsString = e.target.value;
    setTags(tagsString);
    let tags = tagsString.split(/[,]/);
    tags = tags.filter((tag: string) => tag.length > 0).map((tag: string) => tag.trim());
    form.setValue(`${prefix}metainfo.tags`, tags, { shouldDirty: true });
  };

  return (
    <>
      <Card sx={[{ minWidth: "100%" }, ...(Array.isArray(sx) ? sx : [sx])]}>
        <CardContent>
          <Typography variant="h6">Meta-Informationen zur Aufgabe:</Typography>
          <TextField
            disabled={!editModeEnabled}
            label="Tags"
            variant="standard"
            value={tags}
            onChange={handleTagsChange}
            fullWidth
          />
          <Typography variant="subtitle1">Beschreibung:</Typography>
          <ContentArrayEditor path={`${prefix}metainfo.description.`} />
        </CardContent>
      </Card>
    </>
  );
};

export default AufgabeMetaInfoEditor;
