import React from "react";
import { useController, useFormContext } from "react-hook-form";
//import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import InlineEditor from "@ckeditor/ckeditor5-build-inline/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const WysiwigEditorCK5 /*: React.FC<{ fieldName: string }>*/ = ({ disabled, fieldName }) => {
  const { control, getValues, register } = useFormContext();

  const {
    field: { onChange },
  } = useController({
    control,
    name: fieldName,
  });

  register(fieldName);
  const timeoutRef = React.useRef();
  const handleChange = React.useCallback(
    (event, editor) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        onChange(editor.getData());
        timeoutRef.current = undefined;
      }, 1000);
    },
    [onChange]
  );

  /**
   *   IMPORTANT:
   *   ==================
   *
   *   Copy mathjax-folder to renderer/public!
   */
  return <CKEditor disabled={disabled} editor={InlineEditor} data={getValues(fieldName)} onChange={handleChange} />;
};

export default WysiwigEditorCK5;
