import { CircularProgress, Fab, Stack } from "@mui/material";
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  FormProvider,
  SubmitHandler,
  UseFormGetValues,
  UseFormReturn,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import SimpleDialog from "./SimpleDialog";
import { useUsername } from "./couchdb-mgr/CouchDbContext";
import useLock from "./couchdb-mgr/useLock";
import { AttachmentManagerContext } from "./AttachmentManager";
import React from "react";

interface UseEditableViewResult {
  form: UseFormReturn<any, any>;
}

function useEditableView({ value, dbname }: { value: any; dbname: string }): UseEditableViewResult {
  if (!value) throw new Error("Please define default values!!");

  const form = useForm({
    defaultValues: JSON.parse(JSON.stringify(value)),
  });

  // Wenn man die Variable dirtyFields nicht explizit abfragt, werden die
  // dirty fields von react-hook-form nicht getrackt....
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dirtyFields = form.formState.dirtyFields;

  // notify-changes effect
  useEffect(() => {
    console.log("receiving new data... resetting form!");

    const currentValue = form.getValues();

    if (
      Object.keys(value).includes("_id") ||
      Object.keys(value).includes("_rev") ||
      Object.keys(currentValue).includes("_id") ||
      Object.keys(currentValue).includes("_rev")
    ) {
      // these seem to be CouchDB Objects... they hav _id, _rev and _attachments
      if (currentValue["_id"] !== value["_id"] || currentValue["_rev"] !== value["_rev"]) {
        // there has been an update
        console.log("_id or _rev are updated... resetting form...");
        form.reset(JSON.parse(JSON.stringify(value)));
      } else {
        console.log("Skipping update because _id and _rev are not changed");
      }
    } else {
      // they are not CouchDB-Values
      console.log("Not CouchDB-Values... doing deep-compare...");
      if (JSON.stringify(value) !== JSON.stringify(currentValue)) {
        console.log("Values are different... resetting form.");
        form.reset(JSON.parse(JSON.stringify(value)));
      } else {
        console.log("Values are same... skipping reset.");
      }
    }
  }, [form, value]);

  /*const onError: SubmitErrorHandler<any> = useCallback(
    (errors) => {
      console.log(errors);
      cancel();
    },
    [cancel]
  );*/

  const memoizedValue = useMemo(() => {
    return {
      form,
      priv: { dbname, document: value },
    };
  }, [dbname, form, value]);

  return memoizedValue;
}

interface EditableViewContextType {
  isDirty: boolean;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
}
const EditableViewContext = createContext<EditableViewContextType>({
  isDirty: false,

  getValues: (() => {}) as UseFormGetValues<any>,
  setValue: (() => {}) as UseFormSetValue<any>,
});

interface EditModeDataContextType {
  editModeEnabled: boolean;
}
const EditModeDataContext = createContext<EditModeDataContextType>({
  editModeEnabled: false,
} as EditModeDataContextType);
interface EditModeAPIContextType {
  setEditModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  cancel: () => void;
}
const EditModeAPIContext = createContext<EditModeAPIContextType>({} as EditModeAPIContextType);
interface EditModeContextComponentProps {
  form: UseFormReturn<any, any>;
  value: any;
  dbname: string;
  children?: ReactNode;
}
const EditModeContextComponent: React.FC<EditModeContextComponentProps> = ({ form, value, dbname, children }) => {
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const _id = form.watch("_id");
  const lock = useLock(dbname, _id);

  const cancel = useCallback(() => {
    form.reset(JSON.parse(JSON.stringify(value)));
    lock.unlock();
  }, [form, value, lock]);

  const memoizedAPI = useMemo(() => {
    return { setEditModeEnabled, cancel };
  }, [cancel]);
  const memoizedData = useMemo(() => {
    return { editModeEnabled };
  }, [editModeEnabled]);

  return (
    <EditModeAPIContext.Provider value={memoizedAPI}>
      <EditModeDataContext.Provider value={memoizedData}>{children && children}</EditModeDataContext.Provider>
    </EditModeAPIContext.Provider>
  );
};

export const useEditModeContext = () => useContext(EditModeDataContext);
const useEditModeAPIContext = () => useContext(EditModeAPIContext);

interface EditModeButtonsProps {
  form: UseFormReturn<any, any>;
  dbname: string;
  isDirty: boolean;
  setShowVerwerfenDialog: (show: boolean) => void;
  saveFunction: (data: any) => Promise<void>;
}
const EditModeButtons: React.FC<EditModeButtonsProps> = ({
  form,
  dbname,
  isDirty,
  setShowVerwerfenDialog,
  saveFunction,
}) => {
  const { editModeEnabled } = useEditModeContext();
  const { setEditModeEnabled, cancel } = useEditModeAPIContext();
  const [editModeLoading, setEditModeLoading] = useState(false);

  // locking
  const username = useUsername();

  const _id = form.watch("_id");
  const lock = useLock(dbname, _id);

  const [canEnableEditMode, setCanEnableEditMode] = useState((form.getValues() as any)._id ? false : true);

  const enableEditMode = useCallback(() => {
    if (!editModeEnabled) {
      if (_id && username) {
        lock.lock();
      } else {
        setEditModeEnabled(true);
      }
    }
  }, [editModeEnabled, _id, username, lock, setEditModeEnabled]);

  // check if edit mode can be enabled (or if there is a lock!)
  useEffect(() => {
    //console.log("run lock effect", _id, lock.islocked(_id));
    if (_id) {
      const lockedBy = lock.lockState.lockUser;
      if (lockedBy === undefined) {
        setCanEnableEditMode(true);
        setEditModeEnabled(false);
      } else if (lockedBy === username) {
        setEditModeEnabled(true);
        setCanEnableEditMode(false);
      } else setCanEnableEditMode(false);
    } else setCanEnableEditMode(true);
    setEditModeLoading(lock.loading);
  }, [_id, lock, username, dbname, setEditModeEnabled]);

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      await saveFunction(data);
      lock.unlock();
    },
    [lock, saveFunction]
  );

  const accept = useCallback(() => {
    const data: any = form.getValues();
    onSubmit(data);
  }, [form, onSubmit]);

  return (
    <>
      {editModeLoading && (
        <CircularProgress sx={{ margin: 0, top: "auto", right: 20, bottom: 20, left: "auto", position: "fixed" }} />
      )}
      {!editModeLoading && !editModeEnabled && (
        <Fab
          color="primary"
          sx={{ zIndex: 10, margin: 0, top: "auto", right: 20, bottom: 20, left: "auto", position: "fixed" }}
          onClick={() => enableEditMode()}
          disabled={!canEnableEditMode}
        >
          <EditIcon />
        </Fab>
      )}
      {!editModeLoading && editModeEnabled && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ zIndex: 10, margin: 0, top: "auto", right: 20, bottom: 20, left: "auto", position: "fixed" }}
        >
          <Fab
            color="error"
            onClick={() => {
              isDirty ? setShowVerwerfenDialog(true) : cancel();
            }}
          >
            <CancelIcon />
          </Fab>
          <Fab color="success" onClick={accept}>
            <SaveIcon />
          </Fab>
        </Stack>
      )}
    </>
  );
};

interface ÄnderungenVerwerdenDialogProps {
  showVerwerfenDialog: boolean;
  setShowVerwerfenDialog: (show: boolean) => void;
}
const ÄnderungenVerwerdenDialog: React.FC<ÄnderungenVerwerdenDialogProps> = ({
  showVerwerfenDialog,
  setShowVerwerfenDialog,
}) => {
  const { cancel } = useEditModeAPIContext();
  return (
    <SimpleDialog
      open={showVerwerfenDialog}
      title="Änderungen verwerfen?"
      dialogText="Sie haben das aktuelle Document verändert. Wollen Sie die Änderungen wirklich verwerfen?"
      handleCancel={() => setShowVerwerfenDialog(false)}
      handleOK={() => {
        cancel();
        setShowVerwerfenDialog(false);
      }}
      abbruchText="Weiter bearbeiten!"
      okText="ÄNDERUNGEN WIRKLICH VERWERFEN!"
    />
  );
};

interface EditableViewProps {
  value: any;
  dbname: string;
  saveFunction: (data: any) => Promise<void>;
  children: ReactNode;
}

export const EditableView: React.FC<EditableViewProps> = React.memo<EditableViewProps>(
  ({ value, dbname, saveFunction, children }) => {
    const { form } = useEditableView({ value, dbname });

    const [showVerwerfenDialog, setShowVerwerfenDialog] = useState(false);

    const memoizedValue: EditableViewContextType = useMemo(() => {
      return {
        isDirty: form.formState.isDirty,
        getValues: form.getValues,
        setValue: form.setValue,
      };
    }, [form.formState.isDirty, form.getValues, form.setValue]);

    const content = useMemo(
      () => (
        <FormProvider {...form}>
          <form style={{ height: "100%" }}>
            <EditModeButtons
              form={form}
              dbname={dbname}
              isDirty={form.formState.isDirty}
              setShowVerwerfenDialog={setShowVerwerfenDialog}
              saveFunction={saveFunction}
            />
            <ÄnderungenVerwerdenDialog
              showVerwerfenDialog={showVerwerfenDialog}
              setShowVerwerfenDialog={setShowVerwerfenDialog}
            />
            {children}
          </form>
        </FormProvider>
      ),
      [children, dbname, form, saveFunction, showVerwerfenDialog]
    );

    return (
      <EditableViewContext.Provider value={memoizedValue}>
        <AttachmentManagerContext dbname={dbname} docid={value._id}>
          <EditModeContextComponent form={form} dbname={dbname} value={value}>
            {content}
          </EditModeContextComponent>
        </AttachmentManagerContext>
      </EditableViewContext.Provider>
    );
  }
);

export const useEditableViewContext = () => {
  return useContext(EditableViewContext);
};

interface EditModeEnableRenderProxyProps {
  children: ReactNode;
}
export const EditModeEnableRenderProxy: React.FC<EditModeEnableRenderProxyProps> = ({ children }) => {
  const { editModeEnabled } = useEditModeContext();
  return <>{editModeEnabled && children}</>;
};
