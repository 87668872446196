import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import React, { useState } from "react";
import { Aufgabe, useAllAufgaben } from "./Aufgabe";
import { DocumentTypes, useCopyDocument, useDeleteDocument } from "./BaseObject";
import { AufgabeIcon, GutachtenIcon, KlausurIcon, XslTemplateIcon } from "./Icons";
import { Klausur, useAllKlausuren } from "./Klausur";
import { useDocName } from "./UseMetaInfo";
import { useAllTemplates, XSLTemplate } from "./XSLTemplate";
import { Gutachten, useAllGutachten } from "./Gutachten";
import ContextMenu from "../lib/ContextMenu";
import SimpleDialog from "../lib/SimpleDialog";
import { DocumentAddress, useDbList } from "../lib/couchdb-mgr/CouchDbContext";
import DatabaseSelect from "../lib/couchdb-mgr/DatabaseSelect";
import { Database } from "../lib/couchdb-mgr/HelperClasses";
import { download } from "../lib/download";

interface DuplicateDialogProps {
  source?: DocumentAddress;
  message: string;
  handleClose: () => void;
}
const DuplicateDialog: React.FC<DuplicateDialogProps> = ({ source, message, handleClose }) => {
  const dbs = useDbList();
  const [targetDb, setTargetDb] = useState<Database | undefined>();

  const copy = useCopyDocument();

  return (
    <Dialog open={source !== undefined}>
      <DialogTitle>Dokument kopieren</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText>{message}</DialogContentText>
          {source && <DatabaseSelect handleChange={setTargetDb} defaultDb={dbs.byName(source.dbname)} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbruch</Button>
        <Button
          onClick={async () => {
            if (source && targetDb) await copy(source, targetDb?.name);
            handleClose();
          }}
          autoFocus
        >
          Kopieren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DbEntryContextMenuProps {
  dbname: string;
  curDocId: string;
  curDocRev: string;
  curDocName: string;
  setSource: (source: DocumentAddress | undefined) => void;
  setCopyMessage: (msg: string) => void;
  setReallyDeleteTitle: (msg: string) => void;
  setReallyDeleteDocId: ({ _id, _rev }: { _id: string; _rev: string }) => void;
  filetype: string;
  fileTypeAnrede: string;
}
const DbEntryContextMenu: React.FC<DbEntryContextMenuProps> = ({
  dbname,
  curDocId,
  curDocRev,
  curDocName,
  setSource,
  setCopyMessage,
  setReallyDeleteTitle,
  setReallyDeleteDocId,
  filetype,
  fileTypeAnrede,
}) => {
  const dbs = useDbList();

  return (
    <ContextMenu
      items={[
        {
          title: "Download",
          handleClick: async () => {
            const db = dbs.byName(dbname);
            if (!db) throw new Error(`Konnte Datenbank ${dbname} nicht finden!`);
            const doc = await db.db.get(curDocId, { attachments: true, binary: false });

            const blob = new Blob([JSON.stringify(doc, null, " ")], {
              type: "application/json", // or whatever your Content-Type is
            });

            download(`${curDocName}.${filetype}`, blob);
          },
          icon: <FileDownloadIcon />,
        },
        {
          title: "Duplicate",
          handleClick: () => {
            setCopyMessage(`In welche Datenbank möchten Sie ${fileTypeAnrede} \"${curDocName}\" kopieren?`);
            setSource({ dbname: dbname, docid: curDocId });
          },
          icon: <FileCopyIcon />,
        },
        {
          title: "Löschen",
          handleClick: () => {
            setReallyDeleteTitle(`Wollen Sie ${fileTypeAnrede} \"${curDocName}\" wirklich löschen?`);
            setReallyDeleteDocId({ _id: curDocId, _rev: curDocRev });
          },
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};

interface ListItemReadableTextProps {
  dbname: string;
  docid: string;
  plainToClassFct: (data: unknown) => any;
}
const ListItemReadableText: React.FC<ListItemReadableTextProps> = ({ dbname, docid, plainToClassFct }) => {
  const title = useDocName(dbname, docid);

  return (
    <>
      <ListItemIcon>
        {!title.loading && title.type === "aufgabe" && <AufgabeIcon />}
        {!title.loading && title.type === "klausur" && <KlausurIcon />}
        {!title.loading && title.type === "gutachten" && <GutachtenIcon />}
        {!title.loading && title.type === "xsltemplate" && <XslTemplateIcon />}
        {title.loading && <CircularProgress />}
      </ListItemIcon>
      <ListItemText primary={title.loading ? "" : title.name} />
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export enum DbDirViewFileTypes {
  AUFGABEN = 1,
  KLAUSUREN = 2,
  TEMPLATES = 4,
  GUTACHTEN = 8,
}

interface DbDirViewProps {
  dbname: string;
  handleOpen: (dbname: string, docid: string, type: DocumentTypes) => void;
  selectedDocId?: string;
  showFileTypes?: number;
}
const DbDirView: React.FC<DbDirViewProps> = ({
  dbname,
  handleOpen,
  selectedDocId: selectedDoc,
  showFileTypes = DbDirViewFileTypes.AUFGABEN |
    DbDirViewFileTypes.KLAUSUREN |
    DbDirViewFileTypes.TEMPLATES |
    DbDirViewFileTypes.GUTACHTEN,
}) => {
  const allAufgaben = useAllAufgaben(dbname, false);
  const allKlausuren = useAllKlausuren(dbname, false);
  const allGutachten = useAllGutachten(dbname, false);
  const allTemplates = useAllTemplates(dbname, false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const deleteDoc = useDeleteDocument(dbname);
  const [reallyDeleteDocId, setReallyDeleteDocId] = useState<{ _id: string; _rev: string } | undefined>();
  const [reallyDeleteTitle, setReallyDeleteTitle] = useState("");

  const [source, setSource] = useState<DocumentAddress | undefined>();
  const [copyMessage, setCopyMessage] = useState("");

  let index = 0;
  return (
    <>
      <SimpleDialog
        open={reallyDeleteDocId !== undefined}
        handleCancel={() => setReallyDeleteDocId(undefined)}
        handleOK={() => {
          if (reallyDeleteDocId) {
            deleteDoc(reallyDeleteDocId);
            setReallyDeleteDocId(undefined);
          }
        }}
        title={reallyDeleteTitle}
      />
      <DuplicateDialog source={source} message={copyMessage} handleClose={() => setSource(undefined)} />
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            {(showFileTypes & DbDirViewFileTypes.AUFGABEN) > 0 && <Tab label="Aufgaben" />}
            {(showFileTypes & DbDirViewFileTypes.KLAUSUREN) > 0 && <Tab label="Klausuren" />}
            {(showFileTypes & DbDirViewFileTypes.GUTACHTEN) > 0 && <Tab label="Gutachten" />}
            {(showFileTypes & DbDirViewFileTypes.TEMPLATES) > 0 && <Tab label="Templates" />}
          </Tabs>
        </Box>
        {(showFileTypes & DbDirViewFileTypes.AUFGABEN) > 0 && (
          <TabPanel value={value} index={index++}>
            {allAufgaben && (
              <List dense>
                {allAufgaben.rows &&
                  allAufgaben.rows.map((row) => {
                    return (
                      <ListItem
                        key={row.id}
                        secondaryAction={
                          <DbEntryContextMenu
                            dbname={dbname}
                            curDocId={row.value._id}
                            curDocRev={row.value._rev}
                            curDocName={row.key[3]}
                            setSource={setSource}
                            setCopyMessage={setCopyMessage}
                            setReallyDeleteTitle={setReallyDeleteTitle}
                            setReallyDeleteDocId={setReallyDeleteDocId}
                            filetype={"aufgabe"}
                            fileTypeAnrede={"die Aufgabe"}
                          />
                        }
                      >
                        <ListItemButton
                          selected={selectedDoc && selectedDoc === row.id ? true : false}
                          onClick={() => handleOpen(dbname, row.id, "aufgabe")}
                        >
                          <ListItemReadableText dbname={dbname} docid={row.id} plainToClassFct={Aufgabe.plainToClass} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </TabPanel>
        )}
        {(showFileTypes & DbDirViewFileTypes.KLAUSUREN) > 0 && (
          <TabPanel value={value} index={index++}>
            {allKlausuren && (
              <List dense>
                {allKlausuren.rows &&
                  allKlausuren.rows.map((row) => {
                    return (
                      <ListItem
                        key={row.id}
                        secondaryAction={
                          <DbEntryContextMenu
                            dbname={dbname}
                            curDocId={row.value._id}
                            curDocRev={row.value._rev}
                            curDocName={row.key[4]}
                            setSource={setSource}
                            setCopyMessage={setCopyMessage}
                            setReallyDeleteTitle={setReallyDeleteTitle}
                            setReallyDeleteDocId={setReallyDeleteDocId}
                            filetype={"klausur"}
                            fileTypeAnrede={"die Klausur"}
                          />
                        }
                      >
                        <ListItemButton
                          selected={selectedDoc && selectedDoc === row.id ? true : false}
                          onClick={() => handleOpen(dbname, row.id, "klausur")}
                        >
                          <ListItemReadableText dbname={dbname} docid={row.id} plainToClassFct={Klausur.plainToClass} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </TabPanel>
        )}
        {(showFileTypes & DbDirViewFileTypes.GUTACHTEN) > 0 && (
          <TabPanel value={value} index={index++}>
            {allGutachten && (
              <List dense>
                {allGutachten.rows &&
                  allGutachten.rows.map((row) => {
                    return (
                      <ListItem
                        key={row.id}
                        secondaryAction={
                          <DbEntryContextMenu
                            dbname={dbname}
                            curDocId={row.value._id}
                            curDocRev={row.value._rev}
                            curDocName={row.key[3]}
                            setSource={setSource}
                            setCopyMessage={setCopyMessage}
                            setReallyDeleteTitle={setReallyDeleteTitle}
                            setReallyDeleteDocId={setReallyDeleteDocId}
                            filetype={"gutachten"}
                            fileTypeAnrede={"das Gutachten"}
                          />
                        }
                      >
                        <ListItemButton
                          selected={selectedDoc && selectedDoc === row.id ? true : false}
                          onClick={() => handleOpen(dbname, row.id, "gutachten")}
                        >
                          <ListItemReadableText
                            dbname={dbname}
                            docid={row.id}
                            plainToClassFct={Gutachten.plainToClass}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </TabPanel>
        )}
        {(showFileTypes & DbDirViewFileTypes.TEMPLATES) > 0 && (
          <TabPanel value={value} index={index++}>
            {allTemplates && (
              <List dense>
                {allTemplates.rows &&
                  allTemplates.rows.map((row) => {
                    return (
                      <ListItem
                        key={row.id}
                        secondaryAction={
                          <DbEntryContextMenu
                            dbname={dbname}
                            curDocId={row.value._id}
                            curDocRev={row.value._rev}
                            curDocName={row.key[4]}
                            setSource={setSource}
                            setCopyMessage={setCopyMessage}
                            setReallyDeleteTitle={setReallyDeleteTitle}
                            setReallyDeleteDocId={setReallyDeleteDocId}
                            filetype={"template"}
                            fileTypeAnrede={"das Template"}
                          />
                        }
                      >
                        <ListItemButton
                          selected={selectedDoc && selectedDoc === row.id ? true : false}
                          onClick={() => handleOpen(dbname, row.id, "xsltemplate")}
                        >
                          <ListItemReadableText
                            dbname={dbname}
                            docid={row.id}
                            plainToClassFct={XSLTemplate.plainToClass}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </TabPanel>
        )}
      </Box>
    </>
  );
};

export default DbDirView;
