import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem } from "@mui/material";
import icon from "../logo.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export interface ActionButton {
  icon: React.ReactNode;
  enabled: boolean;
  handler(): void;
}

export interface MoreVertItem {
  title: string;
  onClick: () => void;
}

export const MyAppbar: React.FC<{
  sidesheetToggleIcon?: React.ReactNode;
  actionButtons?: ActionButton[];
  moreVertMenuItems?: MoreVertItem[];
}> = ({ sidesheetToggleIcon, actionButtons, moreVertMenuItems }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar variant="dense">
        {sidesheetToggleIcon && <Box sx={{ mr: 2 }}>{sidesheetToggleIcon}</Box>}
        <img alt="Logo vom Klausurtool" src={icon} width="25" height="25" />
        <Typography variant="h6" sx={{ ml: 1, letterSpacing: 3, flexGrow: 1 }} color="inherit" component="div">
          KLAUSUR Tool
        </Typography>
        {actionButtons &&
          actionButtons.map((button: ActionButton, index) => {
            return (
              <IconButton
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                color="inherit"
                onClick={button.handler}
                edge="end"
                disabled={!button.enabled}
              >
                {button.icon}
              </IconButton>
            );
          })}

        {moreVertMenuItems && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {moreVertMenuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleClose();
                    item.onClick();
                  }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

MyAppbar.defaultProps = {
  sidesheetToggleIcon: undefined,
  actionButtons: undefined,
};
