import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HomeIcon from "@mui/icons-material/Home";
import FolderIcon from "@mui/icons-material/Folder";
import FolderSharedIcon from "@mui/icons-material/FolderShared";

import { useDbList } from "./CouchDbContext";
import React from "react";
import UserRightsDialog from "./UserRightsDialog";
import { Database } from "./HelperClasses";

interface DbListItemHelperProps {
  db: Database;
}
const DbListItemHelper: React.FC<DbListItemHelperProps> = ({ db }) => {
  return (
    <>
      <ListItemAvatar>
        <Avatar>{db.isMyUserDb ? <HomeIcon /> : db.isUserDb ? <FolderSharedIcon /> : <FolderIcon />}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={db.getReadableName()} secondary={db.name !== db.getReadableName() ? db.name : ""} />
    </>
  );
};

interface DbListItemProps {
  db: Database;
  primaryAction?: (db: Database) => void;
  secondaryAction: React.ReactNode;
  selected?: boolean;
}

export const DbListItem: React.FC<DbListItemProps> = ({ db, primaryAction, secondaryAction, selected }) => {
  return (
    <>
      {primaryAction && (
        <ListItemButton role={undefined} selected={selected} onClick={() => primaryAction(db)}>
          <DbListItemHelper db={db} />
          {secondaryAction && secondaryAction}
        </ListItemButton>
      )}
      {!primaryAction && (
        <ListItem selected={selected} secondaryAction={secondaryAction}>
          <DbListItemHelper db={db} />
        </ListItem>
      )}
    </>
  );
};

interface DBListProps {
  handleAddItem?: (db: Database) => void;
  onSelectDatabase?: (db: Database) => void;
  selectedDb?: Database | undefined;
  allowUserRightsEdit?: boolean;
}
const DBList: React.FC<DBListProps> = ({
  handleAddItem,
  onSelectDatabase: primaryActionHandler,
  selectedDb,
  allowUserRightsEdit = true,
}) => {
  const dbList = useDbList();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showUserRightsDialog, setShowUserRightsDialog] = React.useState(false);

  const [currentMenuDb, setCurrentMenuDb] = React.useState<Database | undefined>(undefined);
  const handleMenu = (event: React.MouseEvent<HTMLElement>, db: Database) => {
    setCurrentMenuDb(db);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddItemInt = () => {
    if (handleAddItem) {
      if (currentMenuDb) handleAddItem(currentMenuDb);
      handleClose();
      setCurrentMenuDb(undefined);
    }
  };
  const handleEditUserRights = () => {
    handleClose();
    setShowUserRightsDialog(true);
  };
  const showOptionsMenu = handleAddItem || allowUserRightsEdit;

  return (
    <>
      <UserRightsDialog
        open={showUserRightsDialog}
        handleClose={() => setShowUserRightsDialog(false)}
        db={currentMenuDb}
      />
      {showOptionsMenu && (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {handleAddItem && <MenuItem onClick={handleAddItemInt}>Item hinzufügen</MenuItem>}
          {allowUserRightsEdit && (
            <MenuItem onClick={handleEditUserRights} disabled={!currentMenuDb?.securityObject}>
              Benutzerrechte editieren
            </MenuItem>
          )}
        </Menu>
      )}
      <List dense>
        {dbList?.dbs.map((db, index) => {
          return (
            <DbListItem
              key={db.name}
              db={db}
              selected={selectedDb === db}
              primaryAction={primaryActionHandler && (() => primaryActionHandler(db))}
              secondaryAction={
                showOptionsMenu && (
                  <IconButton onClick={(e) => handleMenu(e, db)} edge="end" aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                )
              }
            />
          );
        })}
      </List>
    </>
  );
};

export default DBList;
