import { ReactNode, useCallback, useEffect, useState } from "react";
import AufgabeEditorStandaloneWrapper from "./AufgabeEditorStandaloneWrapper";

import StorageIcon from "@mui/icons-material/Storage";
import { AufgabeIcon, GutachtenIcon, KlausurIcon, XslTemplateIcon } from "../Icons";
import { Box, Tab, Tabs } from "@mui/material";
import KlausurEditor from "./KlausurEditor";
import { useDocName } from "../UseMetaInfo";
import XslTemplateView from "./xsltemplateview/XslTemplateView";
import DocumentSelectorComponent from "./DocumentSelectorComponent";
import GutachtenEditor from "./gutachtenview/GutachtenEditor";
import React from "react";
import { TabContextComponent, useTabContext } from "../../lib/TabView";
import { MainViewIsDirtyContextComponent } from "../MainViewIsDirtyContext";

interface MyTabProps {
  dbname: string;
  docid: string;
  isDirty: boolean;
  index: number;
}
const MyTab: React.FC<MyTabProps> = ({ dbname, docid, isDirty, index }) => {
  const { setValue } = useTabContext();
  const title = useDocName(dbname, docid);

  let readableName = !title.loading ? title.name : "LOADING...";
  if (isDirty) readableName = "* " + readableName;

  let icon = undefined;
  if (title.loading === false) {
    if (title.type === "aufgabe") icon = <AufgabeIcon />;
    else if (title.type === "klausur") icon = <KlausurIcon />;
    else if (title.type === "gutachten") icon = <GutachtenIcon />;
    else if (title.type === "xsltemplate") icon = <XslTemplateIcon />;
  }

  return <Tab label={readableName} onClick={() => setValue(index + 1)} iconPosition="start" icon={icon} />;
};

interface MyTabsProps {
  children: ReactNode;
}
const MyTabs: React.FC<MyTabsProps> = ({ children }) => {
  const { value, handleChange } = useTabContext();
  return (
    <Tabs value={value} onChange={handleChange}>
      {children}
    </Tabs>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, index, ...other } = props;
  const { value } = useTabContext();

  return (
    <div style={{ height: "100%" }} role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface HiddenWrapperProps {
  hidden: boolean;
  children: ReactNode;
}
const HiddenWrapper: React.FC<HiddenWrapperProps> = React.memo(({ hidden, children }) => {
  return (
    <div hidden={hidden} role="tabpanel" style={{ height: "100%" }}>
      {children}
    </div>
  );
});

interface MyTabPanelProps {
  index: number;
  dbname: string;
  docid: string;
}
const MyTabPanel: React.FC<MyTabPanelProps> = React.memo(({ index, dbname, docid }) => {
  const metaInfo = useDocName(dbname, docid);
  const { value } = useTabContext();

  return (
    <HiddenWrapper hidden={value !== index}>
      <Box sx={{ height: "100%" }}>
        <>
          {metaInfo && (
            <div style={{ height: "100%" }}>
              {metaInfo.type === "aufgabe" && <AufgabeEditorStandaloneWrapper dbname={dbname} docid={docid} />}

              {metaInfo.type === "klausur" && (
                <KlausurEditor
                  dbname={dbname}
                  docid={docid}
                  openEditorFct={(dbname: string, docid: string) => {}}
                  //handleIsDirty={handleIsDirty}
                />
              )}
              {metaInfo.type === "gutachten" && <GutachtenEditor dbname={dbname} docid={docid} />}
              {metaInfo.type === "xsltemplate" && <XslTemplateView dbname={dbname} docid={docid} />}
            </div>
          )}
        </>
      </Box>
    </HiddenWrapper>
  );
});

export interface IOpenDocument {
  dbname: string;
  docid: string;
  isDirty: boolean;
}

function hasIDocument(docs: IOpenDocument[], doc: IOpenDocument) {
  for (let i = 0; i < docs.length; i += 1) {
    if (docs[i].dbname === doc.dbname && docs[i].docid === doc.docid) {
      return true;
    }
  }
  return false;
}

interface MainEditorViewProps {
  open: (dbname: string, docid: string) => void;
  priv: {
    openDocuments: IOpenDocument[];
    setOpenDocuments: React.Dispatch<React.SetStateAction<IOpenDocument[]>>;
  };
}

export const useMainEditorView = () => {
  // manage open documents
  const [openDocuments, setOpenDocuments] = useState<IOpenDocument[]>([]);

  const open = useCallback(
    (dbname: string, docid: string) => {
      const newDoc: IOpenDocument = { dbname, docid, isDirty: false };
      if (hasIDocument(openDocuments, newDoc) === false) {
        const n = [...openDocuments, newDoc];
        setOpenDocuments(n);
      }
    },
    [openDocuments]
  );

  const [retVal, setRetVal] = useState<MainEditorViewProps>({
    open,
    priv: { openDocuments, setOpenDocuments },
  });

  useEffect(() => {
    setRetVal({ open, priv: { openDocuments, setOpenDocuments } });
  }, [open, openDocuments, setOpenDocuments]);

  return retVal;
};

const MainEditorView: React.FC<MainEditorViewProps> = (props) => {
  const handleOpen = (dbname: string, docid: string) => {
    props.open(dbname, docid);
  };

  const handleIsDirty = useCallback(
    (doc: IOpenDocument, isDirty: boolean) => {
      props.priv.setOpenDocuments((oldState) => {
        const newState: IOpenDocument[] = [...oldState];

        let changed = false;
        for (let i = 0; i < newState.length; i += 1) {
          const cur: IOpenDocument = newState[i];
          if (cur === doc && cur.isDirty !== isDirty) {
            changed = true;
            cur.isDirty = isDirty;
          }
        }
        if (changed) return newState;
        else return oldState;
      });
    },
    [props.priv]
  );

  return (
    <>
      <div style={{ height: "100%" }}>
        <TabContextComponent>
          <div style={{}}>
            <MyTabs>
              <Tab label="Datenbank" icon={<StorageIcon />} iconPosition="start" />
              {props.priv.openDocuments.map((doc, index) => {
                return (
                  <MyTab key={doc.docid} index={index} dbname={doc.dbname} docid={doc.docid} isDirty={doc.isDirty} />
                );
              })}
            </MyTabs>
          </div>
          <div style={{ height: "90%" }}>
            <TabPanel index={0}>
              <DocumentSelectorComponent allowNewDocument={true} handleSelect={handleOpen} />
            </TabPanel>
            {props.priv.openDocuments.map((doc, index) => {
              return (
                <MainViewIsDirtyContextComponent
                  key={doc.docid}
                  doc={doc}
                  setOpenDocuments={props.priv.setOpenDocuments}
                >
                  <MyTabPanel dbname={doc.dbname} docid={doc.docid} index={index + 1} />
                </MainViewIsDirtyContextComponent>
              );
            })}
          </div>
        </TabContextComponent>
      </div>
    </>
  );
};

export default MainEditorView;
