import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import _ from "lodash";

import { Database, SecurityObject } from "./HelperClasses";
import { useCouchDbContext } from "./CouchDbContext";
import { useAllUserList } from "./useAllUserList";

interface NamesListProps {
  title: string;
  names?: string[];
  noRemoveNames?: string[];
  removeFct: (index: number) => void;
  addNames?: string[];
  addFct?: (addName: string) => void;
}
const NamesList: React.FC<NamesListProps> = ({ title, names, noRemoveNames, removeFct, addNames, addFct }) => {
  const [selectedUser, setSelectedUser] = useState("");

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <List>
        {names?.map((name, index) => {
          return (
            <ListItem
              key={index}
              secondaryAction={
                !noRemoveNames?.includes(name) && (
                  <IconButton onClick={() => removeFct(index)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText primary={name} />
            </ListItem>
          );
        })}
      </List>
      {addFct && (
        <Stack direction="row">
          <FormControl fullWidth>
            <InputLabel id="select-benutzername">Benutzername wählen</InputLabel>
            <Select
              labelId="select-benutzername"
              value={selectedUser}
              label="Benutzername"
              onChange={(e: SelectChangeEvent) => {
                setSelectedUser(e.target.value as string);
              }}
            >
              {addNames
                ?.filter((addName) => {
                  return !names?.includes(addName);
                })
                .map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => {
              addFct(selectedUser);
              setSelectedUser("");
            }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      )}
    </Paper>
  );
};

interface UserRightsDialogProps {
  open: boolean;
  handleClose: () => void;
  db?: Database;
}
const UserRightsDialog: React.FC<UserRightsDialogProps> = ({ open, handleClose, db }) => {
  const ctx = useCouchDbContext();
  const users = useAllUserList();

  const [secObj, setSecObj] = useState<SecurityObject>(_.cloneDeep(db?.securityObject) as SecurityObject);

  useEffect(() => {
    setSecObj(_.cloneDeep(db?.securityObject) as SecurityObject);
  }, [db, open]);

  const removeItem = (path: string[], idx: number) => {
    // console.log("remove Item", path, idx);
    const newSec = { ...secObj };
    if (path[0] === "admins" && newSec.admins) {
      if (path[1] === "names" && newSec.admins && newSec.admins.hasOwnProperty("names"))
        newSec.admins.names.splice(idx, 1);
      else if (path[1] === "roles" && newSec.admins && newSec.admins.hasOwnProperty("roles"))
        newSec.admins.roles.splice(idx, 1);
    }
    if (path[0] === "members" && newSec.members) {
      if (path[1] === "names" && newSec.members && newSec.members.hasOwnProperty("names"))
        newSec.members.names.splice(idx, 1);
      else if (path[1] === "roles" && newSec.members && newSec.members.hasOwnProperty("roles"))
        newSec.members.roles.splice(idx, 1);
    }
    setSecObj(newSec as SecurityObject);
  };

  const addItem = (path: string[], addName: string) => {
    // console.log("add name", path, addName);
    const newSec = { ...secObj };

    if (path[0] === "admins" && path[1] === "names") {
      if (!newSec.admins) newSec.admins = { names: [], roles: [] };
      if (!newSec.admins.names) newSec.admins.names = [];
      newSec.admins.names.push(addName);
    }
    if (path[0] === "admins" && path[1] === "roles") {
      if (!newSec.admins) newSec.admins = { names: [], roles: [] };
      if (!newSec.admins.roles) newSec.admins.roles = [];
      newSec.admins.roles.push(addName);
    }
    if (path[0] === "members" && path[1] === "names") {
      if (!newSec.members) newSec.members = { names: [], roles: [] };
      if (!newSec.members.names) newSec.members.names = [];
      newSec.members.names.push(addName);
    }
    if (path[0] === "members" && path[1] === "roles") {
      if (!newSec.members) newSec.members = { names: [], roles: [] };
      if (!newSec.members.roles) newSec.members.roles = [];
      newSec.members.roles.push(addName);
    }
    setSecObj(newSec as SecurityObject);
  };

  const handleSave = () => {
    db?.saveSecurity(secObj);
    handleClose();
  };

  return (
    <>
      {db && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Benutzerrechte für Datenbank bearbeiten</DialogTitle>
          <DialogContent>
            <DialogContentText>Datenbank: {db.getReadableName()}</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NamesList
                  title="Administratoren:"
                  names={secObj?.admins?.names}
                  noRemoveNames={ctx.state.username ? [ctx.state.username] : []}
                  removeFct={(idx) => removeItem(["admins", "names"], idx)}
                  addNames={users.map((user) => user.name)}
                  addFct={(addName) => addItem(["admins", "names"], addName)}
                />
              </Grid>
              <Grid item xs={6}>
                <NamesList
                  title="Admin Groups:"
                  names={secObj?.admins?.roles}
                  noRemoveNames={ctx.state.userRoles}
                  removeFct={(idx) => removeItem(["admins", "roles"], idx)}
                  addFct={(addName) => addItem(["admins", "roles"], addName)}
                />
              </Grid>
              <Grid item xs={6}>
                <NamesList
                  title="Members:"
                  names={secObj?.members?.names}
                  noRemoveNames={ctx.state.username ? [ctx.state.username] : []}
                  removeFct={(idx) => removeItem(["members", "names"], idx)}
                  addNames={users.map((user) => user.name)}
                  addFct={(addName) => addItem(["members", "names"], addName)}
                />
              </Grid>
              <Grid item xs={6}>
                <NamesList
                  title="Member Groups:"
                  names={secObj?.members?.roles}
                  noRemoveNames={ctx.state.userRoles}
                  removeFct={(idx) => removeItem(["members", "roles"], idx)}
                  addNames={users.map((user) => user.name)}
                  addFct={(addName) => addItem(["members", "roles"], addName)}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Abbruch</Button>
            <Button onClick={handleSave}>Speichern</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserRightsDialog;
