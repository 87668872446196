import { useEffect, useState } from "react";
import { XSLFileData } from "./XSLTemplate";
import xsltProcessor from "./xsltProcessor";

export async function doXslt(xslFiles: XSLFileData[], xmlData?: string) {
  if (xmlData && xslFiles.length > 0) {
    const xslt = await xsltProcessor(xslFiles, xmlData);
    return xslt;
  } else {
    return undefined;
  }
}

export interface UseXsltProps {
  xslFiles: XSLFileData[];
  xmlData?: string;
}

const useXsltResult = ({ xslFiles, xmlData }: UseXsltProps) => {
  const [xsltResult, setXsltResult] = useState<string | undefined>();

  useEffect(() => {
    doXslt(xslFiles, xmlData)
      .then((res) => {
        setXsltResult(res);
      })
      .catch((e) => {
        setXsltResult(undefined);
        console.log(e);
      });
  }, [xslFiles, xmlData]);

  return xsltResult;
};

export default useXsltResult;
