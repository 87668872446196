import { InputAdornment, InputProps, TextField } from "@mui/material";
import { useFormContext, useController } from "react-hook-form";
import { useEditModeContext } from "./EditableView";

interface Props {
  label?: string;
  fieldName: string;
  textAlign?: "left" | "right" | "center";
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  editEnabled?: boolean;
  type?: "text" | "time" | "number" | "date";
  min?: number;
  max?: number;
  step?: number;
  fullWidth?: boolean;
  typographyVariant?:
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "overline";
  inputProps?: Partial<InputProps>;
  error?: boolean;
  helperText?: string;
  variant?: "outlined" | "filled" | "standard";
  sx?: object;
  suffix?: string;
}

const EditableTextField: React.FC<Props> = ({
  label,
  fieldName,
  placeholder,
  required,
  multiline,
  editEnabled,
  type,
  fullWidth,
  inputProps,
  error,
  helperText,
  variant,
  sx,
  suffix,
}: Props) => {
  const form = useFormContext();

  const { field } = useController({
    name: fieldName,
    control: form.control,
    rules: { required: required },
  });

  const ctx = useEditModeContext();
  if (ctx && ctx.editModeEnabled === true) editEnabled = true;

  return (
    <>
      <TextField
        sx={[
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        name={field.name}
        onBlur={field.onBlur}
        onChange={(e) => {
          if (type === "number") {
            form.setValue(fieldName, Number(e.target.value.replace(/,/, ".")), {
              shouldDirty: true,
            });
          } else
            form.setValue(fieldName, e.target.value, { shouldDirty: true });
        }}
        ref={field.ref}
        value={field.value}
        label={label}
        placeholder={placeholder}
        variant={variant}
        type={type}
        disabled={!editEnabled}
        fullWidth={fullWidth}
        required={required}
        multiline={multiline}
        InputProps={{
          ...inputProps,
          endAdornment: suffix ? (
            <InputAdornment position="end">{suffix}</InputAdornment>
          ) : (
            inputProps?.endAdornment
          ),
        }}
        error={error}
        helperText={helperText}
      />
    </>
  );
  //return <Controller name={fieldName} render={({ field }) => <input type={type} {...field} />} />;
};

EditableTextField.defaultProps = {
  label: "",
  placeholder: "",
  required: false,
  multiline: false,
  fullWidth: true,
  editEnabled: false,
  type: "text",
  typographyVariant: "body1",
  inputProps: { inputProps: {} },
  variant: "standard",
};

interface SimpleEditableTextFieldProps {
  value: any;
  onChange: (e: any) => void;
}
export const SimpleEditableTextField: React.FC<
  SimpleEditableTextFieldProps
> = ({ value, onChange }) => {
  const { editModeEnabled } = useEditModeContext();
  return (
    <TextField
      disabled={!editModeEnabled}
      variant="standard"
      value={value}
      onChange={onChange}
      fullWidth
    />
  );
};

export default EditableTextField;
