import { useCallback } from "react";

const useClipboard = () => {
  const read = useCallback(async (type: string): Promise<Blob | undefined> => {
    try {
      /*const permission = await navigator.permissions.query({
        name: "clipboard-read",
      });*/
      //if (permission.state !== "denied") {

      const clipboardContents = await navigator.clipboard.read();
      console.log(clipboardContents, type);
      for (const item of clipboardContents) {
        if (item.types.includes(type)) {
          console.log(item);
          const blob = await item.getType(type);
          console.log(blob);
          return blob;
        }
      }
      return undefined;
      //} else {
      //  console.log("Not allowed to read clipboard.");
      // }
    } catch (error: any) {
      console.error(error.message);
    }
  }, []);

  const write = useCallback((blob: Blob) => {
    navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);
  }, []);

  const writeText = useCallback((text: string) => {
    navigator.clipboard.writeText(text);
  }, []);

  return { read, write, writeText };
};

export default useClipboard;
