export function formatAufgabeNummer(n: Array<number>): string {
  let result = "";
  n.forEach((element) => {
    if (result.length > 0) result += ".";
    result = `${result}${element}`;
  });
  result += " ";
  return result;
}

export function makeChildAufgabeNummer(n: Array<number>, childNum?: number): Array<number> {
  const result = [...n];
  if (childNum) result.push(childNum);
  else result.push(1);
  return result;
}

export function incAufgabeNummer(n: number[], addVal = 1) {
  const result = [...n];
  result[result.length - 1] = result[result.length - 1] + addVal;
  return result;
}
