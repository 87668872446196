import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { isEqual } from "lodash";
import { createContext, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDoc } from "use-pouchdb";
import { DocumentAddress } from "../../lib/couchdb-mgr/CouchDbContext";
import { download } from "../../lib/download";
import SimpleDialog from "../../lib/SimpleDialog";
import { RootState } from "../../redux/hooks";
import { klausurEditorActions } from "../../redux/slices/klausurEditorSlice";
import { DocumentTypes } from "../BaseObject";
import { DbDirViewFileTypes } from "../DbDirView";
import { AddAufgabeIcon, XslTemplateIcon } from "../Icons";
import { Klausur } from "../Klausur";
import { useCompleteCompiler, useLatexPreparationCompiler } from "../UseCompleteCompiler";
import { UsePdfCompilerProps } from "../UseServerXeTeXCompiler";
import DocumentSelectorDialog from "./DocumentSelectorDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import LatexView from "../../lib/LatexView";
import { useFormContext, useWatch } from "react-hook-form";

interface VorschauContextType {
  latexViewerProps?: UsePdfCompilerProps;
  setLatexViewerProps: React.Dispatch<React.SetStateAction<UsePdfCompilerProps | undefined>>;
  setInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  klausurValue: {
    [x: string]: any;
  };
}
const VorschauContext = createContext<VorschauContextType>({
  setLatexViewerProps: (v: SetStateAction<UsePdfCompilerProps | undefined>) => {
    throw new Error("not implemented.");
  },
  setInProgress: (v: SetStateAction<boolean>) => {
    throw new Error("not implemented.");
  },
  klausurValue: { something: "unimplemented" },
});

const useUpdatedKlausurValue = (control: any) => {
  const formValues = useWatch({ control: control });
  const [klausurValue, setKlausurValue] = useState(formValues);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const [inProgress, setInProgress] = useState(false);
  useEffect(() => {
    timeoutRef.current = setInterval(() => {
      if (!inProgress && JSON.stringify(klausurValue) !== JSON.stringify(formValues)) {
        setKlausurValue(JSON.parse(JSON.stringify(formValues)));
      }
    }, 2000);

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    };
  }, [formValues, inProgress, klausurValue]);

  return { setInProgress, klausurValue };
};

interface TemplateListIconProps {
  template: DocumentAddress;
  clicked: () => void;
  removed: () => void;
  selected: boolean;
  download: (templateName: string) => void;
  downloadInProgress: boolean;
}

const TemplateListIcon: React.FC<TemplateListIconProps> = ({
  template,
  clicked,
  removed,
  download,
  downloadInProgress,
  selected,
}) => {
  const doc = useDoc(template.docid, { db: template.dbname });

  return (
    <ListItem
      secondaryAction={
        <>
          {!downloadInProgress && (
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={() => download((doc.doc as any).name ? (doc.doc as any).name : "template")}
            >
              <DownloadIcon />
            </IconButton>
          )}
          {downloadInProgress && <CircularProgress size="12pt" />}
          <IconButton edge="end" aria-label="comments" onClick={removed}>
            <DeleteIcon />
          </IconButton>
        </>
      }
    >
      <ListItemButton onClick={clicked} selected={selected}>
        <ListItemIcon>
          <XslTemplateIcon />
        </ListItemIcon>
        <ListItemText secondary={doc.state === "done" && (doc.doc as any).name} />
      </ListItemButton>
    </ListItem>
  );
};

interface VorschauViewProps {
  dbname: string;
  docid?: string;
}
export const VorschauView: React.FC<VorschauViewProps> = ({ dbname, docid }) => {
  //setLatexViewerProps: (latexViewProps: UsePdfCompilerProps | undefined) => void;
  //klausurValue: any;

  const { setLatexViewerProps, klausurValue } = useContext(VorschauContext);

  const reduxDispatch = useDispatch();
  const completeCompiler = useCompleteCompiler();

  const [localTemplates, setLocalTemplates] = useState<DocumentAddress[]>([]);
  const prevDocAdr = useRef<{ dbname: string; docid?: string }>({ dbname, docid });

  const stateTemplates = useSelector((state: RootState) => {
    if (docid && state.klausurEditorSettings && state.klausurEditorSettings.templateListByDbNames) {
      const dbList = state.klausurEditorSettings.templateListByDbNames[dbname];
      if (dbList) {
        const docEntry = dbList[docid] as Array<any>;
        if (docEntry) {
          return docEntry.map((entry) => {
            return { dbname: entry.templateDbName, docid: entry.templateDocId };
          });
        }
      }
    }
    return [];
  });

  useEffect(() => {
    if (prevDocAdr.current.dbname === dbname && prevDocAdr.current.docid === undefined && docid !== undefined) {
      // eine zuvor neue Klausur wurde gespeichert und hat jetzt eine id... die
      // Template-Konfiguration kann nun ebenfalls gespeichert werden!
      if (localTemplates.length > 0) {
        // speichern
        reduxDispatch(
          klausurEditorActions.setTemplates({
            dbname,
            docid,
            templates: localTemplates.map((entry) => {
              return { templateDbName: entry.dbname, templateDocId: entry.docid };
            }),
          })
        );
      }
    }
    prevDocAdr.current = { dbname, docid };
  }, [dbname, docid, localTemplates, reduxDispatch]);

  const [showDocSelectorDlg, setShowDocSelectorDlg] = useState(false);
  const [reallyDeleteIndex, setReallyDeleteIndex] = useState(-1);
  const reallyDelete = () => {
    const n = [...localTemplates];
    n.splice(reallyDeleteIndex, 1);

    setLocalTemplates(n);
    if (docid)
      reduxDispatch(
        klausurEditorActions.setTemplates({
          dbname,
          docid,
          templates: n.map((entry) => {
            return { templateDbName: entry.dbname, templateDocId: entry.docid };
          }),
        })
      );
    setReallyDeleteIndex(-1);
  };

  const templates = stateTemplates.length > 0 ? stateTemplates : localTemplates;

  const [selectedTemplate, setSelectedTemplate] = useState<DocumentAddress | undefined>();

  const dataUrl = useRef<string | undefined>();
  const pdfCompiler = useLatexPreparationCompiler();
  const inProgress = useRef(false);
  useEffect(() => {
    (async () => {
      if (dataUrl.current) {
        URL.revokeObjectURL(dataUrl.current);
        dataUrl.current = undefined;
      }

      if (selectedTemplate && docid && !inProgress.current) {
        // do PDF conversion
        setLatexViewerProps(await pdfCompiler(klausurValue as Klausur, dbname, selectedTemplate));
      } else {
        setLatexViewerProps(undefined);
      }
    })();

    return () => {
      if (dataUrl.current) {
        URL.revokeObjectURL(dataUrl.current);
        dataUrl.current = undefined;
        setLatexViewerProps(undefined);
      }
    };
  }, [selectedTemplate, klausurValue, setLatexViewerProps, docid, pdfCompiler, dbname]);

  const [downloadInProgress, setDownloadInProgress] = useState<number[]>([]);

  return (
    <>
      <SimpleDialog
        open={reallyDeleteIndex !== -1}
        handleCancel={() => setReallyDeleteIndex(-1)}
        handleOK={reallyDelete}
        title={`Wollen Sie das Template ${reallyDeleteIndex + 1} wirklich aus der Liste entfernen?`}
        abbruchText="Abbruch"
        okText="JA WIRKLICH ENTFERNEN!!"
      />
      <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
        <DocumentSelectorDialog
          open={showDocSelectorDlg}
          showFileTypes={DbDirViewFileTypes.TEMPLATES}
          handleOpen={function (templatedbname: string, templatedocid: string, type: DocumentTypes): void {
            let exists = false;
            for (let i = 0; i < localTemplates.length; ++i) {
              if (isEqual(localTemplates[i], { dbname: templatedbname, docid: templatedocid })) {
                exists = true;
                break;
              }
            }
            if (!exists) {
              setLocalTemplates([...localTemplates, { dbname: templatedbname, docid: templatedocid }]);
              if (docid)
                reduxDispatch(
                  klausurEditorActions.addTemplate({
                    dbname,
                    docid,
                    templateDbName: templatedbname,
                    templateDocId: templatedocid,
                  })
                );
            }

            setShowDocSelectorDlg(false);
          }}
          handleCancel={() => setShowDocSelectorDlg(false)}
        />
        <Typography variant="h6">Vorschau wählen:</Typography>
        <List>
          {templates.map((template, index) => {
            return (
              <TemplateListIcon
                key={template.docid}
                template={template}
                selected={isEqual(template, selectedTemplate)}
                downloadInProgress={downloadInProgress.findIndex((element) => element === index) !== -1}
                download={async (templateName: string) => {
                  setDownloadInProgress([...downloadInProgress, index]);
                  const blob = await completeCompiler(klausurValue as Klausur, dbname, template);
                  download(`${klausurValue.name ? klausurValue.name + "_" : ""}${templateName}.pdf`, blob);
                  setDownloadInProgress(downloadInProgress.filter((element) => element !== index));
                }}
                clicked={() => {
                  if (isEqual(template, selectedTemplate)) setSelectedTemplate(undefined);
                  else setSelectedTemplate(template);
                }}
                removed={() => setReallyDeleteIndex(index)}
              />
            );
          })}
          <ListItem>
            <ListItemButton onClick={() => setShowDocSelectorDlg(true)}>
              <ListItemIcon>
                <AddAufgabeIcon />
              </ListItemIcon>
              <ListItemText secondary="Template hinzufügen" />
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
    </>
  );
};

export const LatexViewWrapper: React.FC = () => {
  const { latexViewerProps, setInProgress } = useContext(VorschauContext);
  return <>{latexViewerProps && <LatexView props={latexViewerProps} inProgressChanged={setInProgress} />}</>;
};

interface VorschauContextComponentProps {
  children: React.ReactNode;
}
export const VorschauContextComponent: React.FC<VorschauContextComponentProps> = ({ children }) => {
  const form = useFormContext();

  const [latexViewerProps, setLatexViewerProps] = useState<UsePdfCompilerProps | undefined>();

  //const formValues = editableView.form.watch();
  const { setInProgress, klausurValue } = useUpdatedKlausurValue(form.control);

  const memoizedValue = useMemo(() => {
    return { latexViewerProps, setLatexViewerProps, setInProgress, klausurValue };
  }, [klausurValue, latexViewerProps, setInProgress]);

  return <VorschauContext.Provider value={memoizedValue}>{children}</VorschauContext.Provider>;
};

export const useKlausurVorschauContext = () => {
  return useContext(VorschauContext);
};
