import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import EditableTextField from "../../lib/EditableTextField";
import { AufgabeDefinitionEditor } from "./AufgabeDefinitionEditor";
import AufgabeMetaInfoEditor from "./AufgabeMetaInfoEditor";
import React from "react";

interface AufgabeEditorProps {
  path: string;
  sx?: object;
  showMetaInfoEditor?: boolean;
  aufgabeNummer?: number[];
}
const AufgabeEditor: React.FC<AufgabeEditorProps> = React.memo(
  ({ path, sx = [], showMetaInfoEditor = true, aufgabeNummer }) => {
    return (
      <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
        <Stack spacing={2}>
          {showMetaInfoEditor && (
            <>
              <Card sx={[{ minWidth: "100%" }, ...(Array.isArray(sx) ? sx : [sx])]}>
                <CardContent>
                  <Typography variant="h6">Name des Documents:</Typography>
                  <EditableTextField label="Name des Documents" fieldName={path.length > 0 ? `${path}.name` : "name"} />
                </CardContent>
              </Card>
              <AufgabeMetaInfoEditor prefix={`${path}.`} />
            </>
          )}
          <AufgabeDefinitionEditor path={`${path}.definition`} aufgabeNummer={aufgabeNummer} />
        </Stack>
      </Box>
    );
  }
);
export default AufgabeEditor;
