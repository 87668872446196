import { useEffect, useState } from "react";
import { DocumentTypes, useAllFromViewGeneric } from "./BaseObject";

interface UseMetaInfoResult {
  type?: DocumentTypes;
  name?: string;
  loading: boolean;
}
export const useDocName = (dbname: string, docid: string) => {
  const [docTitle, setDocTitle] = useState<UseMetaInfoResult>({ loading: true });

  const metaInfoRaw = useAllFromViewGeneric({
    dbname: dbname,
    viewName: "klausurtool/allDocsName",
    includeDocs: false,
    key: docid,
  });
  useEffect(() => {
    if (metaInfoRaw.rows && metaInfoRaw.rows.length === 1)
      setDocTitle({
        type: metaInfoRaw.rows[0].value.type,
        name:
          metaInfoRaw.rows[0].value.name && metaInfoRaw.rows[0].value.name.length > 0
            ? metaInfoRaw.rows[0].value.name
            : "Unbenannt",
        loading: false,
      });
    else {
      setDocTitle({ loading: metaInfoRaw.loading });
    }
  }, [metaInfoRaw]);
  return docTitle;
};
