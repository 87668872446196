import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, UseFieldArrayReturn, useFieldArray, useWatch } from "react-hook-form";
import CodeViewer from "../../../lib/CodeViewer";
import EditableTextField from "../../../lib/EditableTextField";
import { EditableView, useEditableViewContext } from "../../../lib/EditableView";
import LatexView from "../../../lib/LatexView";
import { useGenericDocument } from "../../BaseObject";
import useXsltResult from "../../useXsltResult";
import { useSaveXSLTemplate, XSLFileData, XSLTemplate } from "../../XSLTemplate";
import ViewSelectionCheckList, { ViewState } from "./ViewSelectionCheckList";
import XslFileEditor from "./XslFileEditor";
import XslTransformationResultView from "./XslTransformationResultView";
import React from "react";
import { MainViewHandleDirtyChecker } from "../../MainViewIsDirtyContext";
import { Document2XmlContextComponent, DocumentLoadingProxy, useDocument2XmlResult } from "../../UseDocument2Xml";
import { DocumentAttachment } from "../../../lib/AttachmentManager";
import useClipboard from "../../../lib/useClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface XslFileContextType {
  files: UseFieldArrayReturn<FieldValues, "xslFiles", "id">;
}
const XslFileContext = createContext<XslFileContextType>({
  files: {} as UseFieldArrayReturn<FieldValues, "xslFiles", "id">,
});
interface XslFileContextComponentProps {
  children: React.ReactNode;
}
const XslFileContextComponent: React.FC<XslFileContextComponentProps> = ({ children }) => {
  /*
   *  Problem:
   *  Values in einem Array können entweder über fieldArray.update() oder form.setValue() aktualisiert werden.
   *  update() löst ein remount aus, das ist hier nicht erwünscht. form.setValue() propagiert den neuen
   *  value aber nicht in das fieldArray.
   *
   *  Versuch einer Lösung:
   *  Das Field Array wird verwendet, um über das Array zu iterieren und die "Anordnung" des Array zu ändern,
   *  Zugriff auf die Werte darf nur über useWatch() oder das form erfolgen.
   */
  const files = useFieldArray({ name: "xslFiles" });
  return <XslFileContext.Provider value={{ files }}>{children}</XslFileContext.Provider>;
};
export const useXslFileContext = () => useContext(XslFileContext);

interface SetXslFileHelperProps {
  selectedXslFile: number;
  setXslFilesForProcessing: React.Dispatch<React.SetStateAction<XSLFileData[]>>;
}
const SetXslFileHelper: React.FC<SetXslFileHelperProps> = ({ selectedXslFile, setXslFilesForProcessing }) => {
  const { getValues } = useEditableViewContext();
  const v = useWatch({ name: `xslFiles.${selectedXslFile}.data` });
  const { files } = useContext(XslFileContext);
  //const v = useWatch({ control: editableView.form.control, name: `xslFiles.${selectedXslFile}.data` });
  //const ref = (files.fields.at(0) as any).data;
  //if (v !== ref) {
  // ref wird nicht aktualisiert!
  //console.log({ v, ref });
  //}
  useEffect(() => {
    //console.log("XslUpdate");
    setXslFilesForProcessing(
      files.fields.map((file, index) => {
        //if (index === selectedXslFile) return { filename: (file as any).filename, data: v };
        return {
          filename: getValues(`xslFiles.${index}.filename`),
          data: getValues(`xslFiles.${index}.data`),
          type: "filedata",
        };
      })
    );
  }, [v]);
  return <></>;
};

const XmlViewer: React.FC = () => {
  const { result } = useDocument2XmlResult();
  const clipboard = useClipboard();

  return (
    <>
      {result.loading && <CircularProgress />}
      {!result.loading && !result && <Typography>Bitte Klausur auswählen!</Typography>}
      {!result.loading && result.error && <Typography>{result.error}</Typography>}
      {!result.loading && !result.error && result.xml && (
        <div style={{ position: "relative", maxHeight: "100%", height: "100%" }}>
          <Button
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              if (result.xml) clipboard.writeText(result.xml);
            }}
            sx={{ zIndex: 10, margin: 0, top: 20, right: 20, left: "auto", position: "absolute" }}
          />
          <CodeViewer data={result.xml as string} language="markdown" />
        </div>
      )}
    </>
  );
};

interface XsltResultViewerContextType {
  latex: string | undefined;
  attachments: DocumentAttachment[];
}
const XsltResultViewerContext = createContext<XsltResultViewerContextType>({} as XsltResultViewerContextType);
interface XsltResultViewerContextComponentProps {
  xslFilesForProcessing: XSLFileData[];
  children: ReactNode;
}
const XsltResultViewerContextComponent: React.FC<XsltResultViewerContextComponentProps> = ({
  xslFilesForProcessing,
  children,
}) => {
  const { result } = useDocument2XmlResult();

  const latex = useXsltResult({ xslFiles: xslFilesForProcessing, xmlData: result.xml });

  const ctxValue = useMemo(() => {
    return { latex, attachments: result.attachments };
  }, [latex, result.attachments]);

  return <XsltResultViewerContext.Provider value={ctxValue}>{children}</XsltResultViewerContext.Provider>;
};

interface XsltResultViewerProps {}
const XsltResultViewer: React.FC<XsltResultViewerProps> = () => {
  const { latex, attachments } = useContext(XsltResultViewerContext);
  return (
    <>
      {!latex && <Typography>Warte auf .tex-File...</Typography>}
      {latex && <LatexView props={{ latexDocument: latex, attachments }} />}
    </>
  );
};

export const useXsltResultViewerContext = () => {
  return useContext(XsltResultViewerContext);
};

interface ImplProps {
  dbname: string;
  xslTemplate: XSLTemplate;
}
const Impl: React.FC<ImplProps> = ({ dbname, xslTemplate }) => {
  const saveTemplate = useSaveXSLTemplate(dbname);

  const saveFunction = useCallback(
    async (templateNeuRaw: any) => {
      const templateNeu = XSLTemplate.plainToClass(templateNeuRaw);
      if (templateNeu) await saveTemplate(templateNeu);
      else throw new Error("INTERNAL ERROR!");
    },
    [saveTemplate]
  );

  const [xslFilesForProcessing, setXslFilesForProcessing] = useState<XSLFileData[]>([]);

  const [selectedXslFile, setSelectedXslFile] = useState(0);

  const [viewState, setViewState] = useState<ViewState>({ xsl: true, xml: false, latex: false, pdf: false });

  const [widthOfGridItem, setWidthOfGridItem] = useState(12);
  useEffect(() => {
    let v = 0;
    if (viewState.xml) v++;
    if (viewState.xsl) v++;
    if (viewState.pdf) v++;
    if (viewState.latex) v++;
    if (v === 0) v = 1;
    setWidthOfGridItem(12 / v);
  }, [viewState.xml, viewState.xsl, viewState.pdf, viewState.latex]);

  return (
    <EditableView value={xslTemplate} dbname={dbname} saveFunction={saveFunction}>
      <Document2XmlContextComponent>
        <XsltResultViewerContextComponent xslFilesForProcessing={xslFilesForProcessing}>
          <XslFileContextComponent>
            <MainViewHandleDirtyChecker />
            <SetXslFileHelper selectedXslFile={selectedXslFile} setXslFilesForProcessing={setXslFilesForProcessing} />
            <Stack sx={{ height: "100%", maxWidth: "95vw" }}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Name des Templates:</Typography>
                  <EditableTextField label="Name des Documents" fieldName={`name`} />
                </CardContent>
              </Card>
              <ViewSelectionCheckList
                defaultState={viewState}
                stateChanged={setViewState}
                onSelectXslFile={setSelectedXslFile}
              />
              <Grid container spacing={1} sx={{ height: "100%", width: "100%" }}>
                {viewState.xsl && (
                  <Grid item xs={widthOfGridItem} sx={{ maxHeight: "100%" }}>
                    <XslFileEditor selectedFileIdx={selectedXslFile} />
                  </Grid>
                )}
                {viewState.xml && (
                  <Grid item xs={widthOfGridItem} sx={{ maxHeight: "100%" }}>
                    <XmlViewer />
                  </Grid>
                )}
                {viewState.latex && (
                  <Grid item xs={widthOfGridItem} sx={{ maxHeight: "100%" }}>
                    <DocumentLoadingProxy>
                      <XslTransformationResultView />
                    </DocumentLoadingProxy>
                  </Grid>
                )}
                {viewState.pdf && (
                  <Grid item xs={widthOfGridItem} sx={{ maxHeight: "100%" }}>
                    <Box sx={{ position: "fixed", width: `${(widthOfGridItem * 100) / 12 - 1}%` }}>
                      <XsltResultViewer />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </XslFileContextComponent>
        </XsltResultViewerContextComponent>
      </Document2XmlContextComponent>
    </EditableView>
  );
};

interface XslTemplateViewProps {
  dbname: string;
  docid: string;
}

const XslTemplateView: React.FC<XslTemplateViewProps> = React.memo(({ dbname, docid }) => {
  const template = useGenericDocument(dbname, docid, XSLTemplate.plainToClass);
  return (
    <>
      {template.doc && <Impl dbname={dbname} xslTemplate={template.doc} />}
      {!template.doc && <CircularProgress />}
    </>
  );
});

export default XslTemplateView;
