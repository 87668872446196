import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { DocumentTypes } from "../BaseObject";
import DocumentSelectorComponent from "./DocumentSelectorComponent";

interface DocumentSelectorProps {
  open: boolean;
  handleOpen: (dbname: string, docid: string, type: DocumentTypes) => void;
  handleCancel: () => void;
  title?: string;
  showFileTypes?: number;
}
const DocumentSelectorDialog: React.FC<DocumentSelectorProps> = ({
  open,
  handleOpen,
  handleCancel,
  title = "Document auswählen",
  showFileTypes,
}) => {
  const [selected, setSelected] = useState<undefined | { dbname: string; docid: string; type: DocumentTypes }>();

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DocumentSelectorComponent
          allowNewDocument={false}
          handleSelect={(dbname, docid, type) => setSelected({ dbname, docid, type })}
          showFileTypes={showFileTypes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Abbruch</Button>
        <Button
          disabled={selected === undefined}
          onClick={() => {
            if (selected) handleOpen(selected.dbname, selected.docid, selected.type);
          }}
        >
          Auswählen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentSelectorDialog;
