import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm, FormProvider } from "react-hook-form";
import EditableTextField from "../../../lib/EditableTextField";
import SimpleDialog from "../../../lib/SimpleDialog";
import { useDeleteDocument } from "../../BaseObject";
import {
  Gutachten,
  useSaveGutachtenEintrag,
  GutachtenEintrag,
} from "../../Gutachten";
import { GutachtenEntryIcon, AddGutachtenEintragIcon } from "../../Icons";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { useEditableViewContext } from "../../../lib/EditableView";
import { withContextSelector } from "../../../lib/WithContextSelector";
import { useGutachtenLoadingContext } from "./GutachtenLoadingContext";

interface FormType {
  vorname: string;
  nachname: string;
  prüfungsgruppe: string;
}
interface GutachtenEintragHinzufügenDialogProps {
  open: boolean;
  dbname: string;
  handleClose: () => void;
  title: string;
  gutachten: Gutachten;
}
const GutachtenEintragHinzufügenDialog: React.FC<
  GutachtenEintragHinzufügenDialogProps
> = ({ open, dbname, handleClose, title, gutachten }) => {
  const saveGutachtenEintrag = useSaveGutachtenEintrag(dbname);

  const form = useForm<FormType>({
    defaultValues: { vorname: "", nachname: "", prüfungsgruppe: "" },
  });

  useEffect(() => {
    if (open) form.reset();
  }, [open]);

  const handleOK = (data: FormType) => {
    // TODO: create entry
    const ge = new GutachtenEintrag(gutachten._id, gutachten.blueprint);
    ge.vorname = data.vorname;
    ge.nachname = data.nachname;
    ge.prüfungsgruppe = data.prüfungsgruppe;
    saveGutachtenEintrag(ge);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={form.handleSubmit(handleOK)}>
          <FormProvider {...form}>
            <EditableTextField
              fieldName="nachname"
              label="Nachname"
              required
              error={form.formState.errors.nachname !== undefined}
              helperText={
                form.formState.errors.nachname !== undefined
                  ? "Ein Nachname wird benötigt."
                  : undefined
              }
              editEnabled={true}
            />
            <EditableTextField
              fieldName="vorname"
              label="Vorname"
              required
              error={form.formState.errors.vorname !== undefined}
              helperText={
                form.formState.errors.vorname !== undefined
                  ? "Ein Vorname wird benötigt!"
                  : undefined
              }
              editEnabled={true}
            />
            <EditableTextField
              fieldName="prüfungsgruppe"
              label="Prüfungsgruppe"
              required
              error={form.formState.errors.prüfungsgruppe !== undefined}
              helperText={
                form.formState.errors.prüfungsgruppe !== undefined
                  ? "Bitte Prüfungsgruppe (Jahrgang oder Klasse) angeben."
                  : undefined
              }
              editEnabled={true}
            />
          </FormProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbruch</Button>
        <Button onClick={form.handleSubmit(handleOK)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

const GutachtenEintragHinzufügenDialogWithProps = withContextSelector(
  GutachtenEintragHinzufügenDialog,
  { dbname: (ctx) => ctx.dbname, gutachten: (ctx) => ctx.gutachten },
  useGutachtenLoadingContext
);

interface CurEntryContextType {
  curIndex: number;
  setCurIndex: React.Dispatch<React.SetStateAction<number>>;
}
const CurEntryContext = createContext<CurEntryContextType>(
  {} as CurEntryContextType
);
const CurEntryApiContext = createContext<Dispatch<SetStateAction<number>>>(
  {} as Dispatch<SetStateAction<number>>
);
interface CurEntryContextComponentProps {
  children: ReactNode;
}
export const CurEntryContextComponent: React.FC<
  CurEntryContextComponentProps
> = ({ children }) => {
  const [curIndex, setCurIndex] = useState(0);
  const value = useMemo(() => {
    return { curIndex, setCurIndex };
  }, [curIndex]);
  return (
    <CurEntryApiContext.Provider value={setCurIndex}>
      <CurEntryContext.Provider value={value}>
        {children}
      </CurEntryContext.Provider>
    </CurEntryApiContext.Provider>
  );
};

export const useCurEntryContext = () => {
  return useContext(CurEntryContext);
};
export const useCurEntryApiContext = () => {
  return useContext(CurEntryApiContext);
};

interface CurEntryVisibilityWrapperProps {
  thisindex: number;
  children: ReactNode;
}

export const CurEntryVisibilityWrapper: React.FC<
  CurEntryVisibilityWrapperProps
> = ({ thisindex, children }) => {
  const { curIndex } = useContext(CurEntryContext);
  const visible = curIndex === thisindex;
  const childrenWithExtraProp = useMemo(
    () =>
      React.Children.map(children, (child) =>
        React.cloneElement(
          child as React.DetailedReactHTMLElement<any, HTMLElement>,
          {
            sx: { display: visible ? undefined : "none" },
          }
        )
      ),
    [children, visible]
  );
  return <>{visible && childrenWithExtraProp}</>;
};

export function withCurEntryVisibility<T>(Component: React.FC<T>) {
  return (props: any & { sx?: object; thisindex: number }) => {
    const { curIndex } = useContext(CurEntryContext);
    return (
      <Component
        {...props}
        sx={[
          {
            display: curIndex === props.thisindex ? undefined : "none",
          },
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(props.sx && Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      />
    );
  };
}

interface DirtyEinträgeContextType {
  dirtyIds: string[];
  setDirtyIds: React.Dispatch<React.SetStateAction<string[]>>;
}
const DirtyEinträgeContext = createContext<DirtyEinträgeContextType>(
  {} as DirtyEinträgeContextType
);
interface DirtyEinträgeContextComponentProps {
  children: ReactNode;
}
export const DirtyEinträgeContextComponent: React.FC<
  DirtyEinträgeContextComponentProps
> = ({ children }) => {
  const [dirtyIds, setDirtyIds] = useState<string[]>([]);

  const value = useMemo(() => {
    return { dirtyIds, setDirtyIds };
  }, [dirtyIds]);

  return (
    <DirtyEinträgeContext.Provider value={value}>
      {children}
    </DirtyEinträgeContext.Provider>
  );
};
export const useDirtyEinträgeContext = () => {
  return useContext(DirtyEinträgeContext);
};

interface DirtyEinträgeCheckerProps {
  eintragId?: string;
}
export const DirtyEinträgeChecker: React.FC<DirtyEinträgeCheckerProps> = ({
  eintragId,
}) => {
  const { isDirty } = useEditableViewContext();
  const { dirtyIds, setDirtyIds } = useDirtyEinträgeContext();
  useEffect(() => {
    if (eintragId) {
      if (isDirty) {
        // add to dirtyIds if not already there...
        if (dirtyIds.findIndex((e) => e === eintragId) === -1)
          setDirtyIds([...dirtyIds, eintragId]);
      } else {
        // remove id if existing
        const index = dirtyIds.findIndex((e) => e === eintragId);
        if (index !== -1) {
          const n = [...dirtyIds];
          n.splice(index, 1);
          setDirtyIds(n);
        }
      }
    }
  }, [dirtyIds, eintragId, isDirty, setDirtyIds]);
  return <></>;
};

interface GutachtenEintragListEntryProps {
  entry: GutachtenEintrag;
  index: number;
  setReallyDeleteIndex: (item: number) => void;
}
const GutachtenEintragListEntry: React.FC<GutachtenEintragListEntryProps> = ({
  entry,
  index,
  setReallyDeleteIndex,
}) => {
  const { dirtyIds } = useDirtyEinträgeContext();
  const { curIndex, setCurIndex } = useCurEntryContext();
  const isDirty = dirtyIds.findIndex((e) => e === entry._id) !== -1;
  return (
    <ListItem key={entry._id} disablePadding>
      <ListItemButton
        role={undefined}
        onClick={() => setCurIndex(index + 1)}
        dense
        divider
        selected={curIndex === index + 1}
      >
        <ListItemIcon>
          <GutachtenEntryIcon />
        </ListItemIcon>
        <ListItemText
          primary={`${isDirty ? "* " : ""}${entry.nachname}, ${entry.vorname}`}
          secondary={undefined}
          secondaryTypographyProps={{ sx: { fontSize: "x-small" } }}
        />
        <Stack direction="row">
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={() => setReallyDeleteIndex(index + 1)}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

const EigenschaftenListEntry: React.FC = () => {
  const { curIndex, setCurIndex } = useCurEntryContext();

  return (
    <ListItem disablePadding>
      <ListItemButton
        role={undefined}
        onClick={() => setCurIndex(0)}
        dense
        divider
        selected={curIndex === 0}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Eigenschaften" />
      </ListItemButton>
    </ListItem>
  );
};

const useReallyDelete = (dbname: string) => {
  const setCurIndex = useCurEntryApiContext();
  const deleteDocument = useDeleteDocument(dbname);
  return useCallback(
    (einträge: GutachtenEintrag[], reallyDeleteIndex: number) => {
      deleteDocument({
        _id: einträge[reallyDeleteIndex - 1]._id,
        _rev: einträge[reallyDeleteIndex - 1]._rev,
      });
      setCurIndex(0);
    },
    [deleteDocument, setCurIndex]
  );
};

interface ReallyDeleteDialogProps {
  reallyDeleteIndex: number;
  setReallyDeleteIndex: Dispatch<SetStateAction<number>>;
}
const ReallyDeleteDialog: React.FC<ReallyDeleteDialogProps> = ({
  reallyDeleteIndex,
  setReallyDeleteIndex,
}) => {
  const { dbname, einträge } = useGutachtenLoadingContext();
  const reallyDelete = useReallyDelete(dbname);

  return (
    <SimpleDialog
      open={reallyDeleteIndex !== -1}
      handleCancel={() => setReallyDeleteIndex(-1)}
      handleOK={() => {
        if (einträge) reallyDelete(einträge, reallyDeleteIndex);
        setReallyDeleteIndex(-1);
      }}
      title={
        reallyDeleteIndex > 0
          ? `Wollen Sie Das Gutachten von ${
              einträge && einträge[reallyDeleteIndex - 1].vorname
            } ${
              einträge && einträge[reallyDeleteIndex - 1].nachname
            } wirklich löschen?`
          : ""
      }
      abbruchText="Abbruch"
      okText="JA WIRKLICH LÖSCHEN!!"
    />
  );
};

interface AddListEntryProps {
  setShowAddDlg: Dispatch<SetStateAction<boolean>>;
  gutachtenId: string;
}
const AddListEntry: React.FC<AddListEntryProps> = ({
  setShowAddDlg,
  gutachtenId,
}) => {
  return (
    <>
      {gutachtenId && (
        <>
          <ListItemButton
            onClick={() => {
              setShowAddDlg(true);
            }}
          >
            <ListItemIcon>
              <AddGutachtenEintragIcon />
            </ListItemIcon>
            <ListItemText secondary="Neuer Eintrag" />
          </ListItemButton>
          <Divider />
        </>
      )}
    </>
  );
};

const AddListEntryWithProps = withContextSelector(
  AddListEntry,
  {
    gutachtenId: (ctx) =>
      ctx.gutachten && ctx.gutachten._id ? ctx.gutachten._id : undefined,
  },
  useGutachtenLoadingContext
);

interface StructureViewEinträgeListProps {
  setReallyDeleteIndex: Dispatch<SetStateAction<number>>;
  einträge: GutachtenEintrag[];
}
const StructureViewEinträgeList: React.FC<StructureViewEinträgeListProps> = ({
  setReallyDeleteIndex,
  einträge,
}) => {
  return (
    <>
      {!einträge && <CircularProgress />}
      {einträge &&
        einträge.map((entry: GutachtenEintrag, index) => {
          return (
            <GutachtenEintragListEntry
              key={entry._id}
              entry={entry}
              index={index}
              setReallyDeleteIndex={setReallyDeleteIndex}
            />
          );
        })}
    </>
  );
};

const StructureViewEinträgeListWithProps = withContextSelector(
  StructureViewEinträgeList,
  { einträge: (ctx) => ctx.einträge },
  useGutachtenLoadingContext
);

export const GutachtenStructureView: React.FC = () => {
  const [reallyDeleteIndex, setReallyDeleteIndex] = useState(-1);

  const [showAddDlg, setShowAddDlg] = useState(false);

  return (
    <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
      <GutachtenEintragHinzufügenDialogWithProps
        open={showAddDlg}
        handleClose={() => setShowAddDlg(false)}
        title={"Gutachten Hinzufügen"}
      />
      <ReallyDeleteDialog
        reallyDeleteIndex={reallyDeleteIndex}
        setReallyDeleteIndex={setReallyDeleteIndex}
      />
      <Typography variant="h6">Struktur:</Typography>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <EigenschaftenListEntry />
        <AddListEntryWithProps />
        <StructureViewEinträgeListWithProps
          setReallyDeleteIndex={setReallyDeleteIndex}
        />
      </List>
    </Paper>
  );
};

export default GutachtenStructureView;
