import WorkIcon from "@mui/icons-material/Work";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArticleIcon from "@mui/icons-material/Article";
import LinkIcon from "@mui/icons-material/Link";
import TransformIcon from "@mui/icons-material/Transform";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonIcon from "@mui/icons-material/Person";

export const AufgabeIcon: React.FC = () => {
  return <ArticleIcon />;
};

export const AufgabeLinkIcon: React.FC = () => {
  return <LinkIcon />;
};

export const KlausurIcon: React.FC = () => {
  return <WorkIcon />;
};

export const GutachtenIcon: React.FC = () => {
  return <GroupIcon />;
};

export const GutachtenEntryIcon: React.FC = () => {
  return <PersonIcon />;
};

export const AddAufgabeIcon: React.FC = () => {
  return <PostAddIcon />;
};

export const AddGutachtenEintragIcon: React.FC = () => {
  return <PersonAddAlt1Icon />;
};

export const XslTemplateIcon: React.FC = () => {
  return <TransformIcon />;
};
