import "./App.css";
import CouchDbContext, { useCouchDbContext } from "./lib/couchdb-mgr/CouchDbContext";
import Layout from "./components/Layout";

import MenuIcon from "@mui/icons-material/Menu";
import DBList from "./lib/couchdb-mgr/DBList";
import ServerAdminDlg from "./lib/couchdb-mgr/ServerAdminDlg";
import { useState } from "react";
import { Database } from "./lib/couchdb-mgr/HelperClasses";
import NewItemDialog from "./components/NewItemDialog";
import DbDirView from "./core/DbDirView";
import MainEditorView, { useMainEditorView } from "./core/views/MainEditorView";
import { Provider } from "react-redux";
import { store } from "./redux/store";

function AppContent() {
  const ctx = useCouchDbContext();
  const [showServerAdminDlg, setShowServerAdminDlg] = useState(false);
  const [newItemDb, setNewItemDb] = useState<Database | undefined>(undefined);
  const [selectedDb, setSelectedDb] = useState<Database | undefined>(undefined);

  const mainView = useMainEditorView();

  const handleOpen = (dbname: string, docid: string) => {
    mainView.open(dbname, docid);
  };

  return (
    <>
      {newItemDb && (
        <NewItemDialog open={newItemDb !== undefined} closeDialog={() => setNewItemDb(undefined)} db={newItemDb} />
      )}
      <ServerAdminDlg open={showServerAdminDlg} handleClose={() => setShowServerAdminDlg(false)} />
      {ctx.state.loggedIn && (
        <Layout
          sidesheetToggleIcon={<MenuIcon />}
          sidesheet={
            <>
              <DBList
                selectedDb={selectedDb}
                handleAddItem={(db) => setNewItemDb(db)}
                onSelectDatabase={(db) => setSelectedDb(db)}
              />
              {selectedDb && <DbDirView dbname={selectedDb.name} handleOpen={handleOpen} />}
            </>
          }
          moreVertMenuItems={[
            { title: "Server Admin", onClick: () => setShowServerAdminDlg(true) },
            { title: "Logout", onClick: () => ctx.logout() },
          ]}
        >
          <MainEditorView {...mainView} />
        </Layout>
      )}
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <CouchDbContext>
        <AppContent />
      </CouchDbContext>
    </Provider>
  );
}

export default App;
