import { FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { useEditModeContext } from "./EditableView";

interface EditableCheckboxProps {
  label: string;
  path: string;
}
export const EditableCheckbox: React.FC<EditableCheckboxProps> = ({ label, path }) => {
  const { control } = useFormContext();
  const { editModeEnabled } = useEditModeContext();

  return (
    <Controller
      control={control}
      name={path}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={<Checkbox checked={value} disabled={!editModeEnabled} onChange={onChange} />}
          label={label}
        />
      )}
    />
  );
};
