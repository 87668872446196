/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Accept, DropEvent, FileRejection, useDropzone } from "react-dropzone";

interface II {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
  accept?: Accept;
  children: React.ReactNode;
  editable?: boolean;
  noClick?: boolean;
}

/**
 *
 * @param {accept} accept: {
 *          'image/png': ['.png'],
 *          'text/html': ['.html', '.htm'],
 *    }
 * @param {onDrop} (acceptedFiles) => void
 * @returns
 */
// eslint-disable-next-line react/prop-types
const MyFileDropZone: React.FC<II> = ({ onDrop, accept, children, editable = true, noClick = false }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    noClick: noClick,
  });

  return (
    <>
      {editable && (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div
              style={{
                borderWidth: 3,
                borderStyle: "dashed",
                borderColor: "#ff0000",
                transition: "border .24s ease-in-out",
              }}
            >
              {children}
            </div>
          ) : (
            children
          )}
        </div>
      )}
      {!editable && <>{children}</>}
    </>
  );
};

export const loadFile = (file: File, setBytesRead?: (bytesRead: number) => void): Promise<ArrayBuffer> => {
  // Always return a Promise
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    // Wait till complete
    reader.onloadend = (e) => {
      if (e.target?.error) {
        throw e.target.error;
      }
      const content = e.target?.result as ArrayBuffer;
      return resolve(content);
    };
    // Make sure to handle error states
    reader.onerror = (e) => {
      return reject(e);
    };
    reader.onprogress = (e) => {
      // console.log(e);

      if (setBytesRead && e.lengthComputable) {
        setBytesRead(e.loaded);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export default MyFileDropZone;
