import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useCouchDbContext } from "./CouchDbContext";
import { useConnectServer } from "./HelperClasses";

/**
 * [chttpd]
 * admin_only_all_dbs = false
 *
 * [chttpd_auth]
 * public_fields = _id, name, type, role
 * require_valid_user = true
 * users_db_public = true
 */

interface LoginDialogProps {}
const LoginDialog: React.FC<LoginDialogProps> = () => {
  const { state, dispatch } = useCouchDbContext();

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const connectServer = useConnectServer(dispatch);

  const onOk = async () => {
    setErrorMessage(await connectServer(username, password));
  };

  useEffect(() => {
    if (state.loggedIn) {
      // when dialog closes
      setUsername("");
      setPassword("");
    }
  }, [state.loggedIn]);

  return (
    <>
      {!state.loggedIn && (
        <>
          <Dialog open={!state.loggedIn}>
            <DialogTitle>Verbinde mit Datenbank:</DialogTitle>
            <DialogContent>
              <DialogContentText>{errorMessage}</DialogContentText>
              {state.inProgress && <CircularProgress />}
              {!state.inProgress && (
                <>
                  <Stack>
                    <>
                      <TextField
                        name="username"
                        label="Benutzername"
                        inputRef={usernameRef}
                        value={username}
                        variant="standard"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          setUsername(e.target.value)
                        }
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            // Do code here
                            if (passwordRef.current) passwordRef.current.focus();
                            ev.preventDefault();
                          }
                        }}
                      />
                      <TextField
                        name="password"
                        label="Password"
                        inputRef={passwordRef}
                        type="password"
                        value={password}
                        variant="standard"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                          setPassword(e.target.value)
                        }
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            // Do code here
                            onOk();
                            ev.preventDefault();
                          }
                        }}
                      />
                    </>
                  </Stack>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                disabled={username.length === 0 || state.inProgress}
                onClick={() => {
                  onOk();
                }}
              >
                Connect
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default LoginDialog;
