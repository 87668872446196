import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../hooks";

interface IState {
  lastKlausur: { dbname: string; docid: string; type: string } | undefined;
}

const initialState: IState = { lastKlausur: undefined };

export const xslEditorSlice = createSlice({
  name: "settings",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLastKlausur: (state, action: PayloadAction<{ dbname: string; docid: string; type: string } | undefined>) => {
      state = { ...state, lastKlausur: action.payload };
      return state;
    },
  },
});

// actions, use: appDispatch(setCredentials(...));
export const xslEditorActions = xslEditorSlice.actions;
export const xslEditorReducer = xslEditorSlice.reducer;

// Selectors (use with useSelector())
export const selectXslEditorSettings = (state: RootState) => state.xslEditorSettings;
