import { Button, IconButton, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import WysiwigEditorCK5 from "../wysiwigeditor/WysiwigEditorCK5";
import { ContentObjectText, ContentObjectImage, ContentTypes } from "../Aufgabe";
import ImageEditorComponent from "./ImageEditorComponent";
import { useEditModeContext, useEditableViewContext } from "../../lib/EditableView";
const { v4: uuidv4 } = require("uuid");

/* function MyDivider() {
  return (
    <Divider
      sx={{
        width: '100%',
        p: 1,
        borderBottomWidth: 5,
        borderBottomStyle: 'dashed',
      }}
    />
  );
} */

const AddUpDownDeleteButton: React.FC<{
  children: React.ReactNode;
  removeFct: (index: number) => void;
  moveFct: (from: number, to: number) => void;
  index: number;
  listLength: number;
  editEnabled: boolean;
}> = ({ children, removeFct, moveFct, index, listLength, editEnabled }) => {
  return (
    <>
      <Stack direction="row" sx={{ width: "100%" }}>
        {children}
        {editEnabled && (
          <Stack>
            <IconButton
              onClick={() => {
                if (index > 0) moveFct(index, index - 1);
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                if (index < listLength - 1) moveFct(index, index + 1);
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton onClick={() => removeFct(index)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </>
  );
};

const ContentArrayEditor: React.FC<{
  path: string;
  title?: string;
}> = ({ path, title }) => {
  const { editModeEnabled } = useEditModeContext();
  const { control } = useFormContext();

  const contentArray = useFieldArray({
    name: path,
    control,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const createNewElement = (type: string) => {
    handleCloseMenu();
    switch (type) {
      case "text":
        contentArray.append({
          type: "text",
          text: "",
        } as ContentObjectText);
        break;
      case "image":
        contentArray.append({
          type: "image",
          uuid: uuidv4(),
          bildunterschrift: "",
          quellenangabe: "",
          data: { uuid: undefined, type: undefined, filename: undefined },
          rotation: 0,
          width: 0.5,
          widthUnit: "\\textwidth",
          alsAnhang: false,
        } as ContentObjectImage);
        break;
      default:
        throw new Error(`Unknown Type:  ${type}`);
    }
  };

  return (
    <Paper variant="outlined" square sx={{ padding: 1 }}>
      <Stack>
        {title && <Typography variant="subtitle1">{title}</Typography>}
        {contentArray.fields.map((field, index) => {
          const c = field as unknown as ContentTypes;
          switch (c.type) {
            case "text":
              return (
                <AddUpDownDeleteButton
                  key={field.id}
                  removeFct={contentArray.remove}
                  moveFct={contentArray.move}
                  index={index}
                  listLength={contentArray.fields.length}
                  editEnabled={editModeEnabled}
                >
                  <Stack sx={{ width: "100%", pb: 1 }}>
                    <WysiwigEditorCK5 disabled={!editModeEnabled} fieldName={`${path}[${index}].text`} />
                  </Stack>
                </AddUpDownDeleteButton>
              );
            case "image":
              return (
                <AddUpDownDeleteButton
                  key={field.id}
                  removeFct={contentArray.remove}
                  moveFct={contentArray.move}
                  index={index}
                  listLength={contentArray.fields.length}
                  editEnabled={editModeEnabled}
                >
                  <Stack sx={{ width: "100%", pb: 1 }}>
                    <ImageEditorComponent path={`${path}[${index}].`} />
                  </Stack>
                </AddUpDownDeleteButton>
              );
            default:
              throw new Error("Unknown content type (see message before!!)");
          }
        })}
        {editModeEnabled && (
          <Button variant="outlined" onClick={handleOpenMenu}>
            Inhalt hinzufügen
          </Button>
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => createNewElement("text")}>Inhalts-Element: Text</MenuItem>
          <MenuItem onClick={() => createNewElement("image")}>Inhalts-Element: Bild</MenuItem>
        </Menu>
      </Stack>
    </Paper>
  );
};

ContentArrayEditor.defaultProps = {
  title: undefined,
};

export default ContentArrayEditor;
