import { Grid } from "@mui/material";
import { useState } from "react";
import NewItemDialog from "../../components/NewItemDialog";
import DBList from "../../lib/couchdb-mgr/DBList";
import { Database } from "../../lib/couchdb-mgr/HelperClasses";
import { DocumentTypes } from "../BaseObject";
import DbDirView from "../DbDirView";

interface DocumentSelectorComponentProps {
  allowNewDocument: boolean;
  handleSelect: (dbname: string, docid: string, type: DocumentTypes) => void;
  showFileTypes?: number;
}
const DocumentSelectorComponent: React.FC<DocumentSelectorComponentProps> = ({
  allowNewDocument,
  handleSelect,
  showFileTypes,
}) => {
  const [selectedDb, setSelectedDb] = useState<Database | undefined>(undefined);
  const [newItemDb, setNewItemDb] = useState<Database | undefined>(undefined);
  const [selectedDoc, setSelectedDoc] = useState<string | undefined>();
  return (
    <>
      {newItemDb && (
        <NewItemDialog open={newItemDb !== undefined} closeDialog={() => setNewItemDb(undefined)} db={newItemDb} />
      )}
      <Grid container>
        <Grid item xs={6}>
          <DBList
            selectedDb={selectedDb}
            handleAddItem={allowNewDocument ? (db) => setNewItemDb(db) : undefined}
            onSelectDatabase={(db) => {
              // hier gibt es onhe try manchmal seltsame Abstürze... mit try verschwinden die?!?
              try {
                setSelectedDb(db);
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {selectedDb && (
            <DbDirView
              selectedDocId={selectedDoc}
              dbname={selectedDb.name}
              handleOpen={(dbname: string, docid: string, type: DocumentTypes) => {
                handleSelect(dbname, docid, type);
                setSelectedDoc(docid);
              }}
              showFileTypes={showFileTypes}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentSelectorComponent;
