import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { Gutachten, useSaveGutachten } from "../../Gutachten";
import { useCallback, useEffect } from "react";
import {
  EditableView,
  useEditableViewContext,
} from "../../../lib/EditableView";
import EditableTextField from "../../../lib/EditableTextField";
import GutachtenStructureView, {
  CurEntryContextComponent,
  CurEntryVisibilityWrapper,
  DirtyEinträgeContextComponent,
  useDirtyEinträgeContext,
  withCurEntryVisibility,
} from "./GutachtenStructureView";
import GutachtenBlueprintEditor from "./GutachtenBlueprintEditor";
import GutachtenEintragView from "./GutachtenEintragView";
import React from "react";
import { useMainViewIsDirtyContext } from "../../MainViewIsDirtyContext";
import { withContextSelector } from "../../../lib/WithContextSelector";
import {
  useGutachtenLoadingContext,
  GutachtenLoadingContextComponent,
} from "./GutachtenLoadingContext";

const GridWithVisibility = withCurEntryVisibility(Grid);

interface GutachtenSettingsViewProps {
  dbname: string;
  gutachten: Gutachten;
}
const GutachtenSettingsView: React.FC<GutachtenSettingsViewProps> = ({
  dbname,
  gutachten,
}) => {
  const saveGutachten = useSaveGutachten(dbname);

  const saveFunction = useCallback(
    async (gutachtenNeuRaw: any) => {
      if (gutachtenNeuRaw) await saveGutachten(gutachtenNeuRaw);
      else throw new Error("INTERNAL ERROR!");
    },
    [saveGutachten]
  );

  return (
    <>
      {!gutachten && <CircularProgress />}
      {gutachten && (
        <EditableView
          value={gutachten}
          dbname={dbname}
          saveFunction={saveFunction}
        >
          <MainViewSpecialHandleDirtyChecker />
          <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Gutachten</Typography>
              </Grid>
              <Grid item xs={12}>
                <EditableTextField
                  fieldName="name"
                  label="Name des Gutachtens"
                />
              </Grid>

              <Grid item xs={6}>
                <EditableTextField
                  fieldName="schule"
                  label="Schule"
                  placeholder="z.B. Heinrich Büssing Schule"
                />
              </Grid>
              <Grid item xs={6}>
                <EditableTextField
                  fieldName="schulform"
                  label="Schulform"
                  placeholder="z.B. Berufliches Gymnasium"
                />
              </Grid>
              <Grid item xs={6}>
                <EditableTextField
                  fieldName="fach"
                  label="Fach"
                  placeholder="z.B. Technik"
                />
              </Grid>
              <Grid item xs={6}>
                <EditableTextField
                  fieldName="schwerpunkt"
                  label="Schwerpunkt"
                  placeholder="z.B. Mechatronik"
                />
              </Grid>
              <Grid item xs={6}>
                <EditableTextField
                  fieldName="art"
                  label="Art der Klausur"
                  placeholder="z.B. Abitur 2022"
                />
              </Grid>

              <Grid item xs={6}>
                <EditableTextField
                  fieldName="vorschlag"
                  label="Vorschlag"
                  placeholder="z.B. Vorschlag A"
                />
              </Grid>
            </Grid>
          </Paper>
          <GutachtenBlueprintEditor path="blueprint" />
        </EditableView>
      )}
    </>
  );
};

const GutachtenSettingsViewWithProps = withContextSelector(
  GutachtenSettingsView,
  { dbname: (ctx) => ctx.dbname, gutachten: (ctx) => ctx.gutachten },
  useGutachtenLoadingContext
);

const MainViewSpecialHandleDirtyChecker: React.FC = () => {
  const ctx = useEditableViewContext();

  const { setIsDirty } = useMainViewIsDirtyContext();

  const { dirtyIds } = useDirtyEinträgeContext();

  useEffect(() => {
    setIsDirty(ctx.isDirty || dirtyIds.length > 0);
  }, [ctx.isDirty, setIsDirty, dirtyIds]);

  return <></>;
};

const GutachtenEditorListView: React.FC = () => {
  const { dbname, einträge } = useGutachtenLoadingContext();
  return (
    <>
      {einträge &&
        einträge.map((eintrag, index) => {
          return (
            <CurEntryVisibilityWrapper
              thisindex={index + 1}
              key={eintrag.nachname + eintrag.vorname}
            >
              <GutachtenEintragView dbname={dbname} eintrag={eintrag} />
            </CurEntryVisibilityWrapper>
          );
        })}
    </>
  );
};

const GutachtenEditorImpl: React.FC = () => {
  return (
    <DirtyEinträgeContextComponent>
      <CurEntryContextComponent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <GutachtenStructureView />
          </Grid>
          <GridWithVisibility thisindex={0} item xs={9}>
            <GutachtenSettingsViewWithProps />
          </GridWithVisibility>
          <GutachtenEditorListView />
        </Grid>
      </CurEntryContextComponent>
    </DirtyEinträgeContextComponent>
  );
};

interface GutachtenEditorLoadingViewProps {
  loading: boolean;
  errormessage?: string;
}
const GutachtenEditorLoadingView: React.FC<GutachtenEditorLoadingViewProps> = ({
  loading,
  errormessage,
}) => {
  return (
    <>
      {loading && <CircularProgress />}
      {!loading && errormessage !== undefined && (
        <Typography>{errormessage}</Typography>
      )}
      {!loading && !errormessage && <GutachtenEditorImpl />}
    </>
  );
};

const GutachtenEditorLoadingViewWithProps = withContextSelector(
  GutachtenEditorLoadingView,
  { loading: (ctx) => ctx.loading, errormessage: (ctx) => ctx.errormessage },
  useGutachtenLoadingContext
);

interface GutachtenEditorProps {
  dbname: string;
  docid: string;
}

const GutachtenEditor: React.FC<GutachtenEditorProps> = React.memo(
  ({ dbname, docid }) => {
    return (
      <GutachtenLoadingContextComponent dbname={dbname} docid={docid}>
        <GutachtenEditorLoadingViewWithProps />
      </GutachtenLoadingContextComponent>
    );
  }
);

export default GutachtenEditor;
