import { useState, useEffect, useCallback } from "react";
import { useDbList } from "../lib/couchdb-mgr/CouchDbContext";
import { DatabaseList } from "../lib/couchdb-mgr/HelperClasses";
import { ContentObjectText, ContentTypes, AufgabeDefinition } from "./Aufgabe";
import { useGenericDocument } from "./BaseObject";
import { KlausurAufgabeReference, Klausur, KlausurAufgabe } from "./Klausur";
import { pandoc } from "./pandoc";
import json2xml from "./json2xml";
import { DocumentAttachment, useGetAttachmentFromObject } from "../lib/AttachmentManager";

async function transformContentArray(contentArray: ContentTypes[]) {
  // eslint-disable-next-line no-plusplus

  return Promise.all(
    contentArray.map(async (obj: ContentTypes) => {
      if (obj.type && obj.type === "text") {
        const pandoced = await pandoc((obj as ContentObjectText).text);

        const newObj = { ...obj } as ContentObjectText;
        newObj.text = pandoced.replaceAll("section{", "section*{");
        return { contentObject: newObj };
      }
      return { contentObject: obj };
    })
  );
}

async function transformAufgabeDefinition(definition: AufgabeDefinition) {
  // contentArray
  (definition.contentArray as any) = await transformContentArray(definition.contentArray);

  // lösung
  (definition.lösung.contentArray as any) = await transformContentArray(definition.lösung.contentArray);

  // recurse: teilaufgaben
  for (let i = 0; i < definition.teilaufgaben.length; ++i) {
    (definition.teilaufgaben[i] as any) = {
      teilaufgabe: { definition: await transformAufgabeDefinition(definition.teilaufgaben[i]) },
    };
  }

  // Der "Punkte-Text" muss ebenfalls gepandoced werden...
  (definition.lösung.punkte as any) = await Promise.all(
    definition.lösung.punkte.map(async (punkt) => {
      return { punkt: { ...punkt, text: await pandoc(punkt.text) } };
    })
  );

  return definition;
}

async function aufgabeToLatex(
  dbs: DatabaseList,
  data: KlausurAufgabe | KlausurAufgabeReference,
  attachments: DocumentAttachment[],
  getAttachment: (obj: any, db: PouchDB.Database<{}>, attachmentName: string) => Promise<Blob>
) {
  let aufgabe = data as any;
  if (data.type === "reference") {
    // first ressolve reference
    const ref = data as KlausurAufgabeReference;
    const db = dbs.byName(ref.dbname);
    if (!db) throw new Error(`DB not existing: ${ref.dbname}`);
    const doc = await db.db.get(ref.docid);
    aufgabe = doc;

    const keys = Object.keys((aufgabe as any)._attachments);
    for (let i = 0; i < keys.length; ++i) {
      const a = await getAttachment(aufgabe as any, dbs.byName(ref.dbname)?.db as PouchDB.Database, keys[i]);
      attachments.push({ name: keys[i], data: a });
    }
  }

  delete aufgabe._attachments;
  delete aufgabe._id;
  delete aufgabe._rev;
  delete aufgabe.metainfo;

  //aufgabe.metainfo.description = await transformContentArray(aufgabe.metainfo.description);
  //aufgabe.metainfo.tags = aufgabe.metainfo.tags.join([","]);
  await transformAufgabeDefinition(aufgabe.definition);
  return { aufgabe };
}

export async function klausur2Xml(
  dbs: DatabaseList,
  klausur: Klausur,
  dbname: string,
  getAttachment: (obj: any, db: PouchDB.Database<{}>, attachmentName: string) => Promise<Blob>
): Promise<{ xml: string; attachments: DocumentAttachment[] }> {
  // work on a deep copy
  const klausurCopy = JSON.parse(JSON.stringify(klausur)) as Klausur;
  const attachments: DocumentAttachment[] = [];

  if (klausurCopy) {
    // first extract attachments from klausur
    if ((klausur as any)._attachments) {
      const keys = Object.keys((klausur as any)._attachments);
      for (let i = 0; i < keys.length; ++i) {
        const a = await getAttachment(klausur as any, dbs.byName(dbname)?.db as PouchDB.Database, keys[i]);
        attachments.push({ name: keys[i], data: a });
      }
    }

    // remove attachments
    delete (klausurCopy as any)._attachments;
    delete (klausurCopy as any)._id;
    delete (klausurCopy as any)._rev;

    // 1. check all ContentTypeText and transform html to latex

    // KlausurMetaInfo
    (klausurCopy.metainfo.hilfsmittel.contentArray as any) = await transformContentArray(
      klausurCopy.metainfo.hilfsmittel.contentArray
    );
    (klausurCopy.metainfo.einleitungPrüferExemplar.contentArray as any) = await transformContentArray(
      klausurCopy.metainfo.einleitungPrüferExemplar.contentArray
    );
    (klausurCopy.metainfo.einleitungSchülerExemplar.contentArray as any) = await transformContentArray(
      klausurCopy.metainfo.einleitungSchülerExemplar.contentArray
    );

    // Aufgaben
    (klausurCopy.aufgaben as any) = await Promise.all(
      klausurCopy.aufgaben.map(async (aufgabe) => {
        return await aufgabeToLatex(dbs, aufgabe, attachments, getAttachment);
      })
    );

    // 2. transform JSON to XML
    const xmlString = json2xml({ klausur: klausurCopy }, { header: true });
    return { xml: xmlString, attachments };
  }

  throw new Error("INTERNAL ERROR!");
}

export interface UseKlausur2XmlReturnType {
  xml?: string;
  attachments: DocumentAttachment[];
  loading: boolean;
  error?: string;
}
interface useKlausur2XmlProps {
  dbname: string;
  docid: string;
}
export const useKlausur2Xml = (props: useKlausur2XmlProps | undefined) => {
  const dbs = useDbList();
  const doc = useGenericDocument(props ? props.dbname : "_default", props ? props.docid : "", Klausur.plainToClass);
  const getAttachment = useGetAttachmentFromObject();

  const [ret, setRet] = useState<UseKlausur2XmlReturnType>({ loading: true, attachments: [] });

  const forceUpdate = useCallback(() => {
    if (props) {
      setRet({ loading: true, attachments: [] });
      if (dbs && doc.doc)
        klausur2Xml(dbs, doc.doc, props.dbname, getAttachment)
          .then((res) => {
            setRet({ xml: res.xml, attachments: res.attachments, loading: false });
          })
          .catch((err) => {
            setRet({ loading: false, error: err, attachments: [] });
            console.log(err);
          });
    } else {
      setRet({ loading: false, attachments: [] });
    }
  }, [props, dbs, doc.doc, getAttachment]);

  useEffect(() => {
    if (doc.loading) {
      setRet({ loading: true, attachments: [] });
    } else if (doc.error || !doc.doc) {
      setRet({ loading: false, error: doc.error, attachments: [] });
    } else {
      forceUpdate();
    }
  }, [doc.loading, doc.error, doc.doc, forceUpdate]);

  return ret;
};
