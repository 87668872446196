import { Button, Typography } from "@mui/material";
import CodeViewer from "../../../lib/CodeViewer";
import useClipboard from "../../../lib/useClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useXsltResultViewerContext } from "./XslTemplateView";

interface XslTransformationResultViewProps {}

const XslTransformationResultView: React.FC<XslTransformationResultViewProps> = () => {
  const clipboard = useClipboard();

  const { latex } = useXsltResultViewerContext();

  return (
    <div style={{ position: "relative", maxHeight: "100%", height: "100%" }}>
      <Button
        variant="contained"
        startIcon={<ContentCopyIcon />}
        onClick={() => {
          if (latex) clipboard.writeText(latex);
        }}
        sx={{ zIndex: 10, margin: 0, top: 20, right: 20, left: "auto", position: "absolute" }}
      />
      <div
        style={{
          maxHeight: "100%",
          overflow: "auto",
          position: "absolute",
          margin: 0,
          top: 0,
          left: 0,
          right: "auto",
          maxWidth: "100%",
        }}
      >
        {latex && <CodeViewer data={latex as string} language="markup" />}
        {!latex && <Typography>No Result yet.</Typography>}
      </div>
    </div>
  );
};

export default XslTransformationResultView;
