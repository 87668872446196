import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";

import { v4 as uuidv4 } from "uuid";

import { useCouchDbContext } from "./CouchDbContext";
import { useState } from "react";
import { Database, dbBaseUrl, useUpdateDbsList } from "./HelperClasses";
import { DbListItem } from "./DBList";
import UserRightsDialog from "./UserRightsDialog";
import { useAllUserList } from "./useAllUserList";
import ChangePasswordDlg from "./ChangePasswordDlg";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function stringToValidDatabaseName(s: string) {
  // Only lowercase characters (a-z), digits (0-9), and any of the
  // characters _, $, (, ), +, -, and / are allowed. Must begin with a letter.
  const n = s.toLowerCase();
  let n2 = "";
  //const regex = /[a-z0-9\_\$\,\+\-\/]/;
  const regex = /[a-z0-9_$,+\-/]/;
  for (let i = 0; i < n.length; i += 1) {
    if (n.charAt(i).match(regex)) n2 += n.charAt(i);
  }

  if (!n2.charAt(0).match(/[a-z]/)) n2 = "a" + n2;

  return n2;
}

interface ReallyDeleteUserDlgProps {
  open: boolean;
  handleCancel: () => void;
  handleReallyDelete: () => void;
  name: string;
}
const ReallyDeleteUserDlg: React.FC<ReallyDeleteUserDlgProps> = ({ open, handleCancel, handleReallyDelete, name }) => {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Wollen Sie den Benutzer {name} wirklich löschen?</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleReallyDelete}>Ja, wirklich löschen!</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ReallyDeleteDatabaseDlgProps {
  open: boolean;
  handleCancel: () => void;
  handleReallyDelete: () => void;
  name?: string;
}
const ReallyDeleteDatabaseDlg: React.FC<ReallyDeleteDatabaseDlgProps> = ({
  open,
  handleCancel,
  handleReallyDelete,
  name,
}) => {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Wollen Sie die Datenbank {name} wirklich löschen?</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleReallyDelete}>Ja, wirklich löschen!</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ServerAdminDlgProps {
  open: boolean;
  handleClose: () => void;
}
const ServerAdminDlg: React.FC<ServerAdminDlgProps> = ({ open, handleClose }) => {
  const ctx = useCouchDbContext();
  const updateDbsList = useUpdateDbsList(ctx.dispatch);
  const allUsers = useAllUserList();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [addDatabaseName, setAddDatabaseName] = useState("");

  const [dbToDelete, setDbToDelete] = useState<Database | undefined>(undefined);
  const deleteDatabase = async (db: Database | undefined) => {
    if (db) {
      const request = await fetch(db.dbUrl().toString(), { method: "DELETE" });
      if (request.status !== 200) {
        const result = await request.json();
        console.log(request, result);
      }
      updateDbsList(ctx.state.username as string, ctx.state.userRoles as string[]);
    }
    setDbToDelete(undefined);
  };

  const [editUserRightsDb, setEditUserRightsDb] = useState<Database | undefined>(undefined);

  const [addUsername, setAddUsername] = useState("");
  const [addUserPassword, setAddUserPassword] = useState("");

  const [userToBeDeleted, setUserToBeDeleted] = useState<any>(undefined);
  const deleteUser = async (user: any) => {
    console.log(user);
    const userUrl = new URL(`./_users/${user._id}`, dbBaseUrl);
    const request = await fetch(userUrl.toString(), {
      method: "DELETE",
      headers: {
        "If-Match": user._rev,
      },
    });

    if (request.status !== 200) {
      const result = await request.json();
      console.log(request, result);
    }
    updateDbsList(ctx.state.username as string, ctx.state.userRoles as string[]);
    setUserToBeDeleted(undefined);
  };

  const [userForChangePassword, setUserForChangePassword] = useState<any>(undefined);

  return (
    <>
      <ChangePasswordDlg
        user={userForChangePassword}
        open={userForChangePassword !== undefined}
        handleClose={() => setUserForChangePassword(undefined)}
      />
      <ReallyDeleteDatabaseDlg
        open={dbToDelete !== undefined}
        handleCancel={() => setDbToDelete(undefined)}
        handleReallyDelete={() => deleteDatabase(dbToDelete)}
        name={dbToDelete?.getReadableName()}
      />
      <ReallyDeleteUserDlg
        open={userToBeDeleted !== undefined}
        handleCancel={() => setUserToBeDeleted(undefined)}
        handleReallyDelete={() => {
          console.log(userToBeDeleted);
          if (userToBeDeleted) deleteUser(userToBeDeleted);
        }}
        name={userToBeDeleted ? userToBeDeleted._id : ""}
      />
      <UserRightsDialog
        db={editUserRightsDb}
        open={editUserRightsDb !== undefined}
        handleClose={() => setEditUserRightsDb(undefined)}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Server Administration</DialogTitle>
        <DialogContent>
          <DialogContentText>angemeldet als: {ctx.state.username}</DialogContentText>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Datenbanken" />
              <Tab label="Benutzer" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Card>
              <CardContent>
                <Typography variant="h6">Datenbanken:</Typography>
                <List>
                  {ctx.state.dbs?.dbs.map((db) => {
                    return (
                      <DbListItem
                        key={db.name}
                        db={db}
                        primaryAction={() => setEditUserRightsDb(db)}
                        secondaryAction={
                          !db.isUserDb &&
                          !db.isMyUserDb &&
                          db.isAdmin(ctx.state.username as string, ctx.state.userRoles as string[]) && (
                            <IconButton onClick={() => setDbToDelete(db)} edge="end" aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          )
                        }
                      />
                    );
                  })}
                </List>
                <Typography variant="h6">Neue Datenbank:</Typography>
                <Stack direction="row">
                  <TextField value={addDatabaseName} onChange={(e) => setAddDatabaseName(e.target.value)} />
                  <IconButton
                    onClick={async () => {
                      const validDbName = stringToValidDatabaseName(addDatabaseName) + "_" + uuidv4().toString();

                      const newDbUrl = new URL(`./${validDbName}`, dbBaseUrl);
                      const request = await fetch(newDbUrl.toString(), { method: "PUT" });

                      if (request.status === 201) {
                        const metaDocUrl = new URL(`./${validDbName}/meta_info`, dbBaseUrl);
                        const request = await fetch(metaDocUrl.toString(), {
                          method: "PUT",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            readable_db_name: addDatabaseName,
                          }),
                        });
                        if (request.status !== 201) {
                          const result = await request.json();
                          console.log(request, result);
                        }
                      } else {
                        const result = await request.json();
                        console.log(request, result);
                      }

                      setAddDatabaseName("");
                      updateDbsList(ctx.state.username as string, ctx.state.userRoles as string[]);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Card>
              <CardContent>
                <Typography variant="h6">Benutzer:</Typography>
                <List>
                  {allUsers.map((user) => {
                    return (
                      <ListItem
                        key={user._id}
                        secondaryAction={
                          <IconButton onClick={() => setUserToBeDeleted(user)} edge="end" aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton onClick={() => setUserForChangePassword(user)}>
                          <ListItemAvatar>
                            <Avatar>
                              <PersonIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={user.name} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
                <Typography variant="h6">Neuer Benutzer:</Typography>
                <Stack direction="row">
                  <TextField
                    label="Benutzername"
                    value={addUsername}
                    onChange={(e) => setAddUsername(e.target.value)}
                  />
                  <TextField
                    label="Password"
                    value={addUserPassword}
                    onChange={(e) => setAddUserPassword(e.target.value)}
                  />
                  <IconButton
                    onClick={async () => {
                      const _id = `org.couchdb.user:${addUsername}`;
                      const newUserUrl = new URL(`./_users/${_id}`, dbBaseUrl);
                      const request = await fetch(newUserUrl.toString(), {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          _id: _id,
                          name: addUsername,
                          type: "user",
                          roles: [],
                          password: addUserPassword,
                        }),
                      });
                      if (request.status !== 201) {
                        const result = await request.json();
                        console.log(request, result);
                      }

                      setAddUsername("");
                      setAddUserPassword("");
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServerAdminDlg;
