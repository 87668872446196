import { useCallback } from "react";
import { IAction } from "./CouchDbContext";
import PouchDB from "pouchdb-browser";
import checkMigrations from "./checkMigrations";

export const dbBaseUrl: URL = new URL("/db/", window.location.href);

function getUserDatabaseName(name: string, prefix: string = "userdb-") {
  const encoder = new TextEncoder();
  const buffy = encoder.encode(name);
  const bytes = Array.from(buffy).map((byte) => byte.toString(16).padStart(2, "0"));
  return prefix + bytes.join("");
}

export interface SecurityObject {
  admins: { names: string[]; roles: string[] };
  members: { names: string[]; roles: string[] };
}

export class Database {
  name: string;
  isMyUserDb: boolean;
  isUserDb: string | undefined;
  readableName: string | undefined;
  securityObject: SecurityObject | undefined;
  db: PouchDB.Database;

  public constructor(name: string, username: string, usernameList: string[]) {
    this.name = name;
    this.securityObject = undefined;

    this.db = new PouchDB(this.dbUrl().toString());
    if (getUserDatabaseName(username) === name) {
      this.isMyUserDb = true;
    } else this.isMyUserDb = false;
    this.isUserDb = undefined;
    for (let i = 0; i < usernameList.length; i += 1) {
      if (getUserDatabaseName(usernameList[i]) === name) this.isUserDb = usernameList[i];
    }
  }

  public getReadableName() {
    if (this.isMyUserDb) return "My Space";
    if (this.isUserDb) return this.isUserDb;
    if (this.readableName) return this.readableName;
    return this.name;
  }

  public dbUrl() {
    return new URL(`./${this.name}`, dbBaseUrl);
  }

  public async saveSecurity(secObj: SecurityObject) {
    const securityUrl = new URL(`./${this.name}/_security`, dbBaseUrl);
    const request = await fetch(securityUrl.toString(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(secObj),
    });
    if (request.status === 200) {
      this.securityObject = secObj;
    } else {
      const result = await request.json();
      console.log(request, result);
    }
  }

  public async fetchSecurityObject() {
    // first check migrations:
    await checkMigrations(this.db);

    // then load security object
    const securityUrl = new URL(`./${this.name}/_security`, dbBaseUrl);
    const request = await fetch(securityUrl.toString());
    if (request.status === 200) {
      const security = await request.json();
      this.securityObject = security;
    } else {
      // const error = await request.json();
      this.securityObject = undefined;
    }

    // also query meta information...
    if (!this.isMyUserDb && !this.isUserDb) {
      const metaDocUrl = new URL(`./${this.name}/meta_info`, dbBaseUrl);
      const request = await fetch(metaDocUrl.toString(), {
        method: "GET",
      });
      if (request.status === 200) {
        const result = await request.json();
        this.readableName = result.readable_db_name;
      } else {
        //const result = await request.json();
        //console.log(request, result);
      }
    }
  }

  public isValidFor(username: string, roles: string[]) {
    if (!this.securityObject) return false;

    // check if username is listed
    if (
      (this.securityObject.admins &&
        this.securityObject.admins.names &&
        this.securityObject.admins.names.includes(username)) ||
      (this.securityObject.members &&
        this.securityObject.members.names &&
        this.securityObject.members.names.includes(username))
    )
      return true;

    // check if role is valid
    if (
      this.securityObject.admins &&
      this.securityObject.admins.roles &&
      this.securityObject.admins.roles.some((role) => {
        for (let i = 0; i < roles.length; i += 1) {
          if (role === roles[i]) return true;
        }
        return false;
      })
    ) {
      return true;
    }
    if (
      this.securityObject.members &&
      this.securityObject.members.roles &&
      this.securityObject.members.roles.some((role) => {
        for (let i = 0; i < roles.length; i += 1) {
          if (role === roles[i]) return true;
        }
        return false;
      })
    ) {
      return true;
    }

    return false;
  }

  public isAdmin(username: string, userroles: string[]): boolean {
    if (!this.securityObject) return false;

    if (this.securityObject.admins.names && this.securityObject.admins.names.includes(username)) return true;
    if (this.securityObject.admins.roles)
      for (let i = 0; i < userroles.length; i += 1) {
        if (this.securityObject.admins.roles.includes(userroles[i])) return true;
      }
    return false;
  }
}

export class DatabaseList {
  dbs: Database[];

  public constructor() {
    this.dbs = [];
  }

  public async loadList(username: string, usernameList: string[]) {
    const allDbUrl = new URL("./_all_dbs", dbBaseUrl);
    const request = await (await fetch(allDbUrl.toString())).json();
    this.dbs = request
      .filter((name: string) => {
        if (name !== "_global_changes" && name !== "_users" && name !== "_replicator") return true;
        return false;
      })
      .map((name: string) => new Database(name, username, usernameList));

    await this.loadAllSecurityObjects();
  }

  private async loadAllSecurityObjects() {
    await Promise.all(
      this.dbs.map(async (db) => {
        await db.fetchSecurityObject();
      })
    );
  }

  public filterForUser(username: string, roles: string[]): DatabaseList {
    const newList = new DatabaseList();
    newList.dbs = this.dbs
      .filter((db) => db.isValidFor(username, roles))
      .sort((a, b) => {
        if (b.isMyUserDb) return 1;
        if (b.isUserDb) return 1;
        return a.getReadableName().localeCompare(b.getReadableName());
      });
    return newList;
  }

  public dbsListObjectForProvider() {
    let dbList = {} as any;
    this.dbs.forEach((db) => {
      dbList[db.name] = db.db;
    });
    return dbList;
  }

  public byName(name: string) {
    for (let i = 0; i < this.dbs.length; i += 1) {
      if (this.dbs[i].name === name) return this.dbs[i];
    }
    return undefined;
  }

  public hasDb(name: string) {
    for (let i = 0; i < this.dbs.length; i += 1) {
      if (this.dbs[i].name === name) return true;
    }
    return false;
  }
}

const sessionUrl = new URL("./_session", dbBaseUrl);

export const useLogout = (dispatch: React.Dispatch<IAction>) => {
  return useCallback(async () => {
    const request = await fetch(sessionUrl.toString(), {
      method: "DELETE",
      credentials: "include",
    });
    const response = await request.json();
    if (response.ok) {
      dispatch({
        type: "setLoginData",
        payload: {
          loggedIn: false,
          username: "",
          password: "",
          userRoles: [],
        },
      });
      dispatch({
        type: "setDatabaseList",
        payload: new DatabaseList(),
      });
    }
  }, [dispatch]);
};

export const useUpdateDbsList = (dispatch: React.Dispatch<IAction>) => {
  return useCallback(
    async (username: string, roles: string[]) => {
      try {
        const dbs = new DatabaseList();

        let usernameList: string[] = [];
        const request = await fetch(new URL("./_users/_all_docs", dbBaseUrl).toString(), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startkey: "org.couchdb.user:",
            include_docs: true,
          }),
        });
        const result = await request.json();
        if (request.status === 200) {
          usernameList = result.rows.map((doc: any) => doc.doc.name);
        }

        await dbs.loadList(username, usernameList);
        // console.log("all dbs: ", dbs);
        const dbsFiltered = dbs.filterForUser(username, roles);
        dispatch({
          type: "setDatabaseList",
          payload: dbsFiltered,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [dispatch]
  );
};

export const useConnectServer = (dispatch: React.Dispatch<IAction>) => {
  const updateDbsList = useUpdateDbsList(dispatch);

  return useCallback(
    async (username: string, password: string) => {
      dispatch({ type: "setInProgress", payload: true });
      let errorMsg = "";

      console.log("connectServer:", username);

      try {
        const request = await fetch(sessionUrl.toString(), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: username,
            password: password,
          }),
        });

        if (request.status === 200) {
          const response = await request.json();
          if (response.ok) {
            console.log("Login success", response);
            await updateDbsList(username, response.roles);

            dispatch({
              type: "setLoginData",
              payload: {
                loggedIn: true,
                username: username,
                password: password,
                userRoles: response.roles,
              },
            });
          }
        } else {
          const e = "Login Error: " + request.status + " " + request.statusText + ", URL: " + request.url;
          errorMsg = e;
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
      dispatch({ type: "setInProgress", payload: false });
      return errorMsg;
    },
    [dispatch, updateDbsList]
  );
};

export const useCheckSessionState = (dispatch: React.Dispatch<IAction>) => {
  const updateDbsList = useUpdateDbsList(dispatch);

  return useCallback(async () => {
    try {
      dispatch({
        type: "setInProgress",
        payload: true,
      });
      const request = await fetch(sessionUrl.toString(), {
        credentials: "include",
      });
      const sessionInfo = await request.json();
      const loggedIn = sessionInfo.userCtx.name !== null;

      if (loggedIn === true) {
        await updateDbsList(sessionInfo.userCtx.name, sessionInfo.userCtx.roles);
        dispatch({
          type: "setLoginData",
          payload: {
            loggedIn: true,
            username: sessionInfo.userCtx.name,
            password: undefined,
            userRoles: sessionInfo.userCtx.roles,
          },
        });
      } else {
        /*if (localStorage.getItem("username") !== null && username !== localStorage.getItem("username")) {
            // den username aus dem localstorage mal probieren...
          } else {
            // im Localstorage ist entweder kein Username oder der funktioniert auch nicht...
            localStorage.removeItem("username");
            localStorage.removeItem("password");
          }*/
        dispatch({
          type: "setInProgress",
          payload: false,
        });
      }
    } catch (err) {}
  }, [dispatch, updateDbsList]);
};
