import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'reduxjs-toolkit-persist';
import hardSet from 'reduxjs-toolkit-persist/lib/stateReconciler/hardSet';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import rootReducer from './rootReducer';

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    stateReconciler: hardSet,
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;
