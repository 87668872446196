import { Paper } from "@mui/material";
import { indigo } from "@mui/material/colors";
import React from "react";

interface ColoredLayerViewProps {
  depth: number;
  baseColorArray?: { [key: number]: string };
  sx?: object;
  children?: React.ReactNode;
}
const ColoredLayerView: React.FC<ColoredLayerViewProps> = React.memo(
  ({ depth, baseColorArray = indigo, sx, children }) => {
    let color: string;
    switch (depth) {
      case 0:
        // eslint-disable-next-line prefer-destructuring
        color = baseColorArray[50];
        break;
      case 1:
        // eslint-disable-next-line prefer-destructuring
        color = baseColorArray[100];
        break;
      case 2:
        // eslint-disable-next-line prefer-destructuring
        color = baseColorArray[200];
        break;
      case 3:
        // eslint-disable-next-line prefer-destructuring
        color = baseColorArray[300];
        break;
      case 4:
        // eslint-disable-next-line prefer-destructuring
        color = baseColorArray[400];
        break;
      default:
        // eslint-disable-next-line prefer-destructuring
        color = baseColorArray[500];
    }

    return (
      <Paper
        elevation={3}
        sx={[
          {
            padding: 2,
            backgroundColor: color,
            mb: 3,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </Paper>
    );
  }
);

export default ColoredLayerView;
