import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import ContentArrayEditor from "./ContentArrayEditor";
import MyImageDropZone from "../../lib/MyImageDropZone";
import EditableTextField from "../../lib/EditableTextField";

interface KlausurMetaInfoEditorProps {
  sx?: object;
}
const KlausurMetaInfoEditor: React.FC<KlausurMetaInfoEditorProps> = ({ sx = [] }) => {
  return (
    <Paper elevation={3} sx={[{ padding: 2, mb: 3 }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Informationen zur Klausur:</Typography>
        </Grid>
        <Grid item xs={6}>
          <EditableTextField fieldName="metainfo.schule" label="Schule" />
        </Grid>
        <Grid item xs={6}>
          <EditableTextField fieldName="metainfo.art" label="Art der Klausur" placeholder="z.B. Abitur 2022" />
        </Grid>
        <Grid item xs={6}>
          <EditableTextField
            fieldName="metainfo.schulform"
            label="Schulform"
            placeholder="z.B. Berufliches Gymnasium"
          />
        </Grid>
        <Grid item xs={6}>
          <EditableTextField fieldName="metainfo.fach" label="Fach" placeholder="z.B. Technik" />
        </Grid>
        <Grid item xs={6}>
          <EditableTextField fieldName="metainfo.schwerpunkt" label="Schwerpunkt" placeholder="z.B. Mechatronik" />
        </Grid>
        <Grid item xs={6}>
          <EditableTextField fieldName="metainfo.vorschlag" label="Vorschlag" placeholder="z.B. Vorschlag A" />
        </Grid>
        <Grid item xs={6}>
          <EditableTextField
            fieldName="metainfo.anforderungsbereich"
            label="Anforderungsbereich"
            placeholder="z.B. eA Leistungskurs"
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <MyImageDropZone fieldName="metainfo.schullogo" title="Schul-Logo:" sx={{ height: "10vh" }} />
        </Grid>
        <Grid item xs={12}>
          <ContentArrayEditor path="metainfo.hilfsmittel.contentArray" title="zugelassene Hilfsmittel:" />
        </Grid>
        <Grid item xs={12}>
          <ContentArrayEditor
            path="metainfo.einleitungPrüferExemplar.contentArray"
            title="Einleitung für Lösungsexemplar:"
          />
        </Grid>
        <Grid item xs={12}>
          <ContentArrayEditor path="metainfo.einleitungSchülerExemplar.contentArray" title="Einleitung für Schüler:" />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default KlausurMetaInfoEditor;
