import { useCallback, useEffect, useState } from "react";

import { Aufgabe, useSaveAufgabe } from "../Aufgabe";
import AufgabeEditor from "./AufgabeEditor";
import { EditableView } from "../../lib/EditableView";
import { useDoc } from "use-pouchdb";
import { CircularProgress, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { MainViewHandleDirtyChecker } from "../MainViewIsDirtyContext";

interface ImplementationProps {
  dbname: string;
  aufgabe: Aufgabe;
  saveFunction: (aufgabeNeuRaw: any) => Promise<void>;
}
const Implementation: React.FC<ImplementationProps> = ({ dbname, aufgabe, saveFunction }) => {
  return (
    <EditableView value={aufgabe} dbname={dbname} saveFunction={saveFunction}>
      <MainViewHandleDirtyChecker />
      <AufgabeEditor path="" />
    </EditableView>
  );
};

interface AufgabeEditorStandaloneWrapperProps {
  dbname: string;
  docid: string;
}
const AufgabeEditorStandaloneWrapper: React.FC<AufgabeEditorStandaloneWrapperProps> = React.memo(
  ({ dbname, docid }) => {
    const saveAufgabe = useSaveAufgabe(dbname);
    const aufgabeRaw = useDoc(docid, { db: dbname });

    const [aufgabe, setAufgabe] = useState<Aufgabe | undefined>();
    useEffect(() => {
      if (aufgabeRaw.error === null && aufgabeRaw.state === "done") {
        setAufgabe(aufgabeRaw.doc as unknown as Aufgabe);
      } else {
        setAufgabe(undefined);
      }
    }, [aufgabeRaw]);

    const saveFunction = useCallback(
      async (aufgabeNeuRaw: any) => {
        //const aufgabeNeu = Aufgabe.plainToClass(aufgabeNeuRaw);
        if (!aufgabeNeuRaw) throw new Error("INTERNAL ERROR");
        await saveAufgabe(aufgabeNeuRaw);
      },
      [saveAufgabe]
    );

    return (
      <>
        {aufgabe && <Implementation dbname={dbname} aufgabe={aufgabe} saveFunction={saveFunction} />}
        {!aufgabe && (
          <Stack direction="row" spacing={2}>
            <CircularProgress />
            <Typography>Loading...</Typography>
          </Stack>
        )}
      </>
    );
  }
);

export default AufgabeEditorStandaloneWrapper;
