import { useEffect, useState } from "react";
import { useAllDocs } from "use-pouchdb";

interface User {
  _id: string;
  _rev: string;
  name: string;
  roles: string[];
  type: string;
}

export const useAllUserList = () => {
  const name = "_users";
  const docs = useAllDocs({
    db: name,
    include_docs: true,
    startkey: "org.couchdb.user:",
  });

  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    if (docs.loading === false && docs.state === "done") {
      setUsers(
        docs.rows.map((doc: any) => {
          return {
            _id: doc.doc._id,
            _rev: doc.doc._rev as string,
            name: doc.doc.name,
            roles: doc.doc.roles,
            type: doc.doc.type,
          };
        })
      );
    } else {
      setUsers([]);
    }
  }, [docs]);

  return users;
};
