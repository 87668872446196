import { styled } from "@mui/material/styles";
import { Box, Drawer, IconButton, Toolbar } from "@mui/material";
import React from "react";
import { MyAppbar, ActionButton, MoreVertItem } from "./MyAppbar";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  //display: "flex",
  //height: "100%",
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Layout: React.FC<{
  sidesheet?: React.ReactNode;
  sidesheetToggleIcon?: React.ReactNode;
  actionButtons?: ActionButton[];
  moreVertMenuItems?: MoreVertItem[];
  children?: React.ReactNode;
}> = ({ sidesheet, sidesheetToggleIcon, actionButtons, moreVertMenuItems, children }) => {
  const [sidesheetOpen, setSidesheetOpen] = React.useState<boolean>(false);

  const toggleSideSheet = () => {
    setSidesheetOpen(!sidesheetOpen);
  };

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <MyAppbar
          sidesheetToggleIcon={
            sidesheetToggleIcon && (
              <IconButton color="inherit" onClick={toggleSideSheet} edge="end">
                {sidesheetToggleIcon}
              </IconButton>
            )
          }
          actionButtons={actionButtons}
          moreVertMenuItems={moreVertMenuItems}
        />

        <Drawer
          anchor="left"
          open={sidesheetOpen}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
        >
          <Toolbar variant="dense" />
          <Box sx={{ overflow: "auto" }}>{sidesheet}</Box>
        </Drawer>
        <Main open={sidesheetOpen}>
          <Toolbar variant="dense" />
          {children}
        </Main>
      </Box>
    </>
  );
};

Layout.defaultProps = {
  sidesheet: undefined,
  sidesheetToggleIcon: undefined,
  actionButtons: undefined,
  children: undefined,
};

export default Layout;
