import React, {
  ReactNode,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useView } from "use-pouchdb";
import { Gutachten, GutachtenEintrag } from "../../Gutachten";

interface GutachtenLoadingContextType {
  dbname: string;
  gutachten?: Gutachten;
  einträge?: GutachtenEintrag[];
  loading: boolean;
  errormessage?: string;
}
const GutachtenLoadingContext =
  React.createContext<GutachtenLoadingContextType>(
    {} as GutachtenLoadingContextType
  );
interface GutachtenLoadingContextComponentProps {
  children: ReactNode;
  dbname: string;
  docid: string;
}
export const GutachtenLoadingContextComponent: React.FC<
  GutachtenLoadingContextComponentProps
> = ({ dbname, docid, children }) => {
  const gutachtenView = useView("klausurtool/gutachtenAndEintraege", {
    db: dbname,
    include_docs: true,
    startkey: [docid],
    endkey: [docid, {}],
  });

  const [gutachten, setGutachten] = useState<Gutachten | undefined>();
  const [einträge, setEinträge] = useState<GutachtenEintrag[] | undefined>();

  useEffect(() => {
    if (gutachtenView.loading === false && gutachtenView.state === "done") {
      const g = Gutachten.plainToClass(gutachtenView.rows[0].doc);
      if (g) {
        let e: GutachtenEintrag[] | undefined = undefined;

        if (gutachtenView.rows.length > 1)
          e = gutachtenView.rows
            .filter((row, index) => (index === 0 ? false : true))
            .map((row) => {
              const t = GutachtenEintrag.plainToClass(row.doc);
              if (!t) throw new Error("ERROR while reading GutachtenEintrag!");
              return t;
            });
        else e = [];

        setGutachten(g);
        setEinträge(e);
      } else throw new Error("ERROR while parsing Gutachten!");
    } else if (gutachtenView.loading === false) {
      console.log(gutachtenView.error);
      setGutachten(undefined);
      setEinträge(undefined);
    }
  }, [gutachtenView]);

  const ctxValue = useMemo(() => {
    return {
      dbname,
      gutachten,
      einträge,
      loading: gutachtenView.loading,
      errormessage: gutachtenView.error
        ? gutachtenView.error.message
        : undefined,
    };
  }, [dbname, einträge, gutachten, gutachtenView.error, gutachtenView.loading]);

  return (
    <GutachtenLoadingContext.Provider value={ctxValue}>
      {children}
    </GutachtenLoadingContext.Provider>
  );
};
export const useGutachtenLoadingContext = () => {
  return useContext(GutachtenLoadingContext);
};
