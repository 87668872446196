import { useCallback, useEffect, useRef, useState } from "react";
import { DocumentAttachment } from "../lib/AttachmentManager";
import { blobToBase64 } from "../lib/base64DataUrlHelper";

export interface UsePdfCompilerProps {
  latexDocument: string;
  attachments: DocumentAttachment[];
}
export interface IPdfCompilerResult {
  success: boolean;
  log: string;
  pdf: Blob | undefined;
}

const useServerXeTeXCompiler = () => {
  const compile = useCallback(
    async (files: { filename: string; data: Blob | string }[]): Promise<IPdfCompilerResult> => {
      // check if a main.tex file is defined:
      let found = 0;
      files.forEach((f) => {
        if (f.filename === "main.tex" && typeof f.data === "string") ++found;
      });

      if (found !== 1) throw new Error("Please provide main.tex as string!");

      const transformed = await Promise.all(
        files.map(async (f) => {
          if (typeof f.data === "string") return { filename: f.filename, data: f.data };
          if (f.data instanceof Blob) {
            return {
              filename: `${f.filename}.${(f.data as Blob).type.substring((f.data as Blob).type.lastIndexOf("/") + 1)}`,
              data: await blobToBase64(f.data),
            };
          }
          console.log(f.data);
          throw new Error("Something went wrong");
        })
      );

      const request = await fetch("/server/api/v1/xetex", {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ files: transformed }),
      });

      if (request.status === 200) {
        return { success: true, log: "", pdf: await request.blob() };
      } else {
        return { success: false, log: await request.text(), pdf: undefined };
      }
    },
    []
  );

  return compile;
};

export function usePdfFromLatex({ latexDocument: mainFile, attachments: files }: UsePdfCompilerProps) {
  const compile = useServerXeTeXCompiler();

  const inProgressRef = useRef(false);

  const [res, setRes] = useState<{
    inProgress: boolean;
    success?: boolean;
    errormessage?: string | undefined;
    dataUrl?: string;
  }>({ inProgress: inProgressRef.current });

  const dataUrlRef = useRef<string | undefined>(undefined);
  useEffect(() => {
    async function fx() {
      if (!inProgressRef.current) {
        inProgressRef.current = true;
        setRes({ inProgress: true });
        const blob = await compile([
          { filename: "main.tex", data: mainFile },
          ...files.map((a: DocumentAttachment) => {
            return { filename: a.name, data: a.data as Blob };
          }),
        ]);
        if (blob.success) {
          dataUrlRef.current = URL.createObjectURL(blob.pdf as Blob);

          setRes({ inProgress: false, success: true, dataUrl: dataUrlRef.current });
        } else {
          setRes({ inProgress: false, success: false, errormessage: blob.log });
        }
        inProgressRef.current = false;
      }
    }
    fx();

    return () => {
      if (dataUrlRef.current) URL.revokeObjectURL(dataUrlRef.current);
    };
  }, [mainFile, files, compile]);

  return res;
}

export default useServerXeTeXCompiler;
