import React from "react";
import { FormControl, InputLabel, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { useEditModeContext } from "./EditableView";

const ReactHookFormSelect: React.FC<{
  name: string;
  label: string;
  error: any;
  required: boolean;
  control: any;
  children: React.ReactNode;
  disabled?: boolean;
}> = ({ name, label, error, required, control, children, disabled }) => {
  const labelId = `${name}-label`;
  let editModeEnabled = true;
  const ctx = useEditModeContext();
  if (ctx) editModeEnabled = ctx.editModeEnabled;
  return (
    <FormControl sx={{ width: "100%" }} variant="standard">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field }) => (
          <Select disabled={!editModeEnabled || disabled} labelId={labelId} label={label} error={error} {...field}>
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
