import { Dialog, DialogTitle, DialogActions, Button, DialogContent, DialogContentText } from "@mui/material";
import { ReactNode } from "react";

interface SimpleDialogProps {
  open: boolean;
  handleCancel?: () => void;
  handleOK: () => void;
  title: string;
  dialogText?: string;
  abbruchText?: string;
  okText?: string;
  children?: ReactNode;
}
const SimpleDialog: React.FC<SimpleDialogProps> = ({
  open,
  handleCancel,
  handleOK,
  title,
  dialogText,
  abbruchText = "Abbruch",
  okText = "OK",
  children,
}) => {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText && dialogText}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {handleCancel && (
          <Button onClick={handleCancel} autoFocus={!handleCancel ? false : true}>
            {abbruchText}
          </Button>
        )}
        <Button onClick={handleOK} autoFocus={handleCancel ? false : true}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
