import { useCallback, useEffect, useState } from "react";
import { useDoc, usePouch, useView } from "use-pouchdb";
import { DocumentAddress, useDbList } from "../lib/couchdb-mgr/CouchDbContext";
import { objectToDateTime } from "../lib/DateTimeHelper";
import { toIndexableString } from "pouchdb-collate";
import { v4 as uuidv4 } from "uuid";

export type DocumentTypes = "xsltemplate" | "aufgabe" | "klausur" | "gutachten" | "prüfungsdurchführung";

export class BaseObject {
  readonly type!: DocumentTypes;

  _id?: string;
  _rev?: string;

  name: string = "";

  creationDate: string = "";
  lastEditDate: string = "";
}

export const useDeleteDocument = (dbKey: string) => {
  const db = usePouch(dbKey);

  return useCallback(
    async ({ _id, _rev }: { _id?: string; _rev?: string }) => {
      console.log("Delete Object", _id, _rev);
      if (!_id) throw new Error("Cannot delete Object without _id");
      if (!_rev) throw new Error("Cannot delete Object without _ref");

      await db.remove(_id, _rev);
    },
    [db]
  );
};

export const useCopyDocument = () => {
  const dbs = useDbList();

  return useCallback(
    async (source: DocumentAddress, targetDbName: string) => {
      try {
        const dbSrc = dbs.byName(source.dbname);
        const dbTarget = dbs.byName(targetDbName);

        if (!dbSrc || !dbTarget)
          throw new Error(`Eine der Datenbanken existiert nicht: ${source.dbname}, ${targetDbName}`);

        const sourceDoc = await dbSrc.db.get(source.docid, { attachments: true, binary: true });
        //console.log(sourceDoc);

        const now = new Date();
        if ((sourceDoc as any).lastEditDate) (sourceDoc as any).lastEditDate = objectToDateTime(now);
        if ((sourceDoc as any).creationDate) (sourceDoc as any).creationDate = objectToDateTime(now);
        sourceDoc._id = toIndexableString([
          (sourceDoc as any).type ? (sourceDoc as any).type : "",
          (sourceDoc as any).creationDate,
          uuidv4().toString(),
          // eslint-disable-next-line no-control-regex
        ]).replace(/\u0000/g, "\u0001");

        (sourceDoc as any).name = (sourceDoc as any).name
          ? `${(sourceDoc as any).name} (Kopie)`
          : `(Kopie von ${(sourceDoc as any).type})`;

        delete (sourceDoc as any)._rev;

        /*const res = */ await dbTarget.db.put(sourceDoc);
      } catch (e) {
        console.log(e);
      }
    },
    [dbs]
  );
};

export function useGenericDocument<ValueType>(
  dbname: string,
  docid: string,
  plainToClassFct?: (data: unknown) => ValueType | undefined
) {
  const docRaw = useDoc(docid, { db: dbname });
  const [doc, setDoc] = useState<ValueType | undefined>();
  const calcRet = useCallback(() => {
    let err: any = docRaw.error;
    if (docRaw.state === "done" && docRaw.error === null && doc === undefined) {
      err = `Error. Could not parse data to class: ${JSON.stringify(docRaw)}`;
    }
    return { doc: doc, loading: docRaw.loading, error: err };
  }, [doc, docRaw]);
  const [ret, setRet] = useState(calcRet());

  useEffect(() => {
    if (docRaw.state === "done" && docRaw.error === null) {
      setDoc(plainToClassFct ? plainToClassFct(docRaw.doc) : docRaw.doc as ValueType);
    } else {
      setDoc(undefined);
    }
  }, [docRaw, plainToClassFct]);

  useEffect(() => {
    setRet(calcRet());
  }, [calcRet]);

  return ret;
}

interface UseAllFromViewGenericProps<ValueType> {
  dbname: string;
  viewName: string;
  includeDocs: boolean;
  plainToClassFct?: (data: unknown) => ValueType | undefined;
  key?: string;
  startkey?: string;
  endkey?: string;
}
interface RowType<ValueType> {
  id: string;
  key: string;
  value: any;
  doc?: ValueType;
}
interface UseAllFromViewGenericReturnVal<ValueType> {
  rows?: RowType<ValueType>[];
  loading: boolean;
  state: string;
  error: PouchDB.Core.Error | null;
}
export function useAllFromViewGeneric<ValueType>({
  dbname,
  viewName,
  includeDocs,
  plainToClassFct,
  key,
  startkey,
  endkey,
}: UseAllFromViewGenericProps<ValueType>) {
  const jsonData = useView(viewName, {
    db: dbname,
    include_docs: includeDocs,
    key: key,
    startkey: startkey,
    endkey: endkey,
  });

  const [rows, setRows] = useState<UseAllFromViewGenericReturnVal<ValueType>>({
    loading: jsonData.loading,
    state: jsonData.state,
    error: jsonData.error,
  });

  useEffect(() => {
    if (jsonData.loading === false) {
      if (jsonData.error) {
        throw new Error(JSON.stringify(jsonData.error));
      }
      setRows({
        loading: jsonData.loading,
        state: jsonData.state,
        error: jsonData.error,
        rows: jsonData.rows.map((row) => {
          return {
            id: row.id,
            key: row.key,
            value: row.value,
            doc: row.doc ? (plainToClassFct ? plainToClassFct(row.doc) : (row.doc as ValueType)) : undefined,
          };
        }),
      });
    } else setRows({ loading: jsonData.loading, state: jsonData.state, error: jsonData.error });
  }, [jsonData.error, jsonData.loading, jsonData.rows, jsonData.state, plainToClassFct]);
  return rows;
}
