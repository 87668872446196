import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import ContextMenu from "../../../lib/ContextMenu";
import { XSLFileData } from "../../XSLTemplate";

import { Delete, Add, Edit } from "@mui/icons-material";
import SimpleDialog from "../../../lib/SimpleDialog";
import { useXslFileContext } from "./XslTemplateView";
import { useEditModeContext } from "../../../lib/EditableView";

interface XslFileSelectorProps {
  enabled?: boolean;
  onSelect: (idx: number) => void;
}
const XslFileSelector: React.FC<XslFileSelectorProps> = ({ enabled = true, onSelect }) => {
  const { editModeEnabled } = useEditModeContext();
  const [fileIdx, setFileIdx] = useState("0");
  const { files } = useXslFileContext();

  const handleChange = (event: SelectChangeEvent) => {
    setFileIdx(event.target.value);
    onSelect(parseInt(event.target.value));
  };

  const [showSimpleDialog, setShowSimpleDialog] = useState(false);
  const [showReallyDeleteDialog, setShowReallyDeleteDialog] = useState(false);
  const deleteFct = useCallback(() => {
    if ((files.fields[parseInt(fileIdx) as number] as unknown as XSLFileData).filename === "main.xsl") {
      setShowSimpleDialog(true);
      return;
    }
    setShowReallyDeleteDialog(true);
  }, [fileIdx, files.fields]);

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [addName, setAddName] = useState("");
  const addFct = useCallback(() => {
    setAddName("");
    setShowAddDialog(true);
  }, []);

  const [showRenameDlg, setShowRenameDlg] = useState(false);
  const [renameName, setRenameName] = useState("");
  const renameFct = useCallback(() => {
    if ((files.fields[parseInt(fileIdx) as number] as unknown as XSLFileData).filename === "main.xsl") {
      setShowSimpleDialog(true);
      return;
    }
    setShowRenameDlg(true);
    setRenameName((files.fields[parseInt(fileIdx) as number] as unknown as XSLFileData).filename);
  }, [fileIdx, files.fields]);

  return (
    <>
      <SimpleDialog
        title="Der Eintrag main.xsl kann nicht gelöscht oder umbenannt werden!"
        open={showSimpleDialog}
        handleOK={() => setShowSimpleDialog(false)}
      />
      <SimpleDialog
        title={`Wollen Sie die Datei ${
          (files.fields[parseInt(fileIdx) as number] as unknown as XSLFileData).filename
        } wirklich löschen?`}
        open={showReallyDeleteDialog}
        handleOK={() => {
          setShowReallyDeleteDialog(false);
          files.remove(parseInt(fileIdx) as number);
          setFileIdx("0");
          onSelect(0);
        }}
        handleCancel={() => setShowReallyDeleteDialog(false)}
        abbruchText={"Lieber nicht!"}
      />
      <SimpleDialog
        title="Name der neuen Datei?"
        open={showAddDialog}
        handleOK={() => {
          files.append({ type: "filedata", data: "", filename: addName } as XSLFileData);
          setShowAddDialog(false);
        }}
        handleCancel={() => setShowAddDialog(false)}
      >
        <TextField variant="standard" value={addName} onChange={(e) => setAddName(e.target.value)} />
      </SimpleDialog>
      <SimpleDialog
        title="Neuer Name?"
        open={showRenameDlg}
        handleOK={() => {
          files.update(parseInt(fileIdx) as number, {
            ...(files.fields[parseInt(fileIdx) as number] as unknown as XSLFileData),
            filename: renameName,
          });
          setShowRenameDlg(false);
        }}
        handleCancel={() => setShowRenameDlg(false)}
      >
        <TextField variant="standard" value={renameName} onChange={(e) => setRenameName(e.target.value)} />
      </SimpleDialog>
      <FormControl>
        <InputLabel>Select File</InputLabel>
        <Select variant="standard" value={fileIdx} label="XSL File" onChange={handleChange}>
          {files.fields.map((fileRaw, index) => {
            const file = fileRaw as unknown as XSLFileData;
            if (file.type === "filedata")
              return (
                <MenuItem key={fileRaw.id} value={index}>
                  {file.filename}
                </MenuItem>
              );
            throw new Error("INTERNAL ERROR");
          })}
        </Select>
      </FormControl>
      {editModeEnabled && (
        <ContextMenu
          items={[
            { title: "Add", handleClick: addFct, icon: <Add /> },
            { title: "Rename", handleClick: renameFct, icon: <Edit /> },
            { title: "Delete", handleClick: deleteFct, icon: <Delete />, dividerBefore: true },
          ]}
        />
      )}
    </>
  );
};

export default XslFileSelector;
