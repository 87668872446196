function writeMigrationVersion(db: any, rev: string, newVersion: number) {
  if (rev === undefined) {
    db.put({
      _id: "dbmigrations",
      type: "dbmigrations",
      version: newVersion,
    })
      .then((result: any) => {
        console.log("Write dbmigration OK. Version " + newVersion);
      })
      .catch((err: any) => {
        console.log("Error writing dbmigration Version " + newVersion + ": " + err);
      });
  } else {
    db.put({
      _id: "dbmigrations",
      _rev: rev,
      type: "dbmigrations",
      version: newVersion,
    })
      .then((result: any) => {
        console.log("Update dbmigration OK. Version " + newVersion);
      })
      .catch((err: any) => {
        console.log("Error updating dbmigration to Version " + newVersion + ": " + err);
      });
  }
}

const dbMigrationFunction = async (db: PouchDB.Database, currentMigrationVersion: number) => {
  const CURVERSION = 10;
  if (currentMigrationVersion < CURVERSION) {
    console.log(`MIGRATE DB TO VERSION ${CURVERSION}`);

    let designDoc: any = {
      _id: "_design/klausurtool",
      views: {
        allAufgaben: {
          map: "function (doc) {\n  if (doc.type === 'aufgabe') emit ([doc.name, doc.metainfo.lastEditDate, doc._id, doc.name], {_id: doc._id, _rev: doc._rev});\n}",
        },
        allKlausuren: {
          map: "function (doc) {\n  if (doc.type === 'klausur') emit ([doc.metainfo.fach, doc.metainfo.art, doc.metainfo.lastEditDate, doc._id, doc.name], {_id: doc._id, _rev: doc._rev});\n}",
        },
        allGutachten: {
          map: "function (doc) {\n  if (doc.type === 'gutachten') emit ([doc.art, doc.lastEditDate, doc._id, doc.name], {_id: doc._id, _rev: doc._rev});\n}",
        },
        allTemplates: {
          map: "function (doc) {\n  if (doc.type === 'xsltemplate') emit ([doc.name, doc.targetType, doc.lastEditDate, doc._id, doc.name], {_id: doc._id, _rev: doc._rev});\n}",
        },
        allDocsName: {
          map: "function (doc) {\n  if (doc.type === 'aufgabe' || doc.type === 'klausur' || doc.type === 'gutachten'|| doc.type === 'xsltemplate') {\n    emit(doc._id, {type: doc.type, name: doc.name ? doc.name : ''});\n  }\n  else if (doc.type === 'xsltemplate') {\n    emit(doc._id, {type: doc.type, name: doc.name ? doc.name : ''});\n  }\n}",
        },
        gutachtenAndEintraege: {
          map: 'function(doc) {\n    if (doc.type == "gutachten") {\n        emit([doc._id, 0], null);\n    } else if (doc.type == "gutachteneintrag") {\n        emit([doc.gutachtenId, 1], null);\n    }\n}',
        },
      },
    };
    try {
      const oldDesign = (await db.get("_design/klausurtool")) as any;
      designDoc = { ...designDoc, _rev: oldDesign._rev };
      await db.put(designDoc);
    } catch (e) {
      console.log(e);
    }

    currentMigrationVersion = CURVERSION;
  }

  return currentMigrationVersion;
};

export default async function checkMigrations(db: PouchDB.Database) {
  let currentMigrationVersion = 0;
  let rev = undefined;

  let doc = undefined;
  try {
    doc = (await db.get("dbmigrations")) as any;
    rev = doc._rev;
    currentMigrationVersion = doc.version;
  } catch (err) {
    console.log("Could not load migrations document");
  }

  try {
    //console.log("run dbMigrationFunction with currentMigrationVersion=" + currentMigrationVersion);
    const newVersion = await dbMigrationFunction(db, currentMigrationVersion);
    //console.log("dbMigrationFunction done! newVersion=" + newVersion);
    if (newVersion !== currentMigrationVersion) writeMigrationVersion(db, rev, newVersion);
  } catch (e) {
    console.log("Error writing migrations", e);
  }
}
