import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import React, { useCallback } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import MyFileDropZone, { loadFile } from "./MyFileDropZone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { dataUrlToBlob } from "./base64DataUrlHelper";
import { download } from "./download";
import SimpleDialog from "./SimpleDialog";
import useClipboard from "./useClipboard";
import { SxProps } from "@mui/system";
import { Accept } from "react-dropzone";
import { FileData } from "../core/Aufgabe";
import { useSetAttachment, useAttachment, useDataUrlFromBlob, useRemoveAttachment } from "./AttachmentManager";
import { v4 as uuidv4 } from "uuid";

function useRemoveImage() {
  const ctx = useFormContext();
  const removeAttachmentFct = useRemoveAttachment();

  return useCallback(
    (fieldName: string, imageUUID: string) => {
      ctx.setValue(`${fieldName}`, undefined, {
        shouldDirty: true,
      });
      removeAttachmentFct(imageUUID);
    },
    [ctx, removeAttachmentFct]
  );
}

function useSetImage(fieldName: string) {
  const ctx = useFormContext();
  const setAttachmentFct = useSetAttachment();
  const removeFct = useRemoveImage();

  const imageUUID = ctx.watch(`${fieldName}.uuid`);

  return useCallback(
    (type: string, filename: string, data: Blob) => {
      if (imageUUID) {
        removeFct(fieldName, imageUUID);
      }

      const newImageUUID = uuidv4();
      ctx.setValue(
        `${fieldName}`,
        { uuid: newImageUUID, type, filename },
        {
          shouldDirty: true,
        }
      );
      setAttachmentFct(newImageUUID, new Blob([data], { type: type }), type);
    },
    [ctx, setAttachmentFct, imageUUID, fieldName]
  );
}

export interface DataRendererComponentProps {
  sx?: SxProps;
  data: FileData;
  dataUrl?: string;
}
interface II {
  title?: string;
  fieldName: string;
  editable: boolean;
  acceptFiles?: Accept;
  DataRendererComponent: React.FC<DataRendererComponentProps>;
  sx?: SxProps;
}

const MyKlausurtoolDropZone: React.FC<II> = ({
  title,
  fieldName,
  editable,
  acceptFiles,
  DataRendererComponent,
  sx,
}: II) => {
  const ctx = useFormContext();

  const imageUUID = ctx.watch(`${fieldName}.uuid`);
  const imageType = ctx.watch(`${fieldName}.type`);
  const imageFilename = ctx.watch(`${fieldName}.filename`);

  const clipboard = useClipboard();

  const imageData = useAttachment(imageUUID);
  const imageUrl = useDataUrlFromBlob(imageData?.attachment);

  const removeAttachmentFct = useRemoveImage();

  const setImage = useSetImage(fieldName);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 1) {
      loadFile(acceptedFiles[0])
        .then(async (data: ArrayBuffer) => {
          const imageType = `image/${acceptedFiles[0].name.substring(acceptedFiles[0].name.length - 3)}`.toLowerCase();
          setImage(imageType, acceptedFiles[0].name, new Blob([data], { type: imageType }));
        })
        .catch((err: any) => {
          console.log(err);
          throw err;
        });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showReallyDeleteDlg, setShowReallyDeleteDlg] = useState(false);

  return (
    <Paper elevation={2}>
      <Stack spacing={2}>
        {title && <Typography>{title}</Typography>}
        <Box>
          <MyFileDropZone editable={editable} onDrop={onDrop} accept={acceptFiles} noClick={true}>
            <DataRendererComponent
              dataUrl={imageUrl}
              data={imageData && ({ uuid: imageUUID, type: imageType, filename: imageFilename } as FileData)}
            />
          </MyFileDropZone>
          {editable && (
            <Box
              sx={{
                position: "relative",
                left: "75%",
                top: "-50px",
                zIndex: 10,
                backgroundColor: "primary.light",
                borderRadius: "50%",
                border: "1pt solid black",
                width: "48px",
                height: "48px",
              }}
            >
              <IconButton size="large" onClick={handleMenu} color="inherit">
                <MoreVertIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Stack>

      <SimpleDialog
        open={showReallyDeleteDlg}
        title={"Wollen Sie das Bild wirklich löschen?"}
        handleCancel={() => setShowReallyDeleteDlg(false)}
        handleOK={() => {
          setShowReallyDeleteDlg(false);
          removeAttachmentFct(fieldName, imageUUID);
        }}
        abbruchText={"Nicht löschen"}
        okText={"JA, löschen!!"}
      />
      {editable && (
        <>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={async () => {
                setAnchorEl(null);
                const data = await clipboard.read("image/png");

                if (data) {
                  const imageType = "image/png";
                  setImage(imageType, "clipboard-image.png", new Blob([await data.arrayBuffer()], { type: imageType }));
                } else {
                  throw new Error(JSON.stringify({ message: "Clipboard did not gave an object???", data }));
                }
              }}
            >
              <ListItemIcon>
                <ContentPasteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Paste From Clipboard</ListItemText>
            </MenuItem>

            <input
              type="file"
              id={`fileUploadInput_${fieldName}`}
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files?.length === 1) {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.onload = (e: ProgressEvent<FileReader>) => {
                    if (e && e.target) {
                      setImage(file.type, file.name, new Blob([e.target.result as ArrayBuffer], { type: imageType }));
                    }
                  };
                  reader.readAsArrayBuffer(file);
                }
              }}
            />
            <MenuItem
              onClick={async () => {
                const element = document.getElementById(`fileUploadInput_${fieldName}`) as HTMLInputElement;
                if (element) element.click();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <UploadFileIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Upload</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={async () => {
                if (imageUrl) {
                  download(imageFilename, new Blob([await dataUrlToBlob(imageUrl)]));
                }
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <DownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setShowReallyDeleteDlg(true);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Paper>
  );
};

export default MyKlausurtoolDropZone;
