import { combineReducers } from "@reduxjs/toolkit";
import { klausurEditorReducer } from "./slices/klausurEditorSlice";
import { xslEditorReducer } from "./slices/xslEditorSlice";

const rootReducer = combineReducers({
  xslEditorSettings: xslEditorReducer,
  klausurEditorSettings: klausurEditorReducer,
});

export default rootReducer;
